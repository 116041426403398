import styled from 'styled-components';
import Calendar from 'moedim';

const StyledCalendar = styled(Calendar)`
font-size: 24px !important;
font-family: Raleway !important;
font-variant-numeric: lining-nums proportional-nums;
border: 0px;
.eBolmP {
    font-size: 24px !important;
}
.kqHZjw {
    font-size: 12px !important;
    font-variant-numeric: lining-nums proportional-nums;
}
`;

export default StyledCalendar;