import PageTitle from '../../../components/layout/PageTitle';
import AccountsList from './AccountsList'

const AccountsPage = (props) => {
    return (
        <>
            <PageTitle
                title='Аккаунты'
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
             <AccountsList />
        </>
    );
};

export default AccountsPage;