import { Outlet, useParams } from 'react-router-dom';
import SummaryPanel from './SummaryPanel';
import PageTitle from '../../components/layout/PageTitle';
import NewsPanel from './NewsPanel';

const DashboardPage = () => {
    let params = useParams();
    const accountId = params.accountId;

    return (
        <>
            <PageTitle
                title='Обзор'
                icon={
                    <img
                        src='/images/icons/home.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
            <div className='row'>
                <div className='col-md-4'>
                    <SummaryPanel accountId={accountId} />
                </div>
                <div className='col-md-5'>
                </div>
            </div>
            <Outlet />

        </>
    );
};

export default DashboardPage;
