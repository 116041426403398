import { apiSlice } from './apiSlice';

export const employeesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeesList: builder.query({
            query: (data) =>
                'api/employees?' +
                `accountId=${data.accountId}` +
                `&showArchive=${data.showArchive ?? false}` +
                `&departmentId=${data.departmentId ?? ''}` +
                `&search=${data.search ?? ''}&sort=${data.sort ?? 'name'}` +
                `&pageSize=${data.pageSize}&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Employees',
                              id,
                          })),
                          { type: 'Employees', id: 'List' },
                      ]
                    : [{ type: 'Employees', id: 'List' }],
        }),
        getEmployeeById: builder.query({
            query: (entityId) => `api/employees/${entityId}`,
            providesTags: (result) => [
                { type: 'Employee', id: result.result.id },
            ],
        }),
        getEmployeeRedirects: builder.query({
            query: (entityId) => `api/employees/${entityId}/redirects`,
            providesTags: (result) => [{ type: 'Redirect', id: result.result }],
        }),
        createEmployee: builder.mutation({
            query: (data) => ({
                url: 'api/employees',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Employees', id: 'List' }],
        }),
        updateEmployee: builder.mutation({
            query: (data) => ({
                url: 'api/employees/' + data.id,
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Employees', id: 'List' },
                { type: 'Employee', id: result.result.id },
            ],
        }),
        patchEmployee: builder.mutation({
            query: ({ id, items }) => ({
                url: 'api/employees/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Employees', id: 'List' },
                { type: 'Employee', id: result.result.id },
            ],
        }),
        patchEmployeeRedirect: builder.mutation({
            query: ({ id, items }) => ({
                url: `api/employees/${id}/redirects`,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Redirect', id: result.result },
            ],
        }),
        deleteEmployee: builder.mutation({
            query: (entityId) => ({
                url: 'api/employees/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Employees', id: 'List' }],
        }),
        getLinesList: builder.query({
            query: (data) =>
                `api/lines?accountId=${data.accountId}&free=${
                    data.free ?? false
                }&include=${data.include ?? ''}&search=${data.search ?? ''}`,
        }),
    }),
});

export const {
    useGetEmployeesListQuery,
    useLazyGetEmployeesListQuery,
    useGetEmployeeByIdQuery,
    useGetEmployeeRedirectsQuery,
    useLazyGetEmployeeRedirectsQuery,
    useLazyGetEmployeeByIdQuery,
    useCreateEmployeeMutation,
    useUpdateEmployeeMutation,
    usePatchEmployeeMutation,
    usePatchEmployeeRedirectMutation,
    useDeleteEmployeeMutation,
    useLazyGetLinesListQuery,
} = employeesApiSlice;
