import { useState } from 'react';
import DeleteModal from './DeleteModal';
import OutsideClickHandler from './OutsideClickHandler';

export const DeleteActionMenu = (props) => {
    const { id, deleteTitle, deleteText, action, callback } = props;

    const [dropdownActionIsOpen, setDropdownActionIsOpen] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);

    const deleteEntity = () => {
        action(id);
        setOpenedDelete(false);
        callback();
    };

    const openDeleteModal = () => {
        setOpenedDelete(true);
        setDropdownActionIsOpen(false);
    };

    return (
        <>
            <DeleteModal
                isOpen={openedDelete}
                okHandle={deleteEntity}
                closeHandle={() => setOpenedDelete(false)}
                deleteTitle={deleteTitle}
                deleteText={deleteText}
            />
            <OutsideClickHandler
                classes='d-inline-block'
                callback={() => setDropdownActionIsOpen(false)}
            >
                <div className='position-relative'>
                    <img
                        src='/images/icons/more-horizontal.svg'
                        className='cursor-pointer'
                        onClick={() => setDropdownActionIsOpen(true)}
                    />
                    <ul
                        className={
                            'dropdown-menu' +
                            (dropdownActionIsOpen ? ' show' : '')
                        }
                        style={{ right: 0 }}
                    >
                        <li>
                            <button
                                className='dropdown-item'
                                onClick={openDeleteModal}
                            >
                                Удалить
                            </button>
                        </li>
                    </ul>
                </div>
            </OutsideClickHandler>
        </>
    );
};
