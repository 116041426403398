import { useEffect, useState } from 'react';
import FilterScalarHeader from '../../components/filters/FilterScalarHeader';
import OutsideClickHandler from '../../components/OutsideClickHandler';

const TypeCallDirectionsFilter = (props) => {
    const { value, valueChanged } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [description, setDescription] = useState(': все звонки');

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const selectValue = (value) => {
        valueChanged(value);
        setIsEdit(false);
        setSelectedValue(value);
        if (value === 0) {
            setDescription(': все звонки');
        } else if (value === 1) {
            setDescription(': пропущенные');
        } else {
            setDescription('');
        }
    };

    return (
        <>
            <FilterScalarHeader
                value={description}
                title='Тип звонка'
                icon='shuffle'
                clickHandler={() => setIsEdit(true)}
                withoutActive
            >
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-200 height-100 filters-dropdown'
                        }
                    >
                        <>
                            <div
                                className='dropdown-check cursor-pointer'
                                onClick={() => selectValue(0)}
                            >
                                <div className='dropdown-flex-start'>
                                    <img
                                        src='/images/icons/phone-call.svg'
                                        width={16}
                                        className='icon-gray'
                                    />
                                    Все звонки
                                </div>
                                {selectedValue === 0 ? (
                                    <img
                                        src='/images/icons/check.svg'
                                        width={20}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className='dropdown-check cursor-pointer'
                                onClick={() => selectValue(1)}
                            >
                                <div className='dropdown-flex-start'>
                                    <img
                                        src='/images/icons/phone-missed.svg'
                                        width={16}
                                        className='icon-gray'
                                    />
                                    Пропущенные
                                </div>
                                {selectedValue === 1 ? (
                                    <img
                                        src='/images/icons/check.svg'
                                        width={20}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </FilterScalarHeader>
        </>
    );
};

export default TypeCallDirectionsFilter;
