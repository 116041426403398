import { useEffect, useState } from 'react';
import DeleteModal from '../DeleteModal';
import OutsideClickHandler from '../OutsideClickHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteMutation } from '../../app/api/companiesApiSlice';
import { useDeleteContactMutation } from '../../app/api/contactsApiSlice';
import { useDeleteEmployeeMutation } from '../../app/api/employeesApiSlice';
import { useDeleteDepartmentMutation } from '../../app/api/departmentsApiSlice';

export const ActionMenu = () => {
    let params = useParams();
    const navigate = useNavigate();

    const [deleteCompany] = useDeleteMutation();
    const [deleteContact] = useDeleteContactMutation();
    const [deleteEmployee] = useDeleteEmployeeMutation();
    const [deleteDepartment] = useDeleteDepartmentMutation();

    const accountId = params.accountId;
    const companyId = params.companyId;
    const contactId = params.contactId;
    const employeeId = params.employeeId;
    const departmentId = params.departmentId;

    const [dropdownActionIsOpen, setDropdownActionIsOpen] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);
    const [entity, setEntity] = useState({});

    useEffect(() => {
        let obj = {};
        if (companyId !== undefined) {
            obj = {
                id: companyId,
                name: 'company',
                title: 'Удаление карточки',
                text: 'Вы действительно хотите удалить карточку?',
                action: deleteCompany,
                redirectUrl: `/account/${accountId}/companies`,
            };
        } else if (contactId !== undefined) {
            obj = {
                id: contactId,
                name: 'contact',
                title: 'Удаление контакта',
                text: 'Вы действительно хотите удалить контакт?',
                action: deleteContact,
                redirectUrl: `/account/${accountId}/contacts`,
            };
        } else if (employeeId !== undefined) {
            obj = {
                id: employeeId,
                name: 'employee',
                title: 'Удаление сотрудника',
                text: 'Вы действительно хотите удалить сотрудника?',
                action: deleteEmployee,
                redirectUrl: `/account/${accountId}/employees`,
            };
        } else if (departmentId !== undefined) {
            obj = {
                id: departmentId,
                name: 'department',
                title: 'Удаление отдела',
                text: 'Вы действительно хотите удалить отдел?',
                action: deleteDepartment,
                redirectUrl: `/account/${accountId}/departments`,
            };
        }
        setEntity(obj);
    }, [companyId, contactId, employeeId, departmentId]);

    const deleteEntity = () => {
        entity.action(entity.id);
        setOpenedDelete(false);
        navigate(entity.redirectUrl);
    };

    const openDeleteModal = () => {
        setOpenedDelete(true);
        setDropdownActionIsOpen(false);
    };

    return (
        <>
            <DeleteModal
                isOpen={openedDelete}
                okHandle={deleteEntity}
                closeHandle={() => setOpenedDelete(false)}
                deleteTitle={entity?.title}
                deleteText={entity?.text}
            />
            <OutsideClickHandler
                classes='d-inline-block'
                callback={() => setDropdownActionIsOpen(false)}
            >
                <>
                    <img
                        src='/images/icons/more-horizontal.svg'
                        className='cursor-pointer'
                        onClick={() => setDropdownActionIsOpen(true)}
                    />
                    <ul
                        className={
                            'dropdown-menu' +
                            (dropdownActionIsOpen ? ' show' : '')
                        }
                        style={{ right: 10 }}
                    >
                        {companyId !== undefined ||
                        contactId !== undefined ||
                        employeeId !== undefined ||
                        departmentId !== undefined ? (
                            <li>
                                <button
                                    className='dropdown-item'
                                    onClick={openDeleteModal}
                                >
                                    Удалить
                                </button>
                            </li>
                        ) : (
                            <i className='mx-2'>Нет доступных действий</i>
                        )}
                    </ul>
                </>
            </OutsideClickHandler>
        </>
    );
};
