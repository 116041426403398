import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { isAuthorize: false, token: null, security: null },
    reducers: {
        setCredentials: (state, action) => {
            localStorage.setItem(
                'access_token',
                action.payload.result.access_token.value
            );
            localStorage.setItem(
                'access_token_time',
                action.payload.result.access_token.expireAt
            );
            localStorage.setItem(
                'refresh_token',
                action.payload.result.refresh_token.value
            );
            localStorage.setItem(
                'refresh_token_time',
                action.payload.result.refresh_token.expireAt
            );
            state.isAuthorize = true;
            state.token = action.payload.result.access_token.value;
        },
        setSecurity: (state, action) => {
            state.security = action.payload;
            state.isAuthorize = true;
        },
        setIsAuthorize: (state, action) => {
            state.isAuthorize = action.payload;
        },
        logOut: (state, action) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_time');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_time');
            state.isAuthorize = false;
            state.token = null;
        },
    },
});

export const { setCredentials, logOut, setSecurity, setIsAuthorize } =
    authSlice.actions;

export default authSlice.reducer;

export const selectIsAuthorize = (state) => state.auth.isAuthorize;
export const selectCurrentToken = (state) => state.auth.token;
export const selectSecurity = (state) => state.auth.security;
