import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterIcon from '../../components/FilterIcon';
import FilterScalarHeader from './FilterScalarHeader';

const CallDirectionFilter = (props) => {
    const { value, valueChanged, classes } = props;
    let params = useParams();

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [description, setDescription] = useState('');

    useEffect(()=>{
        setSelectedValue(value);
    },[value])

    const selectValue = (value) => {
        valueChanged(value);
        setIsEdit(false);
        setSelectedValue(value);
        if(value === true) {
            setDescription(': входящие');
        } else if (value === false) {
            setDescription(': исходящие');
        } else {
            setDescription('');
        }
    }

    return (
        <>
            <FilterScalarHeader value={description} title='Направление' icon='shuffle' clickHandler={()=>setIsEdit(true)}>
                {isEdit ? (
                    <OutsideClickHandler callback={()=>setIsEdit(false)} classes={"dropdown width-200 height-100 filters-dropdown"} >
                        <>
                            <div className="dropdown-check cursor-pointer" onClick={()=>selectValue('')}>
                                <div className="dropdown-flex-start">
                                    <img src="/images/icons/loader.svg" width={16} className="icon-gray"/>
                                    <span>Не выбрано</span>
                                </div>
                                {selectedValue === '' || selectedValue === undefined ? (
                                        <img src='/images/icons/check.svg' width={20} />
                                    ) : ''}
                            </div>
                            <div className="dropdown-check cursor-pointer" onClick={()=>selectValue(true)}>
                                <div className="dropdown-flex-start">
                                    <img src="/images/icons/phone-incoming.svg" width={16} className="icon-gray" />
                                    Входящие
                                </div>
                                {selectedValue === true ? (
                                        <img src='/images/icons/check.svg' width={20} />
                                    ) : ''}
                            </div>
                            <div className="dropdown-check cursor-pointer" onClick={()=>selectValue(false)}>
                                <div className="dropdown-flex-start"   >
                                    <img src="/images/icons/phone-outgoing.svg" width={16} className="icon-gray" />
                                    Исходящие
                                    {selectedValue === false ? (
                                        <img src='/images/icons/check.svg' width={20} />
                                    ) : ''}
                                </div>
                            </div>
                        </>
                    </OutsideClickHandler>                

                ) : ''}
            </FilterScalarHeader>            
        </>
    );
};

export default CallDirectionFilter;