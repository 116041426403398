import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetListQuery } from '../../app/api/companiesApiSlice';
import Paginator from '../../components/Paginator';
import FindComponent from '../../components/filters/FindComponent';
import Button from '../../components/Button';
import SortableTableHead from '../../components/SortableTableHead';
import HoldingFilter from '../../components/filters/HoldingFilter';
import InnFilter from '../../components/filters/InnFilter';
import TagFilter from '../../components/filters/TagFilter';
import SliceIcon from '../../components/SliceIcon';
import { FormatDateToHuman2 } from '../../helpers/DateHelpers';

const CompaniesList = () => {
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;
    const [searchParams, setSearchParams] = useSearchParams();
    const [showArchive, setShowArchive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [holdingId, setHoldingId] = useState('');
    const [inn, setInn] = useState('');
    const [tagIds, setTagIds] = useState([]);

    const [sort, setSort] = useState('name');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [getList, { data }] = useLazyGetListQuery();

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                sort,
            });
            setSearchString(searchParams.get('search') ?? '');
        }
        loadDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadDataAsync() {
            console.log(tagIds);
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                showArchive,
                sort,
                holdingId,
                inn,
                tagIds,
                pageIndex,
                pageSize,
            });
        }
        loadDataAsync();
    }, [
        pageSize,
        pageIndex,
        searchString,
        sort,
        showArchive,
        holdingId,
        inn,
        tagIds,
    ]);

    const openEntityCard = (entityId) => {
        navigate(`/account/${accountId}/companies/${entityId}`);
    };

    return (
        <>
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' + (!showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(false)}
                    >
                        <SliceIcon value={!showArchive} filename='loader' />
                        Все
                    </div>
                    <div
                        className={
                            'slice ' + (showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(true)}
                    >
                        <SliceIcon value={showArchive} filename='archive' />
                        Показать архивные
                    </div>
                </div>
                <div>
                    <Button
                        label='Создать'
                        btnColor='blue'
                        handleClick={() =>
                            navigate(`/account/${accountId}/companies/0`)
                        }
                    />
                </div>
            </div>
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
                <HoldingFilter selectCallback={(v) => setHoldingId(v)} />
                <TagFilter
                    selectCallback={(v) => setTagIds(v)}
                    type='company'
                />
                <InnFilter selectCallback={(v) => setInn(v)} />
            </div>

            <div className='row'>
                <div className='col'>
                    <table className='table table-sm content-table'>
                        <thead>
                            <tr>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Название'
                                    field='name'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Юридическое название'
                                    field='fullname'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Холдинг'
                                    field='holding'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <th scope='col'>Категория</th>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Дата создания'
                                    field='date'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='ИНН'
                                    field='inn'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((item) => {
                                return (
                                    <tr
                                        className={
                                            'cursor-pointer' +
                                            (item.isArchive
                                                ? ' archive-row'
                                                : '')
                                        }
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                    >
                                        <td className='ps-3 left-cell text-medium'>
                                            {item.name}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.fullName}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.holding?.title}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item?.tags
                                                .map((t) => t.title)
                                                .join(', ')}
                                        </td>
                                        <td className='cell text-normal'>
                                            {FormatDateToHuman2(
                                                item.createdAt,
                                                false
                                            )}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.inn}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className='card-body'>
                        <Paginator
                            onPageChange={(e) => setPageIndex(e)}
                            onPageSizeChange={(e) => setPageSize(e)}
                            pageCount={data?.paginator?.totalPages ?? 0}
                            pageSizeArray={[50, 100, 500]}
                            pageSize={pageSize}
                            countCurrent={data?.result?.length ?? 0}
                            countTotal={data?.paginator?.totalCount ?? 0}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompaniesList;
