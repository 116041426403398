import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import SearchableList from '../filters/SearchableList';

import { useLazyGetEmployeesListQuery } from '../../app/api/employeesApiSlice';
import { useLazyGetDepartmentsListQuery } from '../../app/api/departmentsApiSlice';
import {
    useCreateMemberMutation,
    useDeleteMemberMutation,
    useLazyGetMembersListQuery,
} from '../../app/api/tasksApiSlice';

const MembersEditor = (props) => {
    const { value, selectCallback, type = 'simple', view = '' } = props;
    let params = useParams();

    const accountId = params.accountId;
    const taskId = params.taskId;

    const [isEdit, setIsEdit] = useState(false);
    const [slice, setSlice] = useState('employees');
    const [selectedValue, setSelectedValue] = useState([]);
    const [getEmployees, { data: employees }] = useLazyGetEmployeesListQuery();
    const [getDepartments, { data: departments }] =
        useLazyGetDepartmentsListQuery();
    const [getMembers, { data }] = useLazyGetMembersListQuery();
    const [createMember] = useCreateMemberMutation();
    const [deleteMember] = useDeleteMemberMutation();

    useEffect(() => {
        if (taskId > 0) {
            getMembers(taskId);
        }
    }, [taskId]);

    useEffect(() => {
        setSelectedValue(value ?? []);
    }, [value]);

    useEffect(() => {
        selectCallback(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        console.log(employees);
    }, [employees]);

    const selectEmployee = async (value) => {
        let exist = data.result.find(
            (x) => x.id === value.id && x.isGroup === false && x.type === type
        );
        if (exist === undefined) {
            const member = {
                type,
                memberId: value.id,
                isGroup: false,
                title: '',
            };
            await createMember({ member, taskId });
        }
    };

    const selectDepartment = async (value) => {
        let exist = selectedValue.find(
            (x) => x.id === value.id && x.isGroup === true && x.type === type
        );
        if (exist === undefined) {
            const member = {
                type,
                memberId: value.id,
                isGroup: true,
                title: '',
            };
            await createMember({ member, taskId });
        }
    };

    const loadEmployeesAsync = async (search) => {
        await getEmployees({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50,
        });
    };
    const loadDepartmentsAsync = async (search) => {
        await getDepartments({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50,
        });
    };

    const removeItem = async (item) => {
        await deleteMember(item.id);
    };

    return (
        <div
            className='task-members-panel position-relative'
            onClick={() => setIsEdit(true)}
        >
            <div className='task-members-list'>
                {data?.result?.filter((x) => x.type === type).length > 0 ? (
                    data?.result
                        ?.filter((x) => x.type === type)
                        ?.map((x) => {
                            return (
                                <div key={x.id} className='member-list-item'>
                                    {x.isGroup ? (
                                        <img
                                            src='/images/icons/users.svg'
                                            width={12}
                                            className='icon-main cursor-pointer me-1'
                                        />
                                    ) : (
                                        <img
                                            src='/images/icons/user.svg'
                                            width={12}
                                            className='icon-main cursor-pointer me-1'
                                        />
                                    )}
                                    <span>{x.title}</span>
                                </div>
                            );
                        })
                ) : (
                    <span>Пусто</span>
                )}
            </div>
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={'dropdown width-300 height-450 filters-dropdown'}
                >
                    <>
                        <div className='filters-tag-list borders mx-3 mb-2'>
                            {data?.result
                                ?.filter((x) => x.type === type)
                                ?.map((x) => {
                                    return (
                                        <div
                                            key={x.id}
                                            className='member-list-item'
                                        >
                                            {x.isGroup ? (
                                                <img
                                                    src='/images/icons/users.svg'
                                                    width={12}
                                                    className='icon-main cursor-pointer me-1'
                                                />
                                            ) : (
                                                <img
                                                    src='/images/icons/user.svg'
                                                    width={12}
                                                    className='icon-main cursor-pointer me-1'
                                                />
                                            )}
                                            <span>{x.title}</span>
                                            <img
                                                src='/images/icons/x.svg'
                                                width={12}
                                                className='icon-main cursor-pointer ms-1'
                                                onClick={() => removeItem(x)}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                        <div
                            className='slices-container'
                            style={{ justifyContent: 'space-between' }}
                        >
                            <div className='alignCenter'>
                                {view == 'employees' || view == '' ? (
                                    <div
                                        className={
                                            'slice ' +
                                            (slice === 'employees'
                                                ? 'active-slice'
                                                : '')
                                        }
                                        onClick={() => setSlice('employees')}
                                    >
                                        Сотрудники
                                    </div>
                                ) : null}
                                {view == 'departments' || view == '' ? (
                                    <div
                                        className={
                                            'slice ' +
                                            (slice === 'departments'
                                                ? 'active-slice'
                                                : '')
                                        }
                                        onClick={() => setSlice('departments')}
                                    >
                                        Отделы
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {slice === 'employees' ? (
                            <>
                                <SearchableList
                                    list={employees?.result}
                                    searchCallback={(search) =>
                                        loadEmployeesAsync(search)
                                    }
                                    selectCallback={(value) =>
                                        selectEmployee(value)
                                    }
                                    hideEmpty={true}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        {slice === 'departments' ? (
                            <>
                                <SearchableList
                                    list={departments?.result}
                                    searchCallback={(search) =>
                                        loadDepartmentsAsync(search)
                                    }
                                    selectCallback={(value) =>
                                        selectDepartment(value)
                                    }
                                    hideEmpty={true}
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </>
                </OutsideClickHandler>
            ) : (
                ''
            )}
        </div>
    );
};

export default MembersEditor;
