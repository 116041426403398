import moment from 'moment';
import { useEffect, useState } from 'react';

const DateInput = (props) => {
    const { value, placeholder, valueChanged, classes } = props;
    const [text, setText] = useState();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setText(moment(value).format('yyyy-MM-DD'));
    }, [value]);

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commitChanges();
        } else if (event.code === 'Escape') {
            setText(value);
            setIsEdit(false);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if (text !== value) {
            console.log('commit');
            valueChanged(text);
        } else {
            console.log('no commit');
        }
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <input
                    className={
                        'form-control text-input text-input-active ' + classes
                    }
                    placeholder={placeholder}
                    value={text}
                    onChange={(v) => setText(v.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={commitChanges}
                    type='date'
                />
            ) : (
                <>
                    {value ? (
                        <span
                            className={'text-input text-input-hover ' + classes}
                            onClick={() => setIsEdit(true)}
                        >
                            {moment(value).format('DD MMM yyyy')}
                        </span>
                    ) : (
                        <span
                            className={
                                'text-input text-input-hover text-input-defalt ' +
                                classes
                            }
                            onClick={() => setIsEdit(true)}
                        >
                            Пусто
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default DateInput;
