import { useEffect, useState } from "react";
import {  useParams } from 'react-router-dom';

import { useLazyGetEmployeesListQuery } from '../../app/api/employeesApiSlice';
import SimpleDropdown from "./SimpleDropdown";

const EmployeesDropdown = (props) => {
    const {value, selectCallback, disabled=false} = props;

    let params = useParams();
    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [getList, { data }] = useLazyGetEmployeesListQuery();

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50
        });
    }


    const [searchString, setSearchString] = useState('');

    return (
        <>
            <SimpleDropdown 
                value={value} 
                list={data?.result} 
                searchCallback={(search)=>loadDataAsync(search)} 
                selectCallback={(value)=>{
                    console.log(value);
                    selectCallback(value);
                }}
                disabled={disabled}
            />
        </>
    );
};

export default EmployeesDropdown;