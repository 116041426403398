import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetAccountsListQuery } from '../../../app/api/accountsApiSlice';
import Paginator from '../../../components/Paginator';
import FindComponent from '../../../components/filters/FindComponent';
import Button from '../../../components/Button';
import SortableTableHead from '../../../components/SortableTableHead';
import SliceIcon from '../../../components/SliceIcon';
import { FormatDateToHuman2 } from '../../../helpers/DateHelpers';

const AccountsList = () => {
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;
    const [searchParams, setSearchParams] = useSearchParams();
    const [showArchive, setShowArchive] = useState(false);
    const [searchString, setSearchString] = useState('');

    const [sort, setSort] = useState('name');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [getList, { data }] = useLazyGetAccountsListQuery();

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                search: encodeURIComponent(searchString),
                showArchive,
                sort,
                pageIndex,
                pageSize,
            });
        }
        loadDataAsync();
    }, [
        pageSize,
        pageIndex,
        searchString,
        sort,
        showArchive,
    ]);

    const openEntityCard = (entityId) => {
        navigate(`/administrator/accounts/${entityId}`);
    };

    return (
        <>
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' + (!showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(false)}
                    >
                        <SliceIcon value={!showArchive} filename='loader' />
                        Все
                    </div>
                    <div
                        className={
                            'slice ' + (showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(true)}
                    >
                        <SliceIcon value={showArchive} filename='archive' />
                        Показать архивные
                    </div>
                </div>
                <div>
                    <Button
                        label='Создать'
                        btnColor='blue'
                        handleClick={() =>
                            navigate(`/administrator/accounts/0`)
                        }
                    />
                </div>
            </div>
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
            </div>

            <div className='row'>
                <div className='col'>
                    <table className='table table-sm content-table'>
                        <thead>
                            <tr>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Название'
                                    field='name'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <th>
                                    Действия
                                </th>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='VATS ID'
                                    field='vatsAccountId'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Дата создания'
                                    field='date'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((item) => {
                                return (
                                    <tr
                                        className={
                                            'cursor-pointer' +
                                            (item.isArchive
                                                ? ' archive-row'
                                                : '')
                                        }
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                    >
                                        <td className='ps-3 left-cell text-medium'>
                                            {item.name}
                                        </td>
                                        <td className='cell text-normal'>
                                            <a 
                                                class=""
                                                href={`/account/${item.id}/overview`}
                                            >
                                                Открыть
                                            </a>
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.vatsAccountId}
                                        </td>
                                        <td className='cell text-normal'>
                                            {FormatDateToHuman2(
                                                item.createdAt,
                                                false
                                            )}
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className='card-body'>
                        <Paginator
                            onPageChange={(e) => setPageIndex(e)}
                            onPageSizeChange={(e) => setPageSize(e)}
                            pageCount={data?.paginator?.totalPages ?? 0}
                            pageSizeArray={[50, 100, 500]}
                            pageSize={pageSize}
                            countCurrent={data?.result?.length ?? 0}
                            countTotal={data?.paginator?.totalCount ?? 0}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountsList;
