import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetCallsListQuery } from '../../app/api/callsApiSlice';
import {
    ToUtcString,
    GetToday,
    FormatDateToHuman2,
    GetYesterday,
} from '../../helpers/DateHelpers';
import { useSecurity } from '../../features/auth/useSecurity';
import Paginator from '../../components/Paginator';
import FindComponent from '../../components/filters/FindComponent';
import RecordPlayer from '../../components/RecordPlayer';
import CallDirectionFilter from '../../components/filters/CallDirectionFilter';
import CompanyFilter from '../../components/filters/CompanyFilter';
import ContactFilter from '../../components/filters/ContactFilter';
import OperatorFilter from '../../components/filters/OperatorFilter';
import DateRangeFilter from '../../components/filters/DateRangeFilter';
import SliceIcon from '../../components/SliceIcon';
import LinkPhoneToContactModal from '../../components/modals/LinkPhoneToContactModal';
import PhoneComponent from '../../components/calls/PhoneComponent';

const CallsList = (props) => {
    const {
        fastFilters = true,
        preContactId = '',
        preCompanyId = '',
        excludeCols = [],
        preSlice = 'my',
    } = props;

    let params = useParams();

    let security = useSecurity();
    const [searchParams, setSearchParams] = useSearchParams();

    const accountId = params.accountId;
    const [getCalls, { data, isLoading }] = useLazyGetCallsListQuery();
    const [start, setStart] = useState(GetToday());
    const [finish, setFinish] = useState(GetToday());
    const [searchString, setSearchString] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [incoming, setIncoming] = useState('');
    const [companyId, setCompanyId] = useState(preCompanyId);
    const [contactId, setContactId] = useState(preContactId);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [employeeId, setEmployeeId] = useState('');
    const [processed, setProcessed] = useState('');

    const [slice, setSlice] = useState(searchParams.get('show') ?? preSlice);
    const [linkContactModal, setLinkContactModal] = useState(false);
    const [linkedPhone, setLinkedPhone] = useState('');
    const [linkedCallId, setLinkedCallId] = useState(0);

    useEffect(() => {
        let account = security?.accounts.find((x) => x.id == accountId);
        if (account) {
            setEmployeeId(account.employeeId);
            setOperatorId(account.employeeId);
        }
    }, [security]);

    useEffect(() => {
        if (slice === 'in') {
            setIncoming(true);
            setCompanyId(preCompanyId);
            setOperatorId(employeeId);
            setProcessed('');
            setStart(GetToday());
            setFinish(GetToday());
        } else if (slice === 'out') {
            setIncoming(false);
            setCompanyId(preCompanyId);
            setOperatorId(employeeId);
            setProcessed('');
            setStart(GetToday());
            setFinish(GetToday());
        } else if (slice === 'my') {
            setIncoming('');
            setCompanyId(preCompanyId);
            setOperatorId(employeeId);
            setProcessed('');
            setStart(GetToday());
            setFinish(GetToday());
        } else if (slice === 'yesterday') {
            setIncoming('');
            setCompanyId(preCompanyId);
            setOperatorId(employeeId);
            setProcessed('');
            setStart(GetYesterday());
            setFinish(GetYesterday());
        } else if (slice === 'missed') {
            setIncoming(true);
            setCompanyId('');
            setOperatorId('');
            setProcessed(false);
            setStart(GetToday());
            setFinish(GetToday());
        } else if (slice === 'all') {
            setIncoming('');
            setCompanyId(preCompanyId);
            setOperatorId('');
            setProcessed('');
            setStart(GetToday());
            setFinish(GetToday());
        }
    }, [slice, employeeId]);

    useEffect(() => {
        async function loadDataAsync() {
            await getCalls({
                accountId,
                processed,
                start: ToUtcString(start),
                finish: ToUtcString(finish),
                search: encodeURIComponent(searchString),
                operatorId,
                companyId,
                contactId,
                incoming,
                pageSize,
                pageIndex,
            });
        }
        loadDataAsync();
    }, [
        searchString,
        pageSize,
        pageIndex,
        incoming,
        companyId,
        contactId,
        operatorId,
        employeeId,
        processed,
        start,
        finish,
    ]);

    const StatusIcon = (props) => {
        let incoming = props.incoming;
        let answered = props.answered;
        return (
            <img
                src={`/images/icons/${
                    incoming ? 'arrow-down-left' : 'arrow-up-right'
                }.svg`}
                className={answered ? 'icon-green' : 'icon-red'}
                width={16}
            />
        );
    };

    const changeLinkedPhone = (id, phone) => {
        setLinkedCallId(id);
        setLinkedPhone(phone);
        setLinkContactModal(true);
    };

    console.log('calls', data);

    return (
        <>
            <LinkPhoneToContactModal
                isOpen={linkContactModal}
                closeHandle={() => setLinkContactModal(false)}
                phone={linkedPhone}
                callId={linkedCallId}
            />
            {fastFilters ? (
                <div
                    className='slices-container'
                    style={{ justifyContent: 'space-between' }}
                >
                    <div className='alignCenter'>
                        <div
                            className={
                                'slice ' +
                                (slice === 'my' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('my')}
                        >
                            <SliceIcon
                                value={slice === 'my'}
                                filename='user-check'
                            />
                            Мои
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'yesterday' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('yesterday')}
                        >
                            <SliceIcon
                                value={slice === 'yesterday'}
                                filename='my-call-yesterday'
                            />
                            Мои вчерашние
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'in' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('in')}
                        >
                            <SliceIcon
                                value={slice === 'in'}
                                filename='phone-incoming'
                            />
                            Входящие
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'out' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('out')}
                        >
                            <SliceIcon
                                value={slice === 'out'}
                                filename='phone-outgoing'
                            />
                            Исходящие
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'missed' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('missed')}
                        >
                            <SliceIcon
                                value={slice === 'missed'}
                                filename='phone-missed'
                            />
                            Пропущенные
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'all' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('all')}
                        >
                            <SliceIcon
                                value={slice === 'all'}
                                filename='loader'
                            />
                            Все
                        </div>
                    </div>
                </div>
            ) : null}

            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
                <DateRangeFilter
                    value={{ start, finish }}
                    valueChanged={(e) => {
                        if (e?.start) setStart(e.start);
                        else setStart(GetToday());
                        if (e?.finish) setFinish(e.finish);
                        else setFinish(GetToday());
                    }}
                />
                <CallDirectionFilter valueChanged={(v) => setIncoming(v)} />
                {preContactId || preCompanyId ? null : (
                    <CompanyFilter selectCallback={(v) => setCompanyId(v)} />
                )}
                {preContactId ? null : (
                    <ContactFilter
                        selectCallback={(v) => setContactId(v)}
                        companyId={companyId}
                    />
                )}
                <OperatorFilter selectCallback={(v) => setOperatorId(v)} />
            </div>

            <div className='row'>
                <div className='col'>
                    <table className='table table-sm content-table'>
                        <thead>
                            <tr>
                                <th scope='col' width={200}>
                                    Дата и время
                                </th>
                                <th scope='col' width={70}>
                                    Запись
                                </th>
                                {excludeCols.find((x) => x === 'company') ===
                                undefined ? (
                                    <th scope='col'>Компания</th>
                                ) : null}
                                {excludeCols.find((x) => x === 'contact') ===
                                undefined ? (
                                    <th scope='col'>Контакт</th>
                                ) : null}
                                <th scope='col' width={180}>
                                    Телефон
                                </th>
                                <th scope='col'>Оператор</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((item) => {
                                return (
                                    <tr className='' key={item.id}>
                                        <td
                                            width='150px'
                                            className='left-cell text-medium'
                                        >
                                            <StatusIcon
                                                incoming={item.incoming}
                                                answered={
                                                    item.answerTime !== null
                                                }
                                            />
                                            {FormatDateToHuman2(item.createdAt)}
                                            {item.recalled &&
                                            item.incoming &&
                                            item.answerTime === null ? (
                                                <img
                                                    src='/images/icons/phone-outgoing.svg'
                                                    width={16}
                                                    className='ms-2 icon-green'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title='Уже перезвонили'
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {item.recalledByClient &&
                                            item.incoming &&
                                            item.answerTime === null ? (
                                                <img
                                                    src='/images/icons/phone-incoming.svg'
                                                    width={16}
                                                    className='ms-2 icon-green'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title='Клиент перезвонил'
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td
                                            width='90px'
                                            className='cell text-medium'
                                        >
                                            <RecordPlayer call={item} />
                                        </td>
                                        {excludeCols.find(
                                            (x) => x === 'company'
                                        ) === undefined ? (
                                            <td className='cell text-medium'>
                                                {item.company?.title}
                                            </td>
                                        ) : null}
                                        {excludeCols.find(
                                            (x) => x === 'contact'
                                        ) === undefined ? (
                                            <td className='cell text-medium'>
                                                {item.contact?.title}
                                            </td>
                                        ) : null}
                                        <td
                                            className='cell text-medium hover-show-parent'
                                            style={{ minWidth: '190px' }}
                                        >
                                            <PhoneComponent
                                                phone={item.clientPhone}
                                                accountId={accountId}
                                                contactId={
                                                    item.contact?.id ?? null
                                                }
                                                callId={item.id}
                                            />
                                            {!item.contact?.title ? (
                                                <img
                                                    src='/images/icons/user-plus.svg'
                                                    className='icon-main ms-2 hover-show cursor-pointer'
                                                    onClick={() =>
                                                        changeLinkedPhone(
                                                            item.id,
                                                            item.clientPhone
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </td>
                                        <td className='right-cell text-medium'>
                                            {item.operator?.title
                                                ? item.operator?.title
                                                : item.operatorPhone}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className='card-body'>
                        <Paginator
                            onPageChange={(e) => setPageIndex(e)}
                            onPageSizeChange={(e) => setPageSize(e)}
                            pageCount={data?.paginator?.totalPages ?? 0}
                            pageSizeArray={[50, 100, 500]}
                            pageSize={pageSize}
                            countCurrent={data?.result?.length ?? 0}
                            countTotal={data?.paginator?.totalCount ?? 0}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CallsList;
