import { useEffect, useState } from "react";
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import { useGetAccountByIdQuery } from '../../app/api/accountsApiSlice';

// Хлебные крошки
const Breadcrumbs = (props) => {
    let location = useLocation();
    let params = useParams();

    const folders = props.folders;

    const accountId = params.accountId;
    const {data: account, error, isLoading } = useGetAccountByIdQuery(accountId);

    return (
        <div className="breadcrumbs">
            {account ? (
                <div className="breadcrumbs-link">
                    <Link to={'/account/' + accountId + '/overview'} >
                        <img src={'/images/icons/home.svg'} className="mb-1 icon-gray" /> {account.result.name}
                        <img src="/images/icons/slash-divider.svg" width={24} />
                    </Link>
                </div>
            ) : ''}
            {folders.map((x, i)=>{
                let url = x.to;
                if (url) {
                    url = url.replace(':accountId', accountId);
                }

                return (
                    <div key={i} className="breadcrumbs-link">
                        <Link to={url} >
                            <img src={'/images/icons/' + x.icon} className="mb-1 icon-gray" /> {x.title}
                            {folders.length>1 && i < folders.length-1 ? (
                                <img src="/images/icons/slash-divider.svg" width={24} />
                            ) : ""}
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;