import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSecurityMutation } from './authApiSlice';
import { selectIsAuthorize, selectSecurity, setSecurity } from './authSlice';

export function useSecurity() {
    const dispatch = useDispatch();
    const isAuthorize = useSelector(selectIsAuthorize);
    const [security] = useSecurityMutation();
    const securityContext = useSelector(selectSecurity);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (isAuthorize && securityContext === null) {
                const request = await security().unwrap();
                dispatch(setSecurity(request.result));
            }
        }

        loadResourcesAndDataAsync();
    }, [isAuthorize]);

    return securityContext;
}
