import { apiSlice } from './apiSlice';

export const reportsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getReportsList: builder.query({
            query: (data) =>
                'api/reports/calls?' +
                `accountId=${data.accountId}` +
                `&start=${data.start}` +
                `&finish=${data.finish}` +
                `&groupType=${data.groupType ?? 0}`,
            providesTags: (result) => [{ type: 'Reports', id: 'List' }],
        }),
    }),
});

export const { useGetReportsListQuery, useLazyGetReportsListQuery } =
    reportsApiSlice;
