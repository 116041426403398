import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterIcon from '../../components/FilterIcon';
import FilterObjectHeader from './FilterObjectHeader';
import SearchableList from './SearchableList';

import { useLazyGetListQuery } from '../../app/api/companiesApiSlice';

const CompanyFilter = (props) => {
    const { value, selectCallback } = props;
    let params = useParams();

    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [getList, { data }] = useLazyGetListQuery();

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const selectValue = (value) => {
        selectCallback(value?.id);
        setIsEdit(false);
        setSelectedValue(value);
    };

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50,
        });
    };

    return (
        <>
            <FilterObjectHeader
                value={selectedValue}
                title='Компания'
                icon='file'
                clickHandler={() => setIsEdit(true)}
            >
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-300 height-400 filters-dropdown'
                        }
                    >
                        <>
                            <SearchableList
                                value={value}
                                list={data?.result}
                                searchCallback={(search) =>
                                    loadDataAsync(search)
                                }
                                selectCallback={(value) => selectValue(value)}
                            />
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </FilterObjectHeader>
        </>
    );
};

export default CompanyFilter;
