import { apiSlice } from './apiSlice';

export const tagsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTagsList: builder.query({
            query: (data) =>
            'api/tags?' +
            `accountId=${data.accountId}` + `&showArchive=${data.showArchive}` + `&type=${data.type}` +
            `&search=${data.search}&sort=${data.sort}` +
            `&pageSize=${data.pageSize ?? 100}&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Tags',
                              id,
                          })),
                          { type: 'Tags', id: 'List' },
                      ]
                    : [{ type: 'Tags', id: 'List' }],
        }),
        getTagById: builder.query({
            query: (entityId) => `api/tags/${entityId}`,
            providesTags: (result) => [{ type: 'Tag', id: result.result.id }],
        }),
        createTag: builder.mutation({
            query: (credentials) => ({
                url: 'api/tags',
                method: 'POST',
                body: { ...credentials },
            }),
            invalidatesTags: [{ type: 'Tags', id: 'List' }],
        }),
        updateTag: builder.mutation({
            query: (credentials) => ({
                url: 'api/tags/' + credentials.id,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tags', id: 'List' },
                { type: 'Tag', id: result.result.id },
            ],
        }),
        patchTag: builder.mutation({
            
            query: ({id, items}) => ({
                url: 'api/tags/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tags', id: 'List' },
                { type: 'Tag', id: result.result.id },
            ],
        }),
        deleteTag: builder.mutation({
            query: (entityId) => ({
                url: 'api/tags/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Tags', id: 'List' }],
        }),
    }),
});

export const {
    useGetTagsListQuery,
    useLazyGetTagsListQuery,
    useGetTagByIdQuery,
    useLazyGetTagByIdQuery,
    useCreateTagMutation,
    useUpdateTagMutation,
    usePatchTagMutation,
    useDeleteTagMutation,
} = tagsApiSlice;
