import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterIcon from '../../components/FilterIcon';
import FilterScalarHeader from './FilterScalarHeader';
import DropDownWithCheck from '../dropdowns/DropDownWithCheck';

const tasksArray = [
    {
        value: '',
        text: 'Не выбрано',
        icon: '/images/icons/circle.svg',
        iconClass: 'icon-status-none',
        blockClass: 'filters-b-gray',
    },
    {
        value: 'draft',
        text: 'Черновик',
        icon: '/images/icons/edit.svg',
        iconClass: 'icon-status-draft',
        blockClass: 'filters-b-gray',
    },
    {
        value: 'postponed',
        text: 'Отложено',
        icon: '/images/icons/postponed.svg',
        iconClass: 'icon-status-postponed',
        blockClass: 'filters-b-postponed',
    },
    {
        value: 'important',
        text: 'Важно',
        icon: '/images/icons/flag.svg',
        iconClass: 'icon-status-important',
        blockClass: 'filters-b-important',
    },
    {
        value: 'complete',
        text: 'Выполнено',
        icon: '/images/icons/check.svg',
        iconClass: 'icon-status-success',
        blockClass: 'filters-b-gray',
    },
];

const TaskStatusFilter = (props) => {
    const { value, valueChanged, classes } = props;
    let params = useParams();

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setSelectedValue(value ?? '');
    }, [value]);

    const selectValue = (value) => {
        valueChanged(value.value);
        setIsEdit(false);
        setSelectedValue(value.value);
        if (value.value === 'draft') {
            setDescription(': черновик');
        } else if (value.value === 'postponed') {
            setDescription(': отложено');
        } else if (value.value === 'important') {
            setDescription(': важно');
        } else if (value.value === 'complete') {
            setDescription(': выполнено');
        } else {
            setDescription('');
        }
    };

    return (
        <>
            <FilterScalarHeader
                value={description}
                title="Статус"
                icon="copy"
                clickHandler={() => setIsEdit(true)}
            >
                {isEdit ? (
                    <DropDownWithCheck
                        listArray={tasksArray}
                        outsideClick={() => setIsEdit(false)}
                        selectHandle={(value) => selectValue(value)}
                        selectedValue={selectedValue}
                    />
                ) : (
                    ''
                )}
            </FilterScalarHeader>
        </>
    );
};

export default TaskStatusFilter;
