import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import SearchableList from '../filters/SearchableList';

import { useLazyGetListQuery } from '../../app/api/companiesApiSlice';

const CompanyDropdownSelector = (props) => {
    const { value, valueId, selectCallback } = props;
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [getList, { data }] = useLazyGetListQuery();

    const selectValue = (value) => {
        setIsEdit(false);
        selectCallback(value?.id);
    };

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50,
        });
    };

    const openCompany = () => {
        navigate(`/account/${accountId}/companies/${valueId}`);
    };

    return (
        <div
            className='company-edit position-relative d-inline-block me-2'
            onClick={() => setIsEdit(true)}
        >
            {value ? (
                <div className='company-name hover-show-parent'>
                    {value}
                    <img
                        src='/images/icons/corner-down-right.svg'
                        width='18px'
                        className='ms-1 icon-medium-dark cursor-pointer hover-show'
                        onClick={openCompany}
                    />
                </div>
            ) : (
                <span className=''>Пусто</span>
            )}
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={
                        'dropdown width-300 height-400 filters-dropdown d-inline-block top-26'
                    }
                >
                    <>
                        <SearchableList
                            value={value}
                            list={data?.result}
                            searchCallback={(search) => loadDataAsync(search)}
                            selectCallback={(value) => selectValue(value)}
                        />
                    </>
                </OutsideClickHandler>
            ) : null}
        </div>
    );
};

export default CompanyDropdownSelector;
