import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PLATFORM_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        console.log('getRefreshToken');
        const refreshResult = await baseQuery(
            {
                url: 'api/auth/refresh_token',
                method: 'POST',
                body: {
                    refresh_token: localStorage.getItem('refresh_token'),
                },
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            console.log('updateToken');
            console.log('refreshResult', refreshResult);
            localStorage.setItem(
                'access_token',
                refreshResult.data.result.access_token.value
            );
            localStorage.setItem(
                'access_token_time',
                refreshResult.data.result.access_token.expireAt
            );
            localStorage.setItem(
                'refresh_token',
                refreshResult.data.result.refresh_token.value
            );
            localStorage.setItem(
                'refresh_token_time',
                refreshResult.data.result.refresh_token.expireAt
            );
            result = await baseQuery(args, api, extraOptions);
        } else {
            console.log('logout');
            api.dispatch(logOut());
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Companies',
        'Company',
        'Contacts',
        'Contact',
        'Tags',
        'Tag',
        'Employees',
        'Employee',
        'Departments',
        'Department',
        'Messages',
        'Holdings',
        'Notifications',
        'Accounts'
    ],
    endpoints: (builder) => ({}),
});
