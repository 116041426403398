import { useEffect, useState } from "react";

const AddRow = (props) => {
    const { placeholder, valueChanged, classes } = props;
    const [text, setText] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commitChanges();
        }
        else if (event.code === 'Escape') {
            setText('');
            setIsEdit(false);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if(text!=='')
        {
            valueChanged(text);
            setText('');
        }
        else {
            console.log('no commit');
        }
        setIsEdit(false);
    }


    return (
        <>
            {isEdit ? (
                <input className="form-control text-input text-input-active" 
                placeholder={placeholder}
                value={text}
                onChange={(v)=>setText(v.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={commitChanges}
                />
            )
            : (
                <>
                    <span className="text-input text-input-hover text-input-defalt" onClick={()=>setIsEdit(true)}>
                        <img src="/images/icons/plus.svg" width={16} />
                        <span>{placeholder}</span>
                    </span>
                </>
            )}
            
        </>
    );
};

export default AddRow;