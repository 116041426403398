import { useParams } from 'react-router-dom';
import MenuItem from './MenuItem';
import MenuItemWithSub from './MenuItemWithSub';

const MenuMain = (props) => {
    const params = useParams();
    const accountId = params.accountId;

    return (
        <>
            <MenuItem
                to={`/account/${accountId}/overview`}
                icon={
                    <img
                        src='/images/icons/home.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Обзор'
            />
            <MenuItem
                to={`/account/${accountId}/tasks`}
                icon={
                    <img
                        src='/images/icons/target.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Задачи'
            />
            <MenuItem
                to={`/account/${accountId}/companies`}
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Компании'
            />
            <MenuItem
                to={`/account/${accountId}/contacts`}
                icon={
                    <img
                        src='/images/icons/user.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Контакты'
            />
            <MenuItem
                to={`/account/${accountId}/calls`}
                icon={
                    <img
                        src='/images/icons/phone-call.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Вызовы'
            />
            <MenuItem
                to={`/account/${accountId}/departments`}
                icon={
                    <img
                        src='/images/icons/grid.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Отделы'
            />
            <MenuItem
                to={`/account/${accountId}/employees`}
                icon={
                    <img
                        src='/images/icons/user.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Сотрудники'
            />
            <MenuItem
                to={`/account/${accountId}/tags`}
                icon={
                    <img
                        src='/images/icons/tag.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Теги'
            />
            <MenuItem
                to={`/account/${accountId}/holdings`}
                icon={
                    <img
                        src='/images/icons/holding.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Холдинги'
            />
            <MenuItemWithSub
                icon={
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Отчёты'
            >
                <MenuItem
                    to={`/account/${accountId}/reports`}
                    icon={
                        <img
                            src='/images/icons/file-text.svg'
                            width={16}
                            className='me-2 icon-nav'
                        />
                    }
                    title='Звонки'
                />
            </MenuItemWithSub>
        </>
    );
};

export default MenuMain;
