import { useState } from 'react';
import {
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import PageTitle from '../../../components/layout/PageTitle';
import SliceIcon from '../../../components/SliceIcon';
import ContactInfoBlock from './ContactInfoBlock';
import TasksList from '../../tasks/TasksList';
import Button from '../../../components/Button';
import CallsList from '../../calls/CallsList';
import {
    useCreateContactMutation,
    useLazyGetContactByIdQuery,
    usePatchContactMutation,
} from '../../../app/api/contactsApiSlice';
import { useEffect } from 'react';
import { useLazyGetByIdQuery } from '../../../app/api/companiesApiSlice';

const ContactCardPage = () => {
    let params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('companyId');

    const accountId = params.accountId;
    const entityId = params.contactId;

    const [mode, setMode] = useState('info');
    const [createContact] = useCreateContactMutation();
    const [patchContact, { r }] = usePatchContactMutation();
    const [getContact, { data }] = useLazyGetContactByIdQuery();
    const [getCompany, { data: companyData }] = useLazyGetByIdQuery();
    const [company, setCompany] = useState({ id: 0, title: 'пусто' });

    useEffect(() => {
        async function fetchCompany() {
            const result = await getCompany(companyId);
            setCompany({
                id: result.data.result.id,
                title: result.data.result.name,
            });
        }
        if (entityId > 0) {
            getContact(entityId);
        }
        if (companyId > 0) {
            fetchCompany();
        }
    }, [entityId, companyId]);

    const currentTab = () => {
        let tab = null;
        switch (mode) {
            case 'info':
                tab = (
                    <ContactInfoBlock
                        contactId={entityId}
                        preCompany={company}
                    />
                );
                break;
            case 'tasks':
                tab = (
                    <TasksList
                        fastFilters={false}
                        preContactId={entityId}
                        excludeCols={['contact']}
                        page='contact'
                    />
                );
                break;
            case 'calls':
                tab = (
                    <CallsList
                        fastFilters={false}
                        preContactId={entityId}
                        excludeCols={['company', 'contact']}
                        preSlice='all'
                    />
                );
                break;

            default:
                break;
        }
        return tab;
    };

    const patch = (obj) => {
        patchContact({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    };

    const saveTitle = async (name) => {
        if (entityId === '0') {
            const entity = {
                id: entityId ?? 0,
                account: { id: accountId },
                name,
                company: { id: company.id },
            };
            let r = await createContact(entity);
            if (r?.data?.result?.id > 0) {
                navigate(
                    `/account/${accountId}/contacts/${r?.data?.result?.id}`
                );
            }
        } else {
            patch({ path: 'name', value: name });
        }
    };

    return (
        <>
            <PageTitle
                title={data?.result?.name}
                icon={
                    <img
                        src='/images/icons/visitors.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                valueChanged={(name) => saveTitle(name)}
                isNew={entityId === '0'}
                maxLength={150}
            />
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' + (mode === 'info' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('info')}
                    >
                        <SliceIcon value={mode === 'info'} filename='info' />
                        Информация
                    </div>
                    <div
                        className={
                            'slice ' + (mode === 'tasks' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('tasks')}
                    >
                        <SliceIcon value={mode === 'tasks'} filename='target' />
                        Задачи
                    </div>
                    <div
                        className={
                            'slice ' + (mode === 'calls' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('calls')}
                    >
                        <SliceIcon
                            value={mode === 'calls'}
                            filename='phone-call'
                        />
                        Вызовы
                    </div>
                </div>
                <div>
                    {mode === 'tasks' ? (
                        <Button
                            label='Добавить задачу'
                            btnColor='blue'
                            handleClick={() =>
                                navigate({
                                    pathname: `/account/${accountId}/contacts/${entityId}/tasks/0`,
                                    search: `?contactId=${entityId}`,
                                })
                            }
                        />
                    ) : null}
                </div>
            </div>
            {currentTab()}
            <Outlet />
        </>
    );
};

export default ContactCardPage;
