import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'api/auth/login',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        getSmsCode: builder.query({
            query: (credentials) =>
                `api/auth/verification_code?login=${credentials.login}`,
        }),
        loginBySms: builder.mutation({
            query: (credentials) => ({
                url: 'api/auth/login_code',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        changePassword: builder.mutation({
            query: (credentials) => ({
                url: 'api/auth/change_password',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        security: builder.mutation({
            query: () => ({
                url: 'api/auth/security',
                method: 'POST',
            }),
        }),
        deleteSecurity: builder.mutation({
            query: () => ({
                url: 'api/auth/security',
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useLazyGetSmsCodeQuery,
    useChangePasswordMutation,
    useLoginBySmsMutation,
    useSecurityMutation,
    useDeleteSecurityMutation,
} = authApiSlice;
