import SkeletonTable from '../../components/skeleton/SkeletonTable';

const ReportTableAll = (props) => {
    const { data, isLoading } = props;
    return (
        <SkeletonTable isLoading={isLoading}>
            <table className='table table-sm content-table table-bordered'>
                <thead>
                    <tr>
                        <th scope='col' rowSpan={2}>
                            Период
                        </th>
                        <th scope='col' colSpan={3}>
                            Входящие
                        </th>
                        <th scope='col'></th>
                    </tr>
                    <tr>
                        <th scope='col'>Всего</th>
                        <th scope='col'>Отвеченные</th>
                        <th scope='col'>Пропущенные</th>
                        <th scope='col'>Исходящие</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.period}</td>
                                <td>{item.incomingTotal}</td>
                                <td>{item.incomingProcessed}</td>
                                <td>{item.incomingNotProcessed}</td>
                                <td>{item.outgoingTotal}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </SkeletonTable>
    );
};

export default ReportTableAll;
