const TaskStatus = (props) => {
    const { entity } = props;

    const StatusIcon = ()=> {
        if(entity.status === 'draft'){
            return (
                <img 
                    src='/images/icons/edit.svg' 
                    className="icon-status-draft" 
                    width={16} 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Черновик"
                />
            )
        }
        else if(entity.status === 'postponed'){
            return (
                <img 
                    src='/images/icons/postponed.svg' 
                    className="icon-status-postponed" 
                    width={16} 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Отложенно"
                />
            )
        }
        else if(entity.status === 'important'){
            return (
                <img 
                    src='/images/icons/flag.svg' 
                    className="icon-status-important" 
                    width={16} 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Важно"
                />
            )
        }
        else if(entity.status === 'complete'){
            return (
                <img 
                    src='/images/icons/check.svg' 
                    className="icon-status-success" 
                    width={16} 
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Выполнено"
                />
            )
        }
        
        return '';
    }

    const PlanningIcon = ()=> {
        if(entity.planState === 'plan'){
            return (
                <img src='/images/icons/calendar.svg' className="icon-status-plan" width={16} />
            )
        }
        else if(entity.planState === 'prepare'){
            return (
                <img src='/images/icons/activity.svg' className="icon-status-prepare" width={16} />
            )
        }
        else if(entity.planState === 'execute'){
            return (
                <img src='/images/icons/rocket.svg' className="icon-status-execute" width={16} />
            )
        }
        
        return '';
    }



    return (
        <div className="task-row-status">
            <StatusIcon />
        </div>
    );
};

export default TaskStatus;
