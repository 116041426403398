const apiUrl = process.env.REACT_APP_PLATFORM_URL;

export default class Http {
    static HEADERS = { 'Content-Type': 'application/json' };

    static async postMedia(url, data) {
        try {
            const mediaHeader = {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            };
            const config = {
                method: 'POST',
                headers: mediaHeader,
            };

            config.body = data;
            const response = await fetch(apiUrl + url, config);
            return await response.json();
        } catch (e) {
            console.log(e);
        }
    }
    static async getMedia(url) {
        try {
            const mediaHeader = {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            };
            const config = {
                method: 'GET',
                headers: mediaHeader,
            };

            const response = await fetch(apiUrl + url, config);
            return await response.blob();
        } catch (e) {
            console.log(e);
        }
    }
}