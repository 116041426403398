import { apiSlice } from './apiSlice';

export const callsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCallsList: builder.query({
            query: (data) =>
                'api/calls?' +
                `accountId=${data.accountId}` +
                `&start=${data.start}` +
                `&finish=${data.finish}` +
                `&processed=${data.processed ?? ''}` + 
                `&companyId=${data.companyId ?? ''}` +
                `&contactId=${data.contactId ?? ''}` +
                `&search=${data.search ?? ''}` +
                `&operatorId=${data.operatorId ?? ''}` +
                `&incoming=${data.incoming ?? ''}` +
                `&pageSize=${data.pageSize}&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) => [{ type: 'Calls', id: 'List' }],
        }),
        getRecord: builder.query({
            query: (data) => `api/calls/${data.id}/record`,
        }),
        linkContact: builder.mutation({
            query: (credentials) => ({
                url: `api/calls/${credentials.callId}/linkContact?contactId=${credentials.contactId}`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'Calls', id: 'List' }],
        }),
    }),
});

export const {
    useGetCallsListQuery,
    useLazyGetCallsListQuery,
    useLazyGetRecordQuery,
    useLinkContactMutation,
} = callsApiSlice;
