import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function useOutsideClickHandler(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (callback) {
                    callback();
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

function OutsideClickHandler(props) {
    const { callback, classes, id = null } = props;
    const wrapperRef = useRef(null);
    useOutsideClickHandler(wrapperRef, callback);

    return (
        <div
            ref={wrapperRef}
            className={classes}
            id={id}
            style={{ zIndex: 100 }}
        >
            {props.children}
        </div>
    );
}

OutsideClickHandler.propTypes = {
    children: PropTypes.element.isRequired,
};

export default OutsideClickHandler;
