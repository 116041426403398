import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuthorize, logOut } from './authSlice';
import { useDeleteSecurityMutation } from './authApiSlice';

const Logout = () => {
    const dispatch = useDispatch();

    const isAuthorize = useSelector(selectIsAuthorize);
    const [deleteSecurity] = useDeleteSecurityMutation();

    useEffect(() => {
        async function fetchData() {
            console.log('isAuthorize', isAuthorize);
            if (isAuthorize) {
                console.log('start logout');
                dispatch(logOut());
                await deleteSecurity(null).unwrap();
            }
            window.location.href = '/';
        }
        fetchData();
    }, [isAuthorize]);

    return <div>logout</div>;
};

export default Logout;
