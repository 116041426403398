import { useState } from 'react';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {
    useGetContactsListQuery,
    useLazyGetContactsListQuery,
} from '../../app/api/contactsApiSlice';
import { FormatDateToHuman2 } from '../../helpers/DateHelpers';
import { useEffect } from 'react';
import Paginator from '../../components/Paginator';
import FindComponent from '../../components/filters/FindComponent';
import Button from '../../components/Button';
import SortableTableHead from '../../components/SortableTableHead';
import SliceIcon from '../../components/SliceIcon';
import TagFilter from '../../components/filters/TagFilter';
import CompanyFilter from '../../components/filters/CompanyFilter';
import PhoneComponent from '../../components/calls/PhoneComponent';

const ContactsList = (props) => {
    const {
        preCompanyId = '',
        excludeCols = [],
        canCreate = true,
        fastFilters = true,
    } = props;
    let params = useParams();
    const navigate = useNavigate();

    const [getList, { data, isLoading }] = useLazyGetContactsListQuery();

    const accountId = params.accountId;
    const [searchParams, setSearchParams] = useSearchParams();
    const [showArchive, setShowArchive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState('name');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [tagIds, setTagIds] = useState([]);
    const [companyId, setCompanyId] = useState(preCompanyId);

    useEffect(() => {
        async function loadDataAsync() {
            if (Array.from(searchParams).length > 0) {
                await getList({
                    accountId,
                    search: searchParams.get('search') ?? '',
                    sort,
                    showArchive,
                    companyId: preCompanyId,
                });
                setSearchString(searchParams.get('search') ?? '');
            }
        }
        loadDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                showArchive,
                sort,
                companyId,
                tagIds,
                pageSize,
                pageIndex,
            });
        }
        loadDataAsync();
    }, [
        pageSize,
        pageIndex,
        searchString,
        sort,
        showArchive,
        tagIds,
        companyId,
    ]);

    const openEntityCard = (entityId) => {
        navigate(`/account/${accountId}/contacts/${entityId}`);
    };

    const findContacts = async () => {
        setSearchParams(`?search=${encodeURIComponent(searchString)}`);
        await getList({
            accountId,
            search: encodeURIComponent(searchString),
            sort,
            showArchive,
            pageSize,
            pageIndex,
        });
    };

    const handleKeyEnterDown = (event) => {
        if (event.key === 'Enter') {
            findContacts();
        }
    };
    const getPhone = (item) => {
        if (item.entries.length === 0) {
            return '';
        }
        let obj = item.entries.find(
            (o) => o.type === 'mobile' || o.type === 'work_phone'
        );
        if (obj) {
            return (
                <PhoneComponent
                    phone={obj.value}
                    accountId={accountId}
                    contactId={item.id}
                />
            );
        }
    };
    const getEmail = (item) => {
        if (item.entries.length === 0) {
            return '';
        }
        let obj = item.entries.find((o) => o.type === 'email');
        if (obj) {
            return obj.value;
        }
    };

    return (
        <>
            {fastFilters ? (
                <div
                    className='slices-container'
                    style={{ justifyContent: 'space-between' }}
                >
                    <div className='alignCenter'>
                        <div
                            className={
                                'slice ' + (!showArchive ? 'active-slice' : '')
                            }
                            onClick={() => setShowArchive(false)}
                        >
                            <SliceIcon value={!showArchive} filename='loader' />
                            Все
                        </div>
                        <div
                            className={
                                'slice ' + (showArchive ? 'active-slice' : '')
                            }
                            onClick={() => setShowArchive(true)}
                        >
                            <SliceIcon value={showArchive} filename='archive' />
                            Показать архивные
                        </div>
                    </div>
                    {false ? (
                        <div>
                            <Button
                                label='Создать'
                                btnColor='blue'
                                handleClick={() =>
                                    navigate(`/account/${accountId}/contacts/0`)
                                }
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                ''
            )}
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
                {preCompanyId ? null : (
                    <CompanyFilter selectCallback={(v) => setCompanyId(v)} />
                )}
                <TagFilter
                    selectCallback={(v) => setTagIds(v)}
                    type='contact'
                />
            </div>

            <div className='row'>
                <div className='col'>
                    <table className='table table-sm content-table'>
                        <thead>
                            <tr>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Имя'
                                    field='name'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                {excludeCols.find((x) => x === 'company') ===
                                undefined ? (
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Компания'
                                        field='company'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                ) : null}
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Должность'
                                    field='occupation'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Отдел'
                                    field='department'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <th scope='col'>Теги</th>
                                <th scope='col'>Телефон</th>
                                <th scope='col'>Email</th>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-3'
                                    label='Дата создания'
                                    field='date'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((item) => {
                                return (
                                    <tr
                                        className={
                                            'cursor-pointer' +
                                            (item.isArchive
                                                ? ' archive-row'
                                                : '')
                                        }
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                    >
                                        <td className='ps-3 left-cell text-medium'>
                                            {item.name}
                                        </td>
                                        {excludeCols.find(
                                            (x) => x === 'company'
                                        ) === undefined ? (
                                            <td className='cell text-normal'>
                                                {item.company.title}
                                            </td>
                                        ) : null}
                                        <td className='cell text-normal'>
                                            {item.occupation}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.department}
                                        </td>
                                        <td className='cell text-normal'>
                                            {item?.tags
                                                .map((t) => t.title)
                                                .join(', ')}
                                        </td>
                                        <td className='cell text-normal'>
                                            {getPhone(item)}
                                        </td>
                                        <td className='cell text-normal'>
                                            {getEmail(item)}
                                        </td>
                                        <td className='cell text-normal'>
                                            {FormatDateToHuman2(
                                                item.createdAt,
                                                false
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className='card-body'>
                        <Paginator
                            onPageChange={(e) => setPageIndex(e)}
                            onPageSizeChange={(e) => setPageSize(e)}
                            pageCount={data?.paginator?.totalPages ?? 0}
                            pageSizeArray={[50, 100, 500]}
                            pageSize={pageSize}
                            countCurrent={data?.result?.length ?? 0}
                            countTotal={data?.paginator?.totalCount ?? 0}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactsList;
