import { useEffect, useState } from 'react';

import FilterIcon from '../../components/FilterIcon';

const FilterObjectHeader = (props) => {
    const { value, icon, title, clickHandler } = props;
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    return (
        <>
            <div className='filters'>
                <div
                    className={
                        'filters-label cursor-pointer' +
                        (selectedValue === '' || selectedValue === undefined
                            ? ''
                            : ' filters-active')
                    }
                    onClick={() => clickHandler()}
                >
                    <FilterIcon
                        filename={icon}
                        width={12}
                        value={selectedValue}
                    />
                    <span>{title}</span>
                    {selectedValue ? ': ' + selectedValue?.name : ''}
                    <FilterIcon
                        filename='chevron-down'
                        width={12}
                        value={selectedValue}
                    />
                </div>
                {props.children}
            </div>
        </>
    );
};

export default FilterObjectHeader;
