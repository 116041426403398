import { apiSlice } from './apiSlice';

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: (data) =>
                'api/notifications?' + `accountId=${data.accountId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Notifications',
                              id,
                          })),
                          { type: 'Notifications', id: 'List' },
                      ]
                    : [{ type: 'Notifications', id: 'List' }],
        }),
        deleteNotification: builder.mutation({
            query: (data) => ({
                url: 'api/notifications/read?taskId=' + data.taskId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Notifications', id: 'List' }],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useDeleteNotificationMutation
} = notificationsApiSlice;
