import { Link, useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';

const MenuAdmin = (props) => {
    const { mode } = props;
    const location = useLocation();

    return (
        <>
            <MenuItem
                to={`/administrator/overview`}
                icon={
                    <img
                        src='/images/icons/home.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Обзор'
            />
            <MenuItem
                to={`/administrator/accounts`}
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Аккаунты'
            />
        </>
    );
};

export default MenuAdmin;
