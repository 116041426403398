import { apiSlice } from './apiSlice';

export const companiesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getList: builder.query({
            query: (data) =>
                `api/companies?` +
                `accountId=${data.accountId}` +
                `&search=${data.search ?? ''}` +
                `&holdingId=${data.holdingId ?? ''}` +
                (data?.tagIds
                    ? data?.tagIds.map((x) => `&tagIds=${x.id}`).join('')
                    : '') +
                `&inn=${data.inn ?? ''}` +
                `&showArchive=${data.showArchive ?? false}` +
                `&sort=${data.sort}` +
                `&pageSize=${data.pageSize ?? 100}` +
                `&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Companies',
                              id,
                          })),
                          { type: 'Companies', id: 'List' },
                      ]
                    : [{ type: 'Companies', id: 'List' }],
        }),
        getById: builder.query({
            query: (entityId) => `api/companies/${entityId}`,
            providesTags: (result) => [
                { type: 'Company', id: result.result.id },
            ],
        }),
        create: builder.mutation({
            query: (credentials) => ({
                url: 'api/companies',
                method: 'POST',
                body: { ...credentials },
            }),
            invalidatesTags: [{ type: 'Companies', id: 'List' }],
        }),
        update: builder.mutation({
            query: (credentials) => ({
                url: 'api/companies/' + credentials.id,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Companies', id: 'List' },
                { type: 'Company', id: result.result.id },
            ],
        }),
        patch: builder.mutation({
            query: ({ id, items }) => ({
                url: 'api/companies/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Companies', id: 'List' },
                { type: 'Company', id: result.result.id },
            ],
        }),
        delete: builder.mutation({
            query: (entityId) => ({
                url: 'api/companies/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Companies', id: 'List' }],
        }),
        linkTag: builder.mutation({
            query: (credentials) => ({
                url: `api/companies/${credentials.companyId}/tags/${credentials.tagId}`,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Companies', id: 'List' },
                { type: 'Company', id: result.result.id },
            ],
        }),
        deleteLinkTag: builder.mutation({
            query: (credentials) => ({
                url: `api/companies/${credentials.companyId}/tags/${credentials.tagId}`,
                method: 'delete',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Companies', id: 'List' },
                { type: 'Company', id: arg.companyId },
            ],
        }),
        createCompanyTag: builder.mutation({
            query: (credentials) => ({
                url: `api/companies/${credentials.companyId}/tags/${credentials.tagId}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.companyId },
            ],
        }),
        deleteCompanyTag: builder.mutation({
            query: (data) => ({
                url: `api/companies/${data.companyId}/tags/${data.tagId}`,
                method: 'delete',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.companyId },
            ],
        }),
    }),
});

export const {
    useGetListQuery,
    useLazyGetListQuery,
    useGetByIdQuery,
    useLazyGetByIdQuery,
    useCreateMutation,
    useUpdateMutation,
    usePatchMutation,
    useDeleteMutation,
    useLinkTagMutation,
    useDeleteLinkTagMutation,
    useCreateCompanyTagMutation,
    useDeleteCompanyTagMutation,
} = companiesApiSlice;
