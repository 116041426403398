import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetTasksListQuery } from '../../app/api/tasksApiSlice';
import { useEffect } from 'react';
import FindComponent from '../../components/filters/FindComponent';
import CompanyFilter from '../../components/filters/CompanyFilter';
import TaskStatusFilter from '../../components/filters/TaskStatusFilter';
import TaskAuthorFilter from '../../components/filters/TaskAuthorFilter';
import MembersFilter from '../../components/filters/MembersFilter';
import SliceIcon from '../../components/SliceIcon';
import { FormatDateToHuman2 } from '../../helpers/DateHelpers';
import { num_word } from '../../helpers/NumWords';
import Button from '../../components/Button';
import TaskStatus from './TaskStatus';
import Paginator from '../../components/Paginator';
import SortableTableHead from '../../components/SortableTableHead';

const TasksList = (props) => {
    const {
        fastFilters = true,
        preCompanyId = '',
        preContactId = '',
        excludeCols = [],
        page = 'tasks',
    } = props;
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;
    const contactId = params.contactId;
    const paramCompanyId = params.companyId;
    const [searchParams, setSearchParams] = useSearchParams();
    const [getList, { data, isLoading }] = useLazyGetTasksListQuery();
    const [sort, setSort] = useState('reaction_date_desc');

    const [searchString, setSearchString] = useState('');
    const [authorId, setAuthorId] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [status, setStatus] = useState('');
    const [members, setMembers] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [slice, setSlice] = useState(searchParams.get('show') ?? 'today');

    const openEntityCard = (entityId) => {
        let url = `/account/${accountId}/tasks/${entityId}`;
        switch (page) {
            case 'tasks':
                url = `/account/${accountId}/tasks/${entityId}`;
                break;
            case 'contact':
                url = `/account/${accountId}/contacts/${contactId}/tasks/${entityId}`;
                break;
            case 'company':
                url = `/account/${accountId}/companies/${paramCompanyId}/tasks/${entityId}`;
                break;

            default:
                break;
        }
        navigate(url);
    };

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                companyId: preCompanyId !== '' ? preCompanyId : companyId,
                contactId: preContactId,
                slice,
                status,
                authorId,
                members,
                pageSize,
                pageIndex,
                sort,
            });
        }
        loadDataAsync();
    }, [
        accountId,
        searchString,
        slice,
        status,
        companyId,
        authorId,
        members,
        pageIndex,
        pageSize,
        sort,
    ]);

    return (
        <>
            {fastFilters ? (
                <div
                    className='slices-container'
                    style={{ justifyContent: 'space-between' }}
                >
                    <div className='alignCenter'>
                        <div
                            className={
                                'slice ' +
                                (slice === 'expired' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('expired')}
                        >
                            <SliceIcon
                                value={slice === 'expired'}
                                filename='alert-octagon'
                            />
                            Просроченные
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'yesterday' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('yesterday')}
                        >
                            <SliceIcon
                                value={slice === 'yesterday'}
                                filename='alert-triangle'
                            />
                            Вчера
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'today' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('today')}
                        >
                            <SliceIcon
                                value={slice === 'today'}
                                filename='bell'
                            />
                            Сегодня
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'tomorrow' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('tomorrow')}
                        >
                            <SliceIcon
                                value={slice === 'tomorrow'}
                                filename='clock'
                            />
                            Завтра
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'mine' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('mine')}
                        >
                            <SliceIcon
                                value={slice === 'mine'}
                                filename='user-my-tasks'
                            />
                            Мои задачи
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'watching' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('watching')}
                        >
                            <SliceIcon
                                value={slice === 'watching'}
                                filename='eye'
                            />
                            На контроле
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'postponed' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('postponed')}
                        >
                            <SliceIcon
                                value={slice === 'postponed'}
                                filename='postponed'
                            />
                            Отложенные
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'complete' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('complete')}
                        >
                            <SliceIcon
                                value={slice === 'complete'}
                                filename='check'
                            />
                            Выполненные
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'future' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('future')}
                        >
                            <SliceIcon
                                value={slice === 'future'}
                                filename='fast-forward'
                            />
                            На будущее
                        </div>
                        <div
                            className={
                                'slice ' +
                                (slice === 'none' ? 'active-slice' : '')
                            }
                            onClick={() => setSlice('none')}
                        >
                            <SliceIcon
                                value={slice === 'none'}
                                filename='loader'
                            />
                            Все
                        </div>
                    </div>
                    <div>
                        <Button
                            label='Добавить задачу'
                            btnColor='blue'
                            handleClick={() =>
                                navigate(`/account/${accountId}/tasks/0`)
                            }
                        />
                    </div>
                </div>
            ) : null}
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
                <TaskStatusFilter valueChanged={(v) => setStatus(v)} />
                {!preContactId || !preCompanyId ? (
                    <CompanyFilter selectCallback={(v) => setCompanyId(v)} />
                ) : null}
                <MembersFilter selectCallback={(v) => setMembers(v)} />
                <TaskAuthorFilter selectCallback={(v) => setAuthorId(v)} />
            </div>
            <div className='row'>
                <div className='col'>
                    <table className='table table-sm content-table'>
                        <thead>
                            <tr>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-2 width-90'
                                    label='Статус'
                                    field='status'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-2 width-90'
                                    label='№'
                                    field='id'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <th scope='col' className='ps-2'>
                                    Тема
                                </th>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-2 width-150'
                                    label='Дата создания'
                                    field='createdat'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-2 width-150'
                                    label='Дата реакции'
                                    field='reaction_date'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />

                                {excludeCols.find((x) => x === 'company') ===
                                undefined ? (
                                    <th scope='col'>Компания</th>
                                ) : null}
                                <th scope='col'>Исполнители</th>
                                <th scope='col' style={{ width: 200 }}>
                                    Автор
                                </th>
                                <SortableTableHead
                                    scope='col'
                                    classes='ps-2 width-100'
                                    label='Возраст'
                                    field='createdat'
                                    value={sort}
                                    handleClick={(value) => setSort(value)}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((item) => {
                                return (
                                    <tr
                                        className='cursor-pointer'
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                    >
                                        <td className='left-cell text-medium'>
                                            <TaskStatus entity={item} />
                                        </td>
                                        <td className='cell text-normal'>
                                            {item.id}
                                        </td>
                                        <td className='cell text-medium'>
                                            {item.title}
                                        </td>
                                        <td className='cell text-medium'>
                                            {FormatDateToHuman2(
                                                item.createdAt,
                                                false
                                            )}
                                        </td>
                                        <td className='cell text-medium'>
                                            {FormatDateToHuman2(
                                                item.reactionDate,
                                                false
                                            )}
                                        </td>
                                        {excludeCols.find(
                                            (x) => x === 'company'
                                        ) === undefined ? (
                                            <td className='cell text-medium'>
                                                {item.company?.title}
                                            </td>
                                        ) : null}
                                        <td className='cell text-medium'>
                                            {item.members
                                                ?.slice(0, 2)
                                                .map((x) => x.title)
                                                .join(', ')}
                                            {item.members?.length > 2 ? (
                                                <span className='ms-1 fw-bold'>
                                                    +{item.members.length - 2}
                                                </span>
                                            ) : null}
                                        </td>
                                        <td className='cell text-medium'>
                                            {item.author?.title}
                                        </td>
                                        <td className='right-cell text-medium'>
                                            {item?.age +
                                                ' ' +
                                                num_word(item?.age, [
                                                    'день',
                                                    'дня',
                                                    'дней',
                                                ])}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='card-body'>
                <Paginator
                    onPageChange={(e) => setPageIndex(e)}
                    onPageSizeChange={(e) => setPageSize(e)}
                    pageCount={data?.paginator?.totalPages ?? 0}
                    pageSizeArray={[50, 100, 500]}
                    pageSize={pageSize}
                    countCurrent={data?.result?.length ?? 0}
                    countTotal={data?.paginator?.totalCount ?? 0}
                />
            </div>
        </>
    );
};

export default TasksList;
