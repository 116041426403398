import { apiSlice } from './apiSlice';

export const contactsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getContactsList: builder.query({
            query: (data) =>
                `api/contacts?` +
                `accountId=${data.accountId}` +
                `&search=${data.search ?? ''}` +
                `&companyId=${data.companyId ?? ''}` +
                (data?.tagIds
                    ? data?.tagIds.map((x) => `&tagIds=${x.id}`).join('')
                    : '') +
                `&showArchive=${data.showArchive ?? false}` +
                `&sort=${data.sort}` +
                `&pageSize=${data.pageSize ?? 100}` +
                `&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Contacts',
                              id,
                          })),
                          { type: 'Contacts', id: 'List' },
                      ]
                    : [{ type: 'Contacts', id: 'List' }],
        }),
        getContactsByCompanyList: builder.query({
            query: (credentials) =>
                `api/contacts?companyId=${credentials.companyId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Contacts',
                              id,
                          })),
                          { type: 'Contacts', id: 'List' },
                      ]
                    : [{ type: 'Contacts', id: 'List' }],
        }),
        getContactById: builder.query({
            query: (entityId) => `api/contacts/${entityId}`,
            providesTags: (result) => [
                { type: 'Contact', id: result.result.id },
            ],
        }),
        createContact: builder.mutation({
            query: (credentials) => ({
                url: 'api/contacts',
                method: 'POST',
                body: { ...credentials },
            }),
            invalidatesTags: [{ type: 'Contacts', id: 'List' }],
        }),
        updateContact: builder.mutation({
            query: (credentials) => ({
                url: 'api/contacts/' + credentials.id,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contacts', id: 'List' },
                { type: 'Contact', id: result.result.id },
            ],
        }),
        patchContact: builder.mutation({
            query: ({ id, items }) => ({
                url: 'api/contacts/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contacts', id: 'List' },
                { type: 'Contact', id: result.result.id },
            ],
        }),
        deleteContact: builder.mutation({
            query: (entityId) => ({
                url: 'api/contacts/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Contacts', id: 'List' }],
        }),
        createEntry: builder.mutation({
            query: (credentials) => ({
                url: `api/contacts/${credentials.contactId}/entries`,
                method: 'POST',
                body: { ...credentials.entry },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contacts', id: 'List' },
                { type: 'Contact', id: arg.contactId },
            ],
        }),
        updateEntry: builder.mutation({
            query: (data) => ({
                url: `api/contacts/entries/${data.id}`,
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contacts', id: 'List' },
                { type: 'Contact', id: arg.contactId },
            ],
        }),
        deleteEntry: builder.mutation({
            query: (entityId) => ({
                url: 'api/contacts/entries/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contact', id: result.result.id },
            ],
        }),
        createContactTag: builder.mutation({
            query: (credentials) => ({
                url: `api/contacts/${credentials.contactId}/tags/${credentials.tagId}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contact', id: arg.contactId },
            ],
        }),
        deleteContactTag: builder.mutation({
            query: (data) => ({
                url: `api/contacts/${data.contactId}/tags/${data.tagId}`,
                method: 'delete',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Contact', id: arg.contactId },
            ],
        }),
    }),
});

export const {
    useGetContactsListQuery,
    useLazyGetContactsListQuery,
    useGetContactByIdQuery,
    useGetContactsByCompanyListQuery,
    useLazyGetContactByIdQuery,
    useCreateContactMutation,
    useUpdateContactMutation,
    usePatchContactMutation,
    useDeleteContactMutation,
    useCreateEntryMutation,
    useUpdateEntryMutation,
    useDeleteEntryMutation,
    useCreateContactTagMutation,
    useDeleteContactTagMutation,
} = contactsApiSlice;
