import { useEffect, useState } from "react";
import {  useParams } from 'react-router-dom';

import { useLazyGetDepartmentsListQuery } from '../../app/api/departmentsApiSlice';
import SimpleDropdown from "./SimpleDropdown";
import OutsideClickHandler from '../../components/OutsideClickHandler';

const RoleDropdown = (props) => {
    const {
        value, 
        valueChanged, 
        disabled=false
    } = props;

    let values = [
        {'value': 'user', 'title': 'Пользователь'},
        {'value': 'admin', 'title': 'Администратор'}
    ]

    const [isEdit, setIsEdit] = useState(false);
    const selectedValue = values.find(
        (x) => x.value === (value ?? 'user')
    );

    const setEnable = () => {
        if(!disabled)
        {
            setIsEdit(true);
        }
    }
    const selectValue = (value) => {
        console.log(value);
        valueChanged(value);
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={'dropdown width-350 height-100'}
                >
                    <>
                        {values?.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-check cursor-pointer'
                                    onClick={() => selectValue(item.value)}
                                >
                                    <span>{item.title}</span>
                                    {value === item.value ? (
                                        <img
                                            src='/images/icons/check.svg'
                                            width={20}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </>
                </OutsideClickHandler>
            ) : (
                <div
                    className={'dropdown ' +value ? 'dropdown-label' : 'dropdown-empty'}
                    onClick={setEnable}
                >
                    <span>{selectedValue?.title ?? 'Пусто'}</span>
                </div>
            )}
        </>
    );
};

export default RoleDropdown;