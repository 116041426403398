import { apiSlice } from './apiSlice';

export const departmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDepartmentsList: builder.query({
            query: (data) =>
                'api/departments?' +
                `accountId=${data.accountId}` + `&showArchive=${data.showArchive}` +
                `&search=${data.search}&sort=${data.sort}` +
                `&pageSize=${data.pageSize}&pageIndex=${data.pageIndex ?? 1}`,
        providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Departments',
                              id,
                          })),
                          { type: 'Departments', id: 'List' },
                      ]
                    : [{ type: 'Departments', id: 'List' }],
        }),
        getDepartmentById: builder.query({
            query: (entityId) => `api/departments/${entityId}`,
            providesTags: (result) => [
                { type: 'Department', id: result.result.id },
            ],
        }),
        createDepartment: builder.mutation({
            query: (data) => ({
                url: 'api/departments',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: (result) => [
                { type: 'Departments', id: 'List' },
                { type: 'Department', id: result.result.id }],
        }),
        updateDepartment: builder.mutation({
            query: (data) => ({
                url: 'api/departments/' + data.id,
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Departments', id: 'List' },
                { type: 'Department', id: result.result.id },
            ],
        }),
        patchDepartment: builder.mutation({
            
            query: ({id, items}) => ({
                url: 'api/departments/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Departments', id: 'List' },
                { type: 'Department', id: result.result.id },
            ],
        }),
        deleteDepartment: builder.mutation({
            query: (entityId) => ({
                url: 'api/departments/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Departments', id: 'List' }],
        }),
    }),
});

export const {
    useGetDepartmentsListQuery,
    useLazyGetDepartmentsListQuery,
    useGetDepartmentByIdQuery,
    useLazyGetDepartmentByIdQuery,
    useCreateDepartmentMutation,
    useUpdateDepartmentMutation,
    usePatchDepartmentMutation,
    useDeleteDepartmentMutation,
} = departmentsApiSlice;
