import { useEffect, useRef, useState } from 'react';
import { useLazyGetNotificationsQuery } from '../../app/api/notificationsApiSlice';
import NotificationRow from './NotificationRow';
import { useLocation } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../features/auth/authSlice';
import { ActionMenu } from './ActionMenu';

const NotificationsPanel = (props) => {
    const { accountId } = props;
    const location = useLocation();
    const security = useSelector(selectSecurity);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [countNotifications, setCountNotifications] = useState(0);
    const [securityContext, setSecurityContext] = useState(null);

    const [connection, setConnection] = useState(null);
    const countItems = useRef(0);
    countItems.current = countNotifications;
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/notifications`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);
    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log('Connected!');
                    connection
                        .invoke('Join', `notifications_a${accountId}`) //JoinGroup is C# method name
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on('ReceiveMessage', (message) => {
                        console.log('userId=' + security.user.id);
                        console.log(message);
                        if (
                            message.accountId == accountId &&
                            message.userId == security.user.id
                        ) {
                            setCountNotifications(message.count);
                            console.log('set count =' + message.count);
                            getNotitifactions({ accountId });
                        }
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const [getNotitifactions, { data }] = useLazyGetNotificationsQuery();

    const openModal = () => {
        setIsOpenModal(!isOpenModal);
        getNotitifactions({ accountId });
    };

    const RowHandler = (props) => {
        const { entity } = props;
        let companyUrl = entity.company
            ? `/account/${accountId}/companies/${entity?.company?.id}`
            : '';
        let taskUrl = location.pathname.endsWith('/overview')
            ? `/account/${accountId}/overview/tasks/${entity.taskId}`
            : `/account/${accountId}/tasks/${entity.taskId}`;
        return (
            <NotificationRow
                entity={entity}
                taskUrl={taskUrl}
                companyUrl={companyUrl}
                closeHandle={() => setIsOpenModal(false)}
                reloadCallback={() => {
                    setTimeout(function () {
                        getNotitifactions({ accountId });
                    }, 1000);
                }}
            />
        );
    };

    useEffect(() => {
        if (location.pathname.endsWith('/overview')) {
            setIsOpenModal(true);
        } else {
            setIsOpenModal(false);
        }
    }, [location]);
    useEffect(() => {
        setCountNotifications(data?.result?.length);
    }, [data]);

    useEffect(() => {
        async function loadDataAsync() {
            await getNotitifactions({
                accountId,
            });
        }
        loadDataAsync();
    }, [accountId]);

    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    return (
        <>
            <div>
                <div
                    className={
                        'notifications notifications-badge cursor-pointer d-inline-block me-3' +
                        (countNotifications > 0 ? ' notifications-green' : '')
                    }
                    onClick={openModal}
                >
                    <img
                        src='/images/icons/bell.svg'
                        width={12}
                        className={
                            countNotifications > 0
                                ? 'icon-success-500'
                                : 'icon-gray'
                        }
                    />
                    <span
                        className={
                            'notifications-badge-text' +
                            (countNotifications > 0
                                ? ' notifications-green'
                                : '')
                        }
                    >
                        {countNotifications}
                    </span>
                </div>
                {security?.isRoot || securityContext?.role === 'admin' ? (
                    <ActionMenu />
                ) : null}
            </div>

            <div
                className={
                    'container position-absolute vh-100 notifications-container' +
                    (isOpenModal === true ? '' : ' d-none')
                }
            >
                <div className='notifications-header'>
                    <div className='caption'>
                        <img
                            src='/images/icons/bell.svg'
                            width={16}
                            className='icon-main'
                        />
                        <span>Уведомления</span>
                    </div>
                    <div
                        className='close-button cursor-pointer'
                        onClick={() => setIsOpenModal(false)}
                    >
                        Закрыть
                    </div>
                </div>

                <div className='notifications-body'>
                    {data?.result?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={
                                    'notifications-row' +
                                    (item.type === 'task_add_message'
                                        ? ' notifications-row-green'
                                        : '')
                                }
                            >
                                <RowHandler entity={item} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default NotificationsPanel;
