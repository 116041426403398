import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import SearchableList from '../filters/SearchableList';

import { useLazyGetContactsListQuery } from '../../app/api/contactsApiSlice';

const ContactDropdownSelector = (props) => {
    const { value, valueId, selectCallback, companyId } = props;
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [getList, { data }] = useLazyGetContactsListQuery();

    const selectValue = (value) => {
        setIsEdit(false);
        selectCallback(value?.id);
    };

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            companyId: companyId ?? '',
            showArchive: false,
            pageSize: 50,
        });
    };

    const openContact = () => {
        navigate(`/account/${accountId}/contacts/${valueId}`);
    };

    return (
        <>
            <div
                className='company-edit position-relative d-inline-block'
                onClick={() => setIsEdit(true)}
            >
                {value ? (
                    <div className='company-name hover-show-parent'>
                        {value}
                        <img
                            src='/images/icons/corner-down-right.svg'
                            width='18px'
                            className='ms-1 icon-medium-dark cursor-pointer hover-show'
                            onClick={openContact}
                        />
                    </div>
                ) : (
                    <span className=''>Пусто</span>
                )}
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-300 height-400 filters-dropdown d-inline-block top-26'
                        }
                    >
                        <>
                            <SearchableList
                                value={value}
                                list={data?.result}
                                searchCallback={(search) =>
                                    loadDataAsync(search)
                                }
                                selectCallback={(value) => selectValue(value)}
                            />
                        </>
                    </OutsideClickHandler>
                ) : null}
            </div>
        </>
    );
};

export default ContactDropdownSelector;
