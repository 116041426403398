import ReactApexChart from 'react-apexcharts';
import ru from 'apexcharts/dist/locales/ru.json';

const ReportChart = (props) => {
    const { categories, series, colors } = props;

    console.log('categories', categories);

    const options = {
        chart: {
            locales: [ru],
            defaultLocale: 'ru',
            type: 'bar',
            height: 350,
            stacked: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            categories,
        },
        fill: {
            opacity: 1,
        },
        colors,
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        tooltip: { shared: true, intersect: false },
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type='bar'
            height={350}
        />
    );
};

export default ReportChart;
