import PageTitle from '../../../components/layout/PageTitle';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    useCreateTaskMutation,
    useDeleteTaskMutation,
    useLazyGetTaskByIdQuery,
    usePatchTaskMutation,
} from '../../../app/api/tasksApiSlice';
import moment from 'moment';
import 'moment/locale/ru';
import TaskStatusDropdown from '../../../components/editors/TaskStatusDropdown';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import MembersEditor from '../../../components/editors/MembersEditor';
import CompanyDropdownSelector from '../../../components/dropdowns/CompanyDropdownSelector';
import { useEffect, useState } from 'react';
import ContactDropdownSelector from '../../../components/dropdowns/ContactDropdownSelector';
import TextArea from '../../../components/editors/TextArea';
import TaskMessagesPanel from './TaskMessagesPanel';
import DateInput from '../../../components/editors/DateInput';
import { useLazyGetContactByIdQuery } from '../../../app/api/contactsApiSlice';
import { useLazyGetByIdQuery } from '../../../app/api/companiesApiSlice';
import { DeleteActionMenu } from '../../../components/DeleteActionMenu';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../../features/auth/authSlice';

const TaskModalPage = (props) => {
    const { page = 'tasks' } = props;
    let params = useParams();
    const navigate = useNavigate();

    const {
        accountId,
        taskId: entityId,
        contactId: parentContactId,
        companyId: parentCompanyId,
    } = params;
    const [searchParams] = useSearchParams();
    const contactId = searchParams.get('contactId');
    const companyId = searchParams.get('companyId');

    const security = useSelector(selectSecurity);

    const [getData, { data }] = useLazyGetTaskByIdQuery();
    const [getContact, { data: contact }] = useLazyGetContactByIdQuery();
    const [getCompany, { data: companyData }] = useLazyGetByIdQuery();
    const [createTask] = useCreateTaskMutation();
    const [deleteTask] = useDeleteTaskMutation();

    const [patchTask, { r }] = usePatchTaskMutation();
    const [isCollapse, setIsCollapse] = useState(true);
    const [company, setCompany] = useState(0);

    const [securityContext, setSecurityContext] = useState(null);

    useEffect(() => {
        async function fetchContact() {
            const result = await getContact(contactId);
            setCompany(result.data.result.company);
        }
        async function fetchCompany() {
            const result = await getCompany(companyId);
            setCompany({
                id: result.data.result.id,
                title: result.data.result.name,
            });
        }
        if (entityId > 0) {
            getData(entityId);
        }
        if (contactId > 0) {
            fetchContact();
        }
        if (companyId > 0) {
            fetchCompany();
        }
    }, [entityId, contactId, companyId]);

    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    const changePatch = (obj) => {
        patchTask({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    };

    const outsideClick = () => {
        let url = `/account/${accountId}/tasks`;
        switch (page) {
            case 'tasks':
                url = `/account/${accountId}/tasks`;
                break;
            case 'contact':
                url = `/account/${accountId}/contacts/${parentContactId}`;
                break;
            case 'company':
                url = `/account/${accountId}/companies/${parentCompanyId}`;
                break;
            case 'overview':
                url = `/account/${accountId}/overview`;
                break;

            default:
                break;
        }
        navigate(url);
    };

    const changeCompany = (value) => {
        changePatch({
            path: 'companyId',
            value,
        });
        changePatch({
            path: 'contactId',
            value: null,
        });
    };

    const changeContact = (value) => {
        changePatch({
            path: 'contactId',
            value,
        });
    };

    const saveTitle = async (title) => {
        if (entityId === '0') {
            let cnt = null;
            let cmp = null;
            if (contactId > 0) {
                cnt = { id: contactId };
                cmp = { id: company.id };
            }
            if (companyId > 0) {
                cmp = { id: company.id };
            }
            const entity = {
                account: { id: accountId },
                title,
                reactionDate: new Date(),
                text: '',
                contact: cnt,
                company: cmp,
            };
            let r = await createTask(entity);
            if (r?.data?.result?.id > 0) {
                let url = `/account/${accountId}/tasks/${r?.data?.result?.id}`;
                switch (page) {
                    case 'tasks':
                        url = `/account/${accountId}/tasks/${r?.data?.result?.id}`;
                        break;
                    case 'contact':
                        url = `/account/${accountId}/contacts/${contactId}/tasks/${r?.data?.result?.id}`;
                        break;
                    case 'company':
                        url = `/account/${accountId}/companies/${companyId}/tasks/${r?.data?.result?.id}`;
                        break;

                    default:
                        break;
                }
                navigate(url);
            }
        } else {
            changePatch({ path: 'title', value: title });
        }
    };

    console.log('task', data);

    return (
        <div className='container position-absolute task-container'>
            <div className='row'>
                <div className='col-xxl-9 offset-xxl-3  pe-0'>
                    <OutsideClickHandler
                        classes='task-body vh-100'
                        callback={outsideClick}
                        id='taskBody'
                    >
                        <>
                            <div className='top-block d-flex justify-content-between'>
                                <div>
                                    <span className='me-3'>
                                        # {data?.result.id}
                                    </span>
                                    <span className='me-3'>
                                        Создана:{' '}
                                        {moment(
                                            new Date(data?.result.createdAt)
                                        )
                                            .locale('ru')
                                            .add(3, 'h')
                                            .format('DD MMMM YYYY')}
                                    </span>
                                    <span>
                                        Автор: {data?.result.author?.title}
                                    </span>
                                </div>
                                <div>
                                    {security?.isRoot ||
                                    securityContext?.role === 'admin' ||
                                    securityContext?.employeeId ===
                                        data?.result?.author?.id ? (
                                        <DeleteActionMenu
                                            id={entityId}
                                            action={deleteTask}
                                            deleteTitle='Удалить задачу'
                                            deleteText={
                                                <>
                                                    Вы действительно хотите
                                                    удалить задачу{' '}
                                                    <b>{data?.result.title}</b>?
                                                </>
                                            }
                                            callback={outsideClick}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className='task-content'>
                                <PageTitle
                                    title={data?.result.title}
                                    placeholder='Новая задача'
                                    isNew={entityId === '0'}
                                    valueChanged={(value) => saveTitle(value)}
                                    maxLength={50}
                                />
                                <div className='task-attr'>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/award.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    Статус
                                                </div>
                                                <div className='col-md-8'>
                                                    <TaskStatusDropdown
                                                        value={
                                                            data?.result.status
                                                        }
                                                        valueChanged={(value) =>
                                                            changePatch({
                                                                path: 'status',
                                                                value: value.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/calendar.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    Дата реакции
                                                </div>
                                                <div className='col-md-4'>
                                                    <DateInput
                                                        placeholder='Пусто'
                                                        value={
                                                            data?.result
                                                                ?.reactionDate
                                                        }
                                                        classes='font-size-12'
                                                        valueChanged={(v) =>
                                                            changePatch({
                                                                path: 'reactionDate',
                                                                value: new Date(
                                                                    v
                                                                ),
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-md-4 reaction-by'>
                                                    {
                                                        data?.result
                                                            ?.reactionDateBy
                                                            ?.title
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/folder.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    Компания
                                                </div>
                                                <div className='col-md-8 attr-label'>
                                                    <CompanyDropdownSelector
                                                        value={
                                                            entityId === '0'
                                                                ? company.title
                                                                : data?.result
                                                                      .company
                                                                      ?.title
                                                        }
                                                        valueId={
                                                            entityId === '0'
                                                                ? company.id
                                                                : data?.result
                                                                      .company
                                                                      ?.id
                                                        }
                                                        selectCallback={(
                                                            value
                                                        ) =>
                                                            changeCompany(value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/user.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    Контакт
                                                </div>
                                                <div className='col-md-8 attr-label'>
                                                    {data?.result.company ||
                                                    company?.id ? (
                                                        <ContactDropdownSelector
                                                            value={
                                                                entityId === '0'
                                                                    ? contact
                                                                          ?.result
                                                                          ?.name
                                                                    : data
                                                                          ?.result
                                                                          .contact
                                                                          ?.title
                                                            }
                                                            valueId={
                                                                entityId === '0'
                                                                    ? contact
                                                                          ?.result
                                                                          ?.id
                                                                    : data
                                                                          ?.result
                                                                          .contact
                                                                          ?.id
                                                            }
                                                            companyId={
                                                                entityId === '0'
                                                                    ? company.id
                                                                    : data
                                                                          ?.result
                                                                          .company
                                                                          .id
                                                            }
                                                            selectCallback={(
                                                                value
                                                            ) =>
                                                                changeContact(
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/user-check.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    Исполнители
                                                </div>
                                                <div className='col-md-8 attr-label'>
                                                    <MembersEditor
                                                        selectCallback={() =>
                                                            true
                                                        }
                                                        type={'simple'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-4 attr-label'>
                                                    <img
                                                        src='/images/icons/eye.svg'
                                                        width='16px'
                                                        className='icon-gray me-2'
                                                    />
                                                    На контроле
                                                </div>
                                                <div className='col-md-8 attr-label'>
                                                    <MembersEditor
                                                        selectCallback={() =>
                                                            true
                                                        }
                                                        type={'watcher'}
                                                        view={'employees'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className='task-description'>
                                            <div
                                                className={
                                                    isCollapse
                                                        ? 'collapsed'
                                                        : ''
                                                }
                                            >
                                                <TextArea
                                                    value={data?.result.text}
                                                    valueChanged={(value) =>
                                                        changePatch({
                                                            path: 'text',
                                                            value,
                                                        })
                                                    }
                                                    classes='text-detail'
                                                    changedEditStatus={(
                                                        status
                                                    ) => {
                                                        if (status)
                                                            setIsCollapse(
                                                                false
                                                            );
                                                    }}
                                                />
                                            </div>
                                            {data?.result.text.split('\n')
                                                .length > 3 ? (
                                                <>
                                                    {isCollapse ? (
                                                        <div className='q grad'>
                                                            &nbsp;
                                                        </div>
                                                    ) : null}
                                                    <div
                                                        className='show-more'
                                                        onClick={() =>
                                                            setIsCollapse(
                                                                !isCollapse
                                                            )
                                                        }
                                                    >
                                                        {isCollapse
                                                            ? 'Развернуть'
                                                            : 'Свернуть'}
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TaskMessagesPanel taskId={entityId} />
                        </>
                    </OutsideClickHandler>
                </div>
            </div>
        </div>
    );
};

export default TaskModalPage;
