import PageTitle from '../../components/layout/PageTitle';
import CallsList from './CallsList'

const CallsPage = (props) => {
    return (
        <>
            <PageTitle
                title='Вызовы'
                icon={
                    <img
                        src='/images/icons/phone-call.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
             <CallsList />
        </>
    );
};

export default CallsPage;