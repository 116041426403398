import React from 'react';
import OutsideClickHandler from '../OutsideClickHandler';
import SearchableList from '../filters/SearchableList';
import {
    useCreateMutation,
    useLazyGetListQuery,
} from '../../app/api/companiesApiSlice';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import {
    useCreateContactMutation,
    useLazyGetContactsListQuery,
} from '../../app/api/contactsApiSlice';
import { useLinkContactMutation } from '../../app/api/callsApiSlice';
import { async } from 'q';

const LinkPhoneToContactModal = (props) => {
    const { isOpen, closeHandle, phone, callId, okHandle } = props;
    let params = useParams();

    const accountId = params.accountId;

    const [getList, { data }] = useLazyGetListQuery();
    const [getContacts, { data: contacts }] = useLazyGetContactsListQuery();
    const [createLink] = useLinkContactMutation();
    const [createCompany] = useCreateMutation();
    const [createContact] = useCreateContactMutation();

    const [isEdit, setIsEdit] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});

    const [isContactEdit, setIsContactEdit] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
    const [isNewCompany, setIsNewCompany] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [isNewContact, setIsNewContact] = useState(false);
    const [newContactName, setNewContactName] = useState('');

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50,
        });
    };

    const loadContactsDataAsync = async (search) => {
        await getContacts({
            accountId,
            search,
            sort: 'name',
            companyId: selectedCompany.id,
            showArchive: false,
            pageSize: 50,
        });
    };

    const selectValue = (value) => {
        setSelectedCompany(value);
        setSelectedContact({});
        setIsEdit(false);
        if (value !== undefined) {
            getContacts({
                accountId,
                sort: 'name',
                companyId: value.id,
                showArchive: false,
                pageSize: 50,
            });
        }
    };

    const selectContactValue = (value) => {
        setSelectedContact(value);
        setIsContactEdit(false);
    };

    const closeModal = () => {
        setSelectedCompany({});
        setSelectedContact({});
        closeHandle();
    };

    const link = () => {
        createLink({
            callId,
            contactId: selectedContact.id,
        });
        setSelectedCompany({});
        setSelectedContact({});
        okHandle({
            company: { id: selectedCompany.id, title: selectedCompany.name },
            contact: { id: selectedContact.id, title: selectedContact.name },
        });
    };

    const newCompany = async () => {
        const entity = {
            id: 0,
            account: { id: accountId },
            name: newCompanyName,
        };
        const createdCompany = await createCompany(entity);
        setSelectedCompany(createdCompany.data.result);
        setIsNewCompany(false);
    };

    const addNewContact = () => {
        if (Object.keys(selectedCompany ?? {}).length !== 0) {
            setIsNewContact(true);
        }
    };

    const newContact = async () => {
        const entity = {
            id: 0,
            account: { id: accountId },
            name: newContactName,
            company: { id: selectedCompany.id },
        };
        const createdContact = await createContact(entity);
        setSelectedContact(createdContact.data.result);
        setIsNewContact(false);
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Привязать номер</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeModal}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Привязать номер <b>{phone}</b> к контакту.
                            </p>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='mb-3 position-relative'>
                                        <label className='form-label'>
                                            1. Компания
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Выберите компанию'
                                            onClick={() => setIsEdit(true)}
                                            defaultValue={selectedCompany?.name}
                                            readOnly
                                        />
                                        <img
                                            src='/images/icons/file-plus.svg'
                                            className='icon-main input-add-icon'
                                            height={37}
                                            onClick={() =>
                                                setIsNewCompany(true)
                                            }
                                        />
                                        {isEdit ? (
                                            <OutsideClickHandler
                                                callback={() =>
                                                    setIsEdit(false)
                                                }
                                                classes={
                                                    'dropdown width-300 height-400 input-dropdown d-inline-block'
                                                }
                                            >
                                                <>
                                                    <SearchableList
                                                        list={data?.result}
                                                        searchCallback={(
                                                            search
                                                        ) =>
                                                            loadDataAsync(
                                                                search
                                                            )
                                                        }
                                                        selectCallback={(
                                                            value
                                                        ) => selectValue(value)}
                                                    />
                                                </>
                                            </OutsideClickHandler>
                                        ) : null}
                                    </div>
                                    {isNewCompany ? (
                                        <>
                                            <div className='mb-1 position-relative'>
                                                <label className='form-label'>
                                                    Новая компания
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Введите название'
                                                    value={newCompanyName}
                                                    onChange={(e) =>
                                                        setNewCompanyName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button
                                                            className='button button-blue'
                                                            type='button'
                                                            onClick={newCompany}
                                                        >
                                                            Создать компанию
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button
                                                            className='button button-white'
                                                            type='button'
                                                            onClick={() =>
                                                                setIsNewCompany(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div className='col-md-6'>
                                    <div className='mb-3 position-relative'>
                                        <label className='form-label'>
                                            2. Контакт
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Выберите контакт'
                                            defaultValue={selectedContact?.name}
                                            disabled={
                                                Object.keys(
                                                    selectedCompany ?? {}
                                                ).length === 0
                                            }
                                            onClick={() =>
                                                setIsContactEdit(true)
                                            }
                                            readOnly
                                        />
                                        <img
                                            src='/images/icons/user-plus.svg'
                                            className='icon-main input-add-icon'
                                            height={37}
                                            onClick={addNewContact}
                                        />
                                        {isContactEdit ? (
                                            <OutsideClickHandler
                                                callback={() =>
                                                    setIsContactEdit(false)
                                                }
                                                classes={
                                                    'dropdown width-300 height-400 input-dropdown d-inline-block'
                                                }
                                            >
                                                <>
                                                    <SearchableList
                                                        list={contacts?.result}
                                                        searchCallback={(
                                                            search
                                                        ) =>
                                                            loadContactsDataAsync(
                                                                search
                                                            )
                                                        }
                                                        selectCallback={(
                                                            value
                                                        ) =>
                                                            selectContactValue(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </>
                                            </OutsideClickHandler>
                                        ) : null}
                                    </div>
                                    {isNewContact ? (
                                        <>
                                            <div className='mb-1 position-relative'>
                                                <label className='form-label'>
                                                    Новый контакт
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Введите название'
                                                    value={newContactName}
                                                    onChange={(e) =>
                                                        setNewContactName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button
                                                            className='button button-blue'
                                                            type='button'
                                                            onClick={newContact}
                                                        >
                                                            Создать контакт
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='d-grid gap-2'>
                                                        <button
                                                            className='button button-white'
                                                            type='button'
                                                            onClick={() =>
                                                                setIsNewContact(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={closeModal}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary'
                                disabled={
                                    Object.keys(selectedContact ?? {})
                                        .length === 0
                                }
                                onClick={link}
                            >
                                Привязать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default LinkPhoneToContactModal;
