import React, {  useEffect, useState } from 'react';
import http from '../http';
import { useLazyGetRecordQuery } from '../app/api/callsApiSlice';


const RecordPlayer = (props) => {
    const {
        call
    } = props;

    const [isPlaying, setIsPlaying] = useState(false);
    const [getRecord, { data }] = useLazyGetRecordQuery();

    const DurationToTime = (duration) => {
        if(duration === 0){
            return '00:00';
        }
    
        let minutes = Math.floor(duration / 60);
    
        let seconds = duration % 60;
    
        return [
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0'),
        ].join(':');
    }


    useEffect(()=>{
        async function loadDataAsync() {
            await getRecord({
                id: call.id
            });
        }

        if(isPlaying && call!==undefined){
            loadDataAsync();
        }
    }, [isPlaying])
    const playRecord = async () => {
        if(!isPlaying)
        {
            console.log('start playing');
        }
        else{
            console.log('stop playing');
        }
        setIsPlaying(!isPlaying);
    }
    
    return (
        <>
            {call.answerTime && !call.locked ? (
                <img className='cursor-pointer' src={`/images/icons/${isPlaying ? 'pause' : 'play_active'}.svg`} width={16} onClick={()=>playRecord()} />
            ) : (
                <img src='/images/icons/play_inactive.svg' width={16} />
            )}
            {call?.duration>0 && call.answerTime ? (
                <span className='text-normal cursor-pointer' onClick={()=>playRecord()}>{DurationToTime(call.duration)}</span>    
            ) : (
                <span className='text-inactive'>00:00</span>
            )}
            {isPlaying && data ? (
                <audio autoPlay={true}>
                    <source src={`data:audio/mpeg;base64,${data.result}`} />
                </audio>
            ) : ''}
        </>
);
};

export default RecordPlayer;
