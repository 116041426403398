import {
    useCreateCompanyTagMutation,
    useDeleteCompanyTagMutation,
    useGetByIdQuery,
    useLazyGetByIdQuery,
    usePatchMutation,
} from '../../../app/api/companiesApiSlice';
import HoldingSelector from '../../../components/editors/HoldingSelector';
import SwitchInput from '../../../components/editors/SwitchInput';
import TagEditor from '../../../components/editors/TagEditor';
import TextInput from '../../../components/editors/TextInput';
import EmployeesDropdown from '../../../components/editors/EmployeesDropdown';

const CompanyInfoBlock = (props) => {
    const { companyId } = props;
    const [patchCompany, { r }] = usePatchMutation();
    const { data } = useGetByIdQuery(companyId);
    const [createTag] = useCreateCompanyTagMutation();
    const [deleteTag] = useDeleteCompanyTagMutation();

    function patch(obj) {
        patchCompany({
            id: companyId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    const addTag = (value) => {
        createTag({ companyId, tagId: value.id });
    };

    const removeTag = (value) => {
        deleteTag({ companyId, tagId: value });
    };

    console.log('companyData', data);

    return (
        <div className='entity-card'>
            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/user.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Ответственный менеджер</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <EmployeesDropdown
                        value={data?.result?.manager}
                        selectCallback={(v) => {
                            patch({ path: 'managerId', value: v });
                        }}
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Юридическое название</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.fullName}
                        valueChanged={(v) =>
                            patch({ path: 'fullName', value: v })
                        }
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/chef.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Руководитель</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.headName}
                        valueChanged={(v) =>
                            patch({ path: 'headName', value: v })
                        }
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Должость руководителя</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.headPosition}
                        valueChanged={(v) =>
                            patch({ path: 'headPosition', value: v })
                        }
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/holding.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Холдинг</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <HoldingSelector
                        value={data?.result?.holding}
                        valueChanged={(v) =>
                            patch({ path: 'holdingId', value: v })
                        }
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/tag.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Теги</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TagEditor
                        type='company'
                        list={data?.result?.tags}
                        selectCallback={(value) => addTag(value)}
                        deleteCallback={(value) => removeTag(value)}
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>ИНН</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.inn}
                        valueChanged={(v) => patch({ path: 'inn', value: v })}
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>КПП</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.kpp}
                        valueChanged={(v) => patch({ path: 'kpp', value: v })}
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/file-text.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>ОГРН</span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.ogrn}
                        valueChanged={(v) => patch({ path: 'ogrn', value: v })}
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/map.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Юридический адрес </span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.address}
                        valueChanged={(v) =>
                            patch({ path: 'address', value: v })
                        }
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/map-pin.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Фактический адрес </span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.addressFact}
                        valueChanged={(v) =>
                            patch({ path: 'addressFact', value: v })
                        }
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/message-circle.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Комментарий </span>
                </div>
                <div className='col-md-8 property property-value'>
                    <TextInput
                        placeholder='Пусто'
                        value={data?.result?.comment}
                        valueChanged={(v) =>
                            patch({ path: 'comment', value: v })
                        }
                    />
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-4 property property-title'>
                    <img
                        src='/images/icons/archive.svg'
                        width={16}
                        className='icon-gray'
                    />
                    <span>Архивный</span>
                </div>
                <div className='col-md-8 property property-value ps-4'>
                    <SwitchInput
                        value={data?.result?.isArchive}
                        valueChanged={(v) =>
                            patch({ path: 'isArchive', value: v })
                        }
                    />
                </div>
            </div>
        </div>
    );
};
export default CompanyInfoBlock;
