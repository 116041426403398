import { useEffect, useState } from 'react';
import OutsideClickHandler from '../../components/OutsideClickHandler';

const SimpleDropdown = (props) => {
    const [isEdit, setIsEdit] = useState(false);

    const {
        value,
        list,
        searchCallback,
        selectCallback,
        withSearch = true,
        disabled = false,
    } = props;

    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        if (searchString === '' || searchString.length > 1) {
            if (searchCallback) {
                searchCallback(searchString);
            }
        }
    }, [searchString]);
    const setEnable = () => {
        if (!disabled) {
            setIsEdit(true);
        }
    };

    const selectValue = (value) => {
        selectCallback(value);
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={
                        'dropdown width-350 height-300 d-block position-absolute'
                    }
                >
                    <>
                        {withSearch ? (
                            <div className='dropdown-input'>
                                <input
                                    value={searchString}
                                    onChange={(e) =>
                                        setSearchString(e.target.value)
                                    }
                                    placeholder={'Поиск'}
                                />
                            </div>
                        ) : null}
                        <div
                            key={-1}
                            className='dropdown-check cursor-pointer'
                            onClick={() => selectValue(null)}
                        >
                            Не выбрано
                        </div>
                        {list?.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-check cursor-pointer'
                                    onClick={() => selectValue(item.id)}
                                >
                                    <span>{item.name}</span>
                                    {value?.id === item.id ? (
                                        <img
                                            src='/images/icons/check.svg'
                                            width={20}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </>
                </OutsideClickHandler>
            ) : (
                <div
                    className={
                        'dropdown ' + value
                            ? 'dropdown-label'
                            : 'dropdown-empty'
                    }
                    onClick={setEnable}
                >
                    <span>{value?.title ?? 'Пусто'}</span>
                </div>
            )}
        </>
    );
};

export default SimpleDropdown;
