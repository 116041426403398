import OutsideClickHandler from '../OutsideClickHandler';

const DropDownWithCheck = (props) => {
    const { outsideClick, listArray, selectHandle, selectedValue } = props;

    return (
        <OutsideClickHandler
            callback={outsideClick}
            classes={'dropdown width-250 height-200 filters-dropdown'}
        >
            <>
                {listArray.map((item) => (
                    <div
                        className="dropdown-row cursor-pointer"
                        onClick={() => selectHandle(item)}
                        key={item.value}
                    >
                        <div
                            className={
                                'dropdown-flex-start h-28 width-180 radius-4 ' +
                                item.blockClass
                            }
                        >
                            <img
                                src={item.icon}
                                width={12}
                                className={item.iconClass}
                            />
                            <span>{item.text}</span>
                        </div>
                        {selectedValue === item.value ? (
                            <img src="/images/icons/check.svg" width={20} />
                        ) : (
                            ''
                        )}
                    </div>
                ))}
            </>
        </OutsideClickHandler>
    );
};

export default DropDownWithCheck;
