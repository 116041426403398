import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { selectSecurity } from '../../../features/auth/authSlice';
import { useEffect } from 'react';
import {
    useGetEmployeesListQuery,
    useLazyGetEmployeeByIdQuery,
    useLazyGetEmployeeRedirectsQuery,
    usePatchEmployeeRedirectMutation,
} from '../../../app/api/employeesApiSlice';
import SwitchInput from '../../editors/SwitchInput';
import FindComponent from '../../filters/FindComponent';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import {
    selectLines,
    setLines,
    updateStatus,
} from '../../../features/lines/linesSlice';
import { HubConnectionBuilder } from '@microsoft/signalr';

const SidebarHeader = (props) => {
    let params = useParams();
    const { accountId = 0 } = params;
    const security = useSelector(selectSecurity);
    const location = useLocation();

    const dispatch = useDispatch();
    const lines = useSelector(selectLines);

    const [search, setSearch] = useState('');

    const [patchRedirect, { r }] = usePatchEmployeeRedirectMutation();
    const [getEmployee, { data }] = useLazyGetEmployeeByIdQuery();
    const { data: employees } = useGetEmployeesListQuery({
        accountId,
        pageSize: 1000,
        search,
    });

    const [getEmployeeRedirect, { data: redirects }] =
        useLazyGetEmployeeRedirectsQuery();

    const [userShow, setUserShow] = useState(false);
    const [showRedirectPanel, setShowRedirectPanel] = useState(false);
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (security && accountId) {
                const empId = security.accounts.find(
                    (x) => x.id == accountId
                )?.employeeId;
                await getEmployee(empId);
                await getEmployeeRedirect(empId);
            }
        }
        fetchData();
    }, [security, accountId]);

    useEffect(() => {
        async function fetchData() {
            if (employees?.result) {
                dispatch(
                    setLines(
                        employees?.result
                            .filter((x) => x.lineNumber)
                            .map((item) => {
                                return {
                                    number: item.lineNumber,
                                    status: item.lineStatus,
                                };
                            })
                    )
                );
            }
        }
        fetchData();
    }, [employees]);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/hints`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log('Connected!');
                    connection
                        .invoke('Join', `hints_${accountId}`)
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on('ReceiveMessage', (message) => {
                        console.log(message);
                        dispatch(updateStatus(message));
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    function patch(obj) {
        patchRedirect({
            id: data.result.id,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    const changeLine = (line) => {
        setShowRedirectPanel(false);
        patch({
            path: 'mainRedirectNumber',
            value: line,
        });
    };

    const getDot = (status) => {
        let dot = 'gray-dot';
        switch (status) {
            case 'Idle':
                dot = 'green-dot';
                break;
            case 'InUse':
            case 'InUse&Ringing':
            case 'Ringing':
            case 'Busy':
                dot = 'orange-dot';
                break;
            case 'Unavailable':
                dot = 'gray-dot';
                break;

            default:
                dot = 'gray-dot';
                break;
        }

        return dot;
    };

    const getMainStatus = (value) => {
        let status = (
            <div className='header-status d-flex align-items-center'>
                <div className='indicator offline me-1'></div>
                Офлайн
            </div>
        );
        switch (value) {
            case 'Idle':
                status = (
                    <div className='header-status d-flex align-items-center'>
                        <div className='indicator online me-1'></div>
                        Онлайн
                    </div>
                );
                break;
            case 'InUse':
            case 'InUse&Ringing':
            case 'Ringing':
            case 'Busy':
                status = (
                    <div className='header-status d-flex align-items-center'>
                        <div className='indicator busy me-1'></div>
                        Занят
                    </div>
                );
                break;
            case 'Unavailable':
                status = (
                    <div className='header-status d-flex align-items-center'>
                        <div className='indicator offline me-1'></div>
                        Офлайн
                    </div>
                );
                break;

            default:
                status = (
                    <div className='header-status d-flex align-items-center'>
                        <div className='indicator offline me-1'></div>
                        Офлайн
                    </div>
                );
                break;
        }

        return status;
    };

    const panel = () => {
        let pnl = (
            <OutsideClickHandler callback={() => setUserShow(false)}>
                <>
                    <div className='header'>Центр управления</div>
                    {location.pathname.startsWith('/account/') &&
                    data?.result?.lineNumber.length > 0 ? (
                        <>
                            <div className='redirection d-flex justify-content-between'>
                                <span>Переадресация</span>
                                <SwitchInput
                                    value={
                                        redirects?.result?.mainRedirectActive
                                    }
                                    valueChanged={(value) =>
                                        patch({
                                            path: 'mainRedirectActive',
                                            value,
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='redirectObject d-flex justify-content-between cursor-pointer'
                                onClick={() => setShowRedirectPanel(true)}
                            >
                                {redirects?.result?.mainRedirectNumber ? (
                                    <>
                                        <div>
                                            {data?.result?.user?.title.replace(
                                                /\D/g,
                                                ''
                                            ) ===
                                            redirects?.result?.mainRedirectNumber?.replace(
                                                /\D/g,
                                                ''
                                            ) ? (
                                                <>
                                                    <img
                                                        src='/images/icons/smartphone.svg'
                                                        height={18}
                                                        className='icon-gray align-top'
                                                    />
                                                    Мой мобильный
                                                </>
                                            ) : (
                                                employees?.result.find(
                                                    (x) =>
                                                        x.lineNumber ===
                                                        redirects?.result
                                                            .mainRedirectNumber
                                                )?.name ?? 'Номер телефона'
                                            )}
                                        </div>
                                        <div>
                                            {data?.result?.user?.title ===
                                            redirects?.result?.mainRedirectNumber?.replace(
                                                '+',
                                                ''
                                            ) ? null : (
                                                <div
                                                    className={
                                                        'me-1 ' +
                                                        getDot(
                                                            lines.find(
                                                                (x) =>
                                                                    x.number ===
                                                                    redirects
                                                                        ?.result
                                                                        ?.mainRedirectNumber
                                                            )?.status
                                                        )
                                                    }
                                                ></div>
                                            )}
                                            {
                                                redirects?.result
                                                    ?.mainRedirectNumber
                                            }
                                        </div>
                                    </>
                                ) : (
                                    'Не выбран номер'
                                )}
                            </div>
                        </>
                    ) : null}

                    <div className='footer'>
                        <Link to='/personal/accounts'>
                            <img
                                src='/images/icons/user.svg'
                                className='align-text-top'
                                width={16}
                            />{' '}
                            Панель пользователя
                        </Link>
                    </div>
                    {security?.isRoot ? (
                        <div className='footer'>
                            <Link to='/administrator/accounts'>
                                <img
                                    src='/images/icons/settings.svg'
                                    className='align-text-top'
                                    width={16}
                                />{' '}
                                Панель администратора
                            </Link>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='footer'>
                        <Link to='/logout'>
                            <img
                                src='/images/icons/log-out.svg'
                                className='align-text-top'
                                width={16}
                            />{' '}
                            Выход из системы
                        </Link>
                    </div>
                </>
            </OutsideClickHandler>
        );
        if (showRedirectPanel) {
            pnl = (
                <OutsideClickHandler callback={() => setUserShow(false)}>
                    <>
                        <div className='header'>
                            <img
                                src='/images/icons/arrow-left.svg'
                                className='icon-main pe-2 align-text-top cursor-pointer'
                                height={16}
                                onClick={() => setShowRedirectPanel(false)}
                            />
                            Переадресация
                        </div>
                        <div className='find-block'>
                            <FindComponent
                                callback={(value) => setSearch(value)}
                                placeholder='Поиск'
                            />
                        </div>
                        <div className='employees-block'>
                            <div
                                className='d-flex justify-content-between cursor-pointer'
                                onClick={() =>
                                    changeLine(data?.result.user.title)
                                }
                            >
                                <div>
                                    <img
                                        src='/images/icons/smartphone.svg'
                                        height={18}
                                        className='icon-gray align-top me-1'
                                    />
                                    Мой мобильный
                                </div>
                                <div className='employee-number'>
                                    {data?.result?.user?.title}
                                </div>
                            </div>
                            {employees?.result
                                .filter((x) => x.lineNumber !== '')
                                .map((item) => (
                                    <div
                                        key={item.id}
                                        className='redirect-employee d-flex justify-content-between cursor-pointer'
                                        onClick={() =>
                                            changeLine(item.lineNumber)
                                        }
                                    >
                                        <span>{item.name}</span>
                                        <div className='employee-number'>
                                            <div
                                                className={
                                                    'me-1 ' +
                                                    getDot(
                                                        lines.find(
                                                            (x) =>
                                                                x.number ===
                                                                item.lineNumber
                                                        )?.status
                                                    )
                                                }
                                            ></div>{' '}
                                            {item.lineNumber}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                </OutsideClickHandler>
            );
        }
        return pnl;
    };

    return (
        <div className='sidebar-header d-relative'>
            <div
                className={'user-block' + (userShow ? ' active' : '')}
                onClick={() => setUserShow(!userShow)}
            >
                <img
                    className='rounded-circle align-baseline me-2'
                    src='/images/no-user3.png'
                    height={32}
                />
                <div className='d-inline-block'>
                    {location.pathname.startsWith('/account/') ? (
                        <>
                            <div className='header-line d-flex align-items-center'>
                                {data?.result?.lineNumber.length > 0
                                    ? data?.result?.lineNumber
                                    : 'Нет линии'}
                                {redirects?.result?.mainRedirectActive ? (
                                    <>
                                        {' '}
                                        <img
                                            src='/images/icons/arrow-right.svg'
                                            width={14}
                                            className='mx-1'
                                        />{' '}
                                        {redirects?.result?.mainRedirectNumber}
                                    </>
                                ) : null}
                            </div>
                            {getMainStatus(
                                lines.find(
                                    (x) => x.number === data?.result.lineNumber
                                )?.status
                            )}
                        </>
                    ) : (
                        ''
                    )}
                    {location.pathname.startsWith('/administrator/') ? (
                        <>
                            <div className='header-line d-flex align-items-center'>
                                Администратор
                            </div>
                            <div className='header-line d-flex align-items-center'>
                                платформы
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {location.pathname.startsWith('/personal/') ? (
                        <>
                            <div className='header-line d-flex align-items-center'>
                                Пользователь
                            </div>
                            <div className='header-line d-flex align-items-center'>
                                {security?.user?.login}
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className={'user-menu' + (userShow ? '' : ' visually-hidden')}>
                {panel()}
            </div>
        </div>
    );
};

export default SidebarHeader;
