import { apiSlice } from './apiSlice';

export const accountsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAccountsList: builder.query({
            query: (data) =>
                `api/accounts?` +
                `&search=${data.search ?? ''}` +
                `&showArchive=${data.showArchive ?? false}` +
                `&sort=${data.sort}` +
                `&pageSize=${data.pageSize ?? 100}` +
                `&pageIndex=${data.pageIndex ?? 1}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Accounts',
                              id,
                          })),
                          { type: 'Accounts', id: 'List' },
                      ]
                    : [{ type: 'Accounts', id: 'List' }],
        }),
        getAccountById: builder.query({
            query: (entityId) => `api/accounts/${entityId}`,
            providesTags: (result) => [
                { type: 'Account', id: result.result.id },
            ],
        }),
        createAccount: builder.mutation({
            query: (credentials) => ({
                url: 'api/accounts',
                method: 'POST',
                body: { ...credentials },
            }),
            invalidatesTags: [{ type: 'Accounts', id: 'List' }],
        }),
        updateAccount: builder.mutation({
            query: (credentials) => ({
                url: 'api/accounts/' + credentials.id,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Accounts', id: 'List' },
                { type: 'Account', id: result.result.id },
            ],
        }),
        patchAccount: builder.mutation({
            query: ({ id, items }) => ({
                url: 'api/accounts/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Accounts', id: 'List' },
                { type: 'Account', id: result.result.id },
            ],
        }),
        deleteAccount: builder.mutation({
            query: (entityId) => ({
                url: 'api/accounts/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Accounts', id: 'List' }],
        }),
    }),
});

export const {
    useGetAccountsListQuery,
    useLazyGetAccountsListQuery,
    useGetAccountByIdQuery,
    useLazyGetAccountByIdQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    usePatchAccountMutation,
    useDeleteAccountMutation,
} = accountsApiSlice;
