import { apiSlice } from './apiSlice';

export const newsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNewsList: builder.query({
            query: (data) =>
                'api/notifications?' + `accountId=${data.accountId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'News',
                              id,
                          })),
                          { type: 'News', id: 'List' },
                      ]
                    : [{ type: 'News', id: 'List' }],
        }),
        deleteNews: builder.mutation({
            query: (entityId) => ({
                url: 'api/notifications/read?taskId=' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'News', id: 'List' }],
        }),
        deleteNewsAll: builder.mutation({
            query: () => ({
                url: 'api/notifications/readall',
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'News', id: 'List' }],
        }),
    }),
});

export const {
    useGetNewsListQuery,
    useDeleteNewsMutation,
    useDeleteNewsAllMutation,
} = newsApiSlice;
