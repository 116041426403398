import { apiSlice } from './apiSlice';

export const tasksApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTasksList: builder.query({
            query: (data) =>
                'api/tasks?' +
                `accountId=${data.accountId}` +
                `&companyId=${data.companyId ?? ''}` +
                `&contactId=${data.contactId ?? ''}` +
                `&slice=${data.slice ?? ''}` +
                `&status=${data.status ?? ''}` +
                `&sort=${data.sort ?? ''}` +
                `&authorId=${data.authorId ?? ''}` +
                (data?.members
                    ? data?.members
                          .map(
                              (x) => `&memberIds=${x.isGroup ? '-' : ''}${x.id}`
                          )
                          .join('')
                    : '') +
                `&search=${data.search ?? ''}` +
                `&pageSize=${data.pageSize ?? 100}&pageIndex=${
                    data.pageIndex ?? 1
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Tasks',
                              id,
                          })),
                          { type: 'Tasks', id: 'List' },
                      ]
                    : [{ type: 'Tasks', id: 'List' }],
        }),
        getTaskById: builder.query({
            query: (entityId) => `api/tasks/${entityId}`,
            providesTags: (result) => [{ type: 'Task', id: result.result.id }],
        }),
        getMessagesList: builder.query({
            query: (taskId) => `api/tasks/${taskId}/messages`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Messages',
                              id,
                          })),
                          { type: 'Messages', id: 'List' },
                      ]
                    : [{ type: 'Messages', id: 'List' }],
        }),
        getMembersList: builder.query({
            query: (taskId) => `api/tasks/${taskId}/members`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Members',
                              id,
                          })),
                          { type: 'Members', id: 'List' },
                      ]
                    : [{ type: 'Members', id: 'List' }],
        }),
        createTask: builder.mutation({
            query: (data) => ({
                url: 'api/tasks',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Tasks', id: 'List' }],
        }),
        createMessage: builder.mutation({
            query: (data) => ({
                url: `api/tasks/${data.taskId}/messages`,
                method: 'POST',
                body: { ...data.message },
            }),
            invalidatesTags: [{ type: 'Messages', id: 'List' }],
        }),
        createMember: builder.mutation({
            query: (data) => ({
                url: `api/tasks/${data.taskId}/members`,
                method: 'POST',
                body: { ...data.member },
            }),
            invalidatesTags: [{ type: 'Members', id: 'List' }],
        }),
        updateTask: builder.mutation({
            query: (data) => ({
                url: 'api/tasks/' + data.id,
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tasks', id: 'List' },
                { type: 'Task', id: result.result.id },
            ],
        }),
        deleteTask: builder.mutation({
            query: (entityId) => ({
                url: 'api/tasks/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Tasks', id: 'List' }],
        }),
        deleteMember: builder.mutation({
            query: (entityId) => ({
                url: 'api/tasks/members/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Members', id: 'List' }],
        }),
        patchTask: builder.mutation({
            query: ({ id, items }) => ({
                url: 'api/tasks/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tasks', id: 'List' },
                { type: 'Task', id: result.result.id },
            ],
        }),
    }),
});

export const {
    useGetTasksListQuery,
    useLazyGetTasksListQuery,
    useGetTaskByIdQuery,
    useGetMessagesListQuery,
    useGetMembersListQuery,
    useLazyGetMembersListQuery,
    useLazyGetTaskByIdQuery,
    useCreateTaskMutation,
    useCreateMessageMutation,
    useCreateMemberMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useDeleteMemberMutation,
    usePatchTaskMutation,
} = tasksApiSlice;
