import { apiSlice } from './apiSlice';

export const overviewApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOverview: builder.query({
            query: (entityId) => `api/overview?accountId=${entityId}`,
        }),
    }),
});

export const {
    useGetOverviewQuery,
    useLazyGetOverviewQuery,
} = overviewApiSlice;
