import { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Paginator = (props) => {
    const {
        pageCount,
        onPageChange,
        countCurrent,
        countTotal,
        pageSize,
        pageSizeArray,
        onPageSizeChange,
        forcePage,
    } = props;

    const [currentPage, setCurrentPage] = useState(1);

    const changePage = (data) => {
        setCurrentPage(data.selected + 1);
        onPageChange(data.selected + 1);
    };

    let sizeArr = [20, 50, 100];
    if (
        pageSizeArray !== undefined &&
        pageSizeArray != null &&
        pageSizeArray.length > 0
    ) {
        sizeArr = pageSizeArray;
    }

    let size = 100;
    if (pageSize !== undefined && pageSize != null) {
        size = pageSize;
    }

    return (
        <div className='row'>
            <div className='col-md-6 paginator'>
                <ReactPaginate
                    previousLabel={
                        <>
                            <i className='fas fa-arrow-left me-2' /> Назад
                        </>
                    }
                    nextLabel={
                        <>
                            Вперёд <i className='fas fa-arrow-right ms-2' />
                        </>
                    }
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => changePage(data)}
                    containerClassName={'cx-pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item page-item-left'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item page-item-right'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    forcePage={forcePage}
                />
            </div>
            {countCurrent !== undefined && countCurrent != null ? (
                <div className='col-md-2'>
                    <span
                        className='cx-page-count-info'
                        id='paginatorCountInfoText'
                    >
                        {(currentPage - 1) * pageSize + countCurrent}/
                        {countTotal}
                    </span>
                </div>
            ) : null}
            {onPageSizeChange !== undefined && onPageSizeChange != null ? (
                <div className='col-md-4 text-end'>
                    <div className='page-size-block'>
                        <span className='caption'>Показывать строк:</span>
                        <div
                            className='page-size-numbers'
                            role='group'
                        >
                            {sizeArr.map((item, index) => (
                                <button
                                    key={item}
                                    className={
                                        'page-button' +
                                        (item === size ? ' active' : '') +
                                        (index === 0 ? ' left' : '') +
                                        (index === sizeArr.length-1 ? ' right' : '')
                                    }
                                    onClick={() => onPageSizeChange(item)}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Paginator;
