import { useEffect, useState } from "react";
import { FormatDateToHuman3 } from '../../helpers/DateHelpers';
import { useNavigate } from 'react-router-dom';
import { useDeleteNotificationMutation } from "../../app/api/notificationsApiSlice";

const NotificationRow = (props) => {
    const { entity, closeHandle, taskUrl, companyUrl, reloadCallback } = props;
    const navigate = useNavigate();
    const [deleteNotitifaction, { r }] = useDeleteNotificationMutation();

    const openCompany = () => {
        console.log(companyUrl);
        closeHandle();
        navigate(companyUrl);
    }

    const openTask = () => {
        console.log(taskUrl);
        closeHandle();
        navigate(taskUrl);
    }

    const getAction = ()=> {
        if(entity.type === 'task_add_message'){
            return 'прокомментировал';
        } else if(entity.type === 'task_add_member'){
            return 'добавил вас в задачу';
        } else if(entity.type === 'task_complete'){
            return 'закрыл задачу';
        }

    }

    const readNotification = () => {
        console.log('delete notification = ' + entity.taskId);
        deleteNotitifaction({taskId: entity.taskId});
        //reloadCallback();
    }


    return (
        <>
            <div className="indent justify-between">
                <div>
                    <span className="author">{entity.author.title}</span> 
                    <span className="action"> {getAction()} </span> 
                    <span className="subject cursor-pointer" onClick={openTask}>{entity.subject}</span>
                </div>
                <div className="close cursor-pointer" onClick={readNotification}>
                    <img src="/images/icons/x.svg" width={16} />
                </div>
            </div>
            <div className="date indent">
                {FormatDateToHuman3(entity.date)}
                {entity.company ? (
                    <span onClick={openCompany} className="cursor-pointer"> • {entity.company.title}</span>
                    ) : ''}
            </div>
            {entity.type === 'task_add_message' ? (
                <>
                    <div className="separator"></div>
                    <div className="indent">
                        <div className="comments-header">
                            <img src="/images/icons/message-circle.svg" width={16} className="icon-gray" />
                            <span>Комментарий</span>
                        </div>
                        <div className="comments-text">
                            {entity.description}
                        </div>
                    </div>
                </>
            ) : ''}
        </>
    );
};
        
export default NotificationRow;