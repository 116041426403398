import PageTitle from '../../components/layout/PageTitle';
import ReportsList from './ReportsList';

const ReportsPage = (props) => {
    return (
        <>
            <PageTitle
                title='Звонки'
                icon={
                    <img
                        src='/images/icons/file-text.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
            />
            <ReportsList />
        </>
    );
};

export default ReportsPage;
