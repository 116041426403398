import { Outlet } from 'react-router-dom';
import PageTitle from '../../components/layout/PageTitle';
import TasksList from './TasksList';

const TasksPage = (props) => {
    return (
        <>
            <PageTitle
                title="Задачи"
                icon={
                    <img
                        src="/images/icons/target.svg"
                        width={24}
                        className="icon-main"
                    />
                }
                disabled={true}
            />
            <TasksList />
            <Outlet />
        </>
    );
};

export default TasksPage;
