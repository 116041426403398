import React, { useEffect, useState } from 'react';

const SortableTableHead = (props) => {
    const {
        label,
        handleClick,
        field,
        value,
        classes,
        scope,
    } = props;

    const [icon, setIcon] = useState('');


    useEffect(()=>{
        if(value === field){
            setIcon(<img src='/images/icons/arrow-down.svg' width={16} className='icon-gray' />);
        }
        else if(value === field+'_desc'){
            setIcon(<img src='/images/icons/arrow-up.svg' width={16} className='icon-gray' />);
        }
        else {
            setIcon('');
        }

    }, [value])

    function changeSort(){
        if(value === field){
            handleClick(field+'_desc');
        } 
        else if(value === field+'_desc'){
            handleClick(field);
        }
        else {
            handleClick(field);
        }

    }

    return (
        <th
            scope={scope}
            className={'sortable-head ' + classes}
            onClick={changeSort}
        >
            <span>{label}</span>
            <span>{icon}</span>
        </th>
    );
};

export default SortableTableHead;
