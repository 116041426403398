import { apiSlice } from './apiSlice';

export const holdingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHoldingsList: builder.query({
            query: (data) =>
            'api/holdings?' +
            `accountId=${data.accountId}` +
            `&search=${data.search ?? ''}`+
            `&showArchive=${data.showArchive ?? false}`+
            `&sort=${data.sort}` +
            `&pageSize=${data.pageSize ?? 100}` +
            `&pageIndex=${data.pageIndex ?? 1}`,
        providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Holdings',
                              id,
                          })),
                          { type: 'Holdings', id: 'List' },
                      ]
                    : [{ type: 'Holdings', id: 'List' }],
        }),
        getHoldingById: builder.query({
            query: (entityId) => `api/holdings/${entityId}`,
            providesTags: (result) => [{ type: 'Holding', id: result.result.id }],
        }),
        createHolding: builder.mutation({
            query: (credentials) => ({
                url: 'api/holdings',
                method: 'POST',
                body: { ...credentials },
            }),
            invalidatesTags: [{ type: 'Holdings', id: 'List' }],
        }),
        updateHolding: builder.mutation({
            query: (credentials) => ({
                url: 'api/holdings/' + credentials.id,
                method: 'PUT',
                body: { ...credentials },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Holdings', id: 'List' },
                { type: 'Holding', id: result.result.id },
            ],
        }),
        patchHolding: builder.mutation({
            
            query: ({id, items}) => ({
                url: 'api/holdings/' + id,
                method: 'PATCH',
                body: items,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Holdings', id: 'List' },
                { type: 'Holding', id: result.result.id },
            ],
        }),
        deleteHolding: builder.mutation({
            query: (entityId) => ({
                url: 'api/holdings/' + entityId,
                method: 'delete',
            }),
            invalidatesTags: [{ type: 'Holdings', id: 'List' }],
        }),
    }),
});

export const {
    useGetHoldingsListQuery,
    useLazyGetHoldingsListQuery,
    useGetHoldingByIdQuery,
    useLazyGetHoldingByIdQuery,
    useCreateHoldingMutation,
    useUpdateHoldingMutation,
    usePatchHoldingMutation,
    useDeleteHoldingMutation,
} = holdingsApiSlice;
