import { apiSlice } from './apiSlice';

export const callbackApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createCallback: builder.mutation({
            query: (data) => ({
                url: 'api/callback',
                method: 'POST',
                body: { ...data },
            })
        }),
    }),
});

export const {
    useCreateCallbackMutation,
} = callbackApiSlice;
