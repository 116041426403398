import { selectLines } from '../../features/lines/linesSlice';
import { useSelector } from 'react-redux';
import PhoneComponent from '../calls/PhoneComponent';

const LineStatus = (props) => {
    const { number, accountId } = props;

    const lines = useSelector(selectLines);

    const getDot = (status) => {
        let dot = 'gray-dot';
        switch (status) {
            case 'Idle':
                dot = 'green-dot';
                break;
            case 'InUse':
            case 'InUse&Ringing':
            case 'Ringing':
            case 'Busy':
                dot = 'orange-dot';
                break;
            case 'Unavailable':
                dot = 'gray-dot';
                break;

            default:
                dot = 'gray-dot';
                break;
        }

        return dot;
    };

    return (
        <div className='line-status'>
            {number ? (
                <>
                    <div
                        className={
                            'me-1 ' +
                            getDot(
                                lines.find((x) => x.number === number)?.status
                            )
                        }
                    ></div>
                    <PhoneComponent phone={number} accountId={accountId} />
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default LineStatus;
