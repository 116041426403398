import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';

const MenuPersonal = (props) => {
    const { mode } = props;
    const location = useLocation();

    return (
        <>
            <MenuItem
                to={`/personal/accounts`}
                icon={
                    <img
                        src='/images/icons/home.svg'
                        width={16}
                        className='me-2 icon-nav'
                    />
                }
                title='Обзор'
            />
        </>
    );
};

export default MenuPersonal;
