import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import SearchableList from '../filters/SearchableList';

import { useLazyGetTagsListQuery } from '../../app/api/tagsApiSlice';

const TagEditor = (props) => {
    const { list, selectCallback, deleteCallback, type = 'contact' } = props;
    let params = useParams();

    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [getList, { data }] = useLazyGetTagsListQuery();

    const selectValue = (value) => {
        let exist = list.find((x) => x.id === value.id);
        if (exist === undefined) {
            selectCallback(value);
        }
    };

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            type,
            showArchive: false,
            pageSize: 50,
        });
    };

    const removeItem = (id) => {
        deleteCallback(id);
    };

    return (
        <>
            <div
                className='filters-tag-list position-relative'
                onClick={() => setIsEdit(true)}
            >
                {list?.length > 0
                    ? list?.map((x) => {
                          return (
                              <div key={x.id} className='filters-tag-list-item'>
                                  <span>{x.title}</span>
                              </div>
                          );
                      })
                    : 'Пусто'}
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-300 height-400 filters-dropdown'
                        }
                    >
                        <>
                            <div className='filters-tag-list borders mx-3 mb-2'>
                                {list?.map((x) => {
                                    return (
                                        <div
                                            key={x.id}
                                            className='filters-tag-list-item'
                                        >
                                            <span>{x.title}</span>
                                            <img
                                                src='/images/icons/x.svg'
                                                width={12}
                                                className='icon-main cursor-pointer'
                                                onClick={() => removeItem(x.id)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <SearchableList
                                value={list}
                                list={data?.result}
                                searchCallback={(search) =>
                                    loadDataAsync(search)
                                }
                                selectCallback={(value) => selectValue(value)}
                                hideEmpty={true}
                            />
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default TagEditor;
