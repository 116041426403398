const FilterIcon = (props) => {
    const { value, filename, width, withoutActive } = props;

    return (
        <img
            src={`/images/icons/${filename}.svg`}
            width={width ?? 16}
            className={
                withoutActive ||
                value === null ||
                value === '' ||
                value === undefined ||
                value.length === 0
                    ? 'icon-gray'
                    : 'icon-active'
            }
        />
    );
};

export default FilterIcon;
