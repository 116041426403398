import { useEffect, useState } from 'react';
import OutsideClickHandler from '../OutsideClickHandler';

const AddEntry = (props) => {
    const { value, placeholder, valueChanged, classes, type = 'phone' } = props;
    const [text, setText] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [ddOpen, setDdOpen] = useState(false);

    const [typeSelected, setTypeSelected] = useState('mobile');

    useEffect(() => {
        setText(value);
    }, [value]);

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commitChanges();
        } else if (event.code === 'Escape') {
            setText(value);
            setIsEdit(false);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if (text !== value) {
            console.log('commit');
            valueChanged(text, type === 'phone' ? typeSelected : 'email');
        } else {
            console.log('no commit');
        }
        setText('');
        setTypeSelected('mobile');
        setIsEdit(false);
    };

    const ddOpenHandle = () => {
        if (type === 'email') {
            setTypeSelected('email');
            setIsEdit(true);
        } else {
            setDdOpen(true);
        }
    };

    const changePhoneType = (phoneType) => {
        setTypeSelected(phoneType);
        setIsEdit(true);
        setDdOpen(false);
    };

    const getIcon = () => {
        let icon = 'smartphone';
        switch (typeSelected) {
            case 'mobile':
                icon = 'smartphone.svg';
                break;
            case 'work_phone':
                icon = 'phone.svg';
                break;
            case 'email':
                icon = 'mail.svg';
                break;

            default:
                break;
        }
        return icon;
    };

    const getName = () => {
        let name = 'Мобильный';
        switch (typeSelected) {
            case 'mobile':
                name = 'Мобильный';
                break;
            case 'work_phone':
                name = 'Рабочий';
                break;
            case 'email':
                name = 'Email';
                break;

            default:
                break;
        }
        return name;
    };

    return (
        <div className='row mt-4'>
            {isEdit ? (
                <>
                    <div className='col-md-4 property property-title cursor-pointer'>
                        <img
                            src={'/images/icons/' + getIcon()}
                            width={16}
                            className='icon-gray'
                        />
                        <span>{getName()}</span>
                    </div>
                    <div className='col-md-8'>
                        <input
                            className={
                                'form-control text-input text-input-active ' +
                                classes
                            }
                            placeholder={placeholder}
                            value={text}
                            onChange={(v) => setText(v.target.value)}
                            onKeyDown={handleKeyDown}
                            onBlur={commitChanges}
                            type={type}
                        />
                    </div>
                </>
            ) : null}
            <div className='col'>
                <div className='position-relative'>
                    <button
                        className={
                            'btn property property-title border-0 ps-0 font-weight-600' +
                            (ddOpen ? ' show' : '')
                        }
                        type='button'
                        onClick={ddOpenHandle}
                    >
                        <img
                            src='/images/icons/plus.svg'
                            width={16}
                            className='icon-gray'
                        />
                        {type === 'phone'
                            ? 'Добавить телефон'
                            : 'Добавить Email'}
                    </button>
                    <OutsideClickHandler callback={() => setDdOpen(false)}>
                        <ul
                            className={
                                'dropdown-menu' + (ddOpen ? ' show' : '')
                            }
                        >
                            <li>
                                <button
                                    className='dropdown-item property property-title'
                                    onClick={() => changePhoneType('mobile')}
                                >
                                    <img
                                        src='/images/icons/smartphone.svg'
                                        width={16}
                                        className='icon-gray'
                                    />
                                    Мобильный
                                </button>
                            </li>
                            <li>
                                <button
                                    className='dropdown-item property property-title'
                                    onClick={() =>
                                        changePhoneType('work_phone')
                                    }
                                >
                                    <img
                                        src='/images/icons/phone.svg'
                                        width={16}
                                        className='icon-gray'
                                    />
                                    Рабочий
                                </button>
                            </li>
                        </ul>
                    </OutsideClickHandler>
                </div>
            </div>
        </div>
    );
};

export default AddEntry;
