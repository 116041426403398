import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterArrayObjectHeader from './FilterArrayObjectHeader';
import SearchableList from './SearchableList';

import { useLazyGetEmployeesListQuery } from '../../app/api/employeesApiSlice'
import { useLazyGetDepartmentsListQuery } from '../../app/api/departmentsApiSlice'

const MembersFilter = (props) => {
    const { value, selectCallback, type } = props;
    let params = useParams();

    const accountId = params.accountId;


    const [isEdit, setIsEdit] = useState(false);
    const [slice, setSlice] = useState('employees');
    const [selectedValue, setSelectedValue] = useState([]);
    const [getEmployees, { data:employees }] = useLazyGetEmployeesListQuery();
    const [getDepartments, { data:departments }] = useLazyGetDepartmentsListQuery();

    useEffect(()=>{
        setSelectedValue(value ?? []);
    },[value])

    useEffect(()=>{
        selectCallback(selectedValue)
        console.log(selectedValue);
    }, [selectedValue])

    useEffect(()=>{
        console.log(employees);
    }, [employees])

    const selectEmployee = (value) => {
        let exist = selectedValue.find(x=>x.id === value.id && x.isGroup === false);
        if(exist === undefined){
            setSelectedValue([...selectedValue, {id: value.id, name: value.name, isGroup: false}]);
        }
    }

    const selectDepartment = (value) => {
        let exist = selectedValue.find(x=>x.id === value.id && x.isGroup === true);
        if(exist === undefined){
            setSelectedValue([...selectedValue, {id: value.id, name: value.name, isGroup: true}]);
        }
    }

    const loadEmployeesAsync = async (search) => {
        await getEmployees({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50
        });
    }
    const loadDepartmentsAsync = async (search) => {
        await getDepartments({
            accountId,
            search,
            sort: 'name',
            showArchive: false,
            pageSize: 50
        });
    }

    const removeItem = (item) => {
        console.log('delete ' + item.id + ' ' + item.isGroup);
        setSelectedValue(selectedValue.filter(x=>! (x.id === item.id && x.isGroup === item.isGroup)));
    }


    return (
        <>
            <FilterArrayObjectHeader value={selectedValue} title='Исполнитель' icon='user' clickHandler={()=>setIsEdit(true)}>
                {isEdit ? (
                    <OutsideClickHandler callback={()=>setIsEdit(false)} classes={"dropdown width-300 height-450 filters-dropdown"} >
                        <>
                            <div className="filters-tag-panel">
                                <div className="filters-tag-list">
                                    {selectedValue?.map((x)=>{
                                        return (
                                            <div key={x.id} className="filters-tag-list-item">
                                                {x.isGroup ? (
                                                <img src="/images/icons/users.svg" width={12} className="icon-main cursor-pointer" />
                                                ) : ''}
                                                <span>{x.name}</span>
                                                <img src="/images/icons/x.svg" width={12} className="icon-main cursor-pointer" onClick={()=>removeItem(x)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className='slices-container' style={{justifyContent: 'space-between'}}>
                                <div className='alignCenter'>
                                    <div className={'slice ' + (slice === 'employees' ? 'active-slice' : '')} onClick={()=>setSlice('employees')}>
                                        Сотрудники
                                    </div>
                                    <div className={'slice ' + (slice === 'departments' ? 'active-slice' : '')} onClick={()=>setSlice('departments')}>
                                        Отделы
                                    </div>
                                </div>
                            </div>

                            {slice === 'employees' ? (
                                <>
                                    <SearchableList 
                                        list={employees?.result} 
                                        searchCallback={(search)=>loadEmployeesAsync(search)} 
                                        selectCallback={(value)=>selectEmployee(value)}
                                        hideEmpty={true}
                                    />
                                </>
                            ) : ''}
                            {slice === 'departments' ? (
                                <>
                                    <SearchableList 
                                        list={departments?.result} 
                                        searchCallback={(search)=>loadDepartmentsAsync(search)} 
                                        selectCallback={(value)=>selectDepartment(value)}
                                        hideEmpty={true}
                                    />
                                </>
                            ) : ''}

                        </>
                    </OutsideClickHandler>
                ) : ''}
            </FilterArrayObjectHeader>           
        </>
    );
};

export default MembersFilter;