import { useState } from 'react';
import {
    useCreateMessageMutation,
    useGetMessagesListQuery,
} from '../../../app/api/tasksApiSlice';
import moment from 'moment';

const TaskMessagesPanel = (props) => {
    const { taskId } = props;

    const taskBody = document.getElementById('taskBody');

    const { data } = useGetMessagesListQuery(taskId);
    const [createMessage] = useCreateMessageMutation();

    const [newMessage, setNewMessage] = useState('');

    const sendMessage = async () => {
        if (newMessage?.length > 0) {
            const entity = {
                taskId,
                message: {
                    text: newMessage,
                    action: 1,
                },
            };
            createMessage(entity);
            setNewMessage('');
        }
    };

    const message = (msg) => {
        let text = '';
        let authorClass = '';
        let textClass = '';
        console.log('msg', msg);
        switch (msg.action) {
            case 'message':
                text = msg.text;
                break;
            case 'create':
                text = 'Создал задачу';
                authorClass = 'opacity-7 font-weight-600';
                textClass = 'opacity-7 color-medium-dark';
                break;
            case 'complete':
                text = 'Завершил задачу';
                authorClass = 'opacity-7 font-weight-600';
                textClass = 'opacity-7 color-medium-dark';
                break;
            default:
                text = msg.text;
                break;
        }
        return (
            <div className='message-box' key={msg.id}>
                <div className='message-author d-flex justify-content-between '>
                    <div className={authorClass}>{msg.author.title}</div>
                    <div className='message-time ms-2'>
                        {moment(new Date(msg.createdAt))
                            .format('DD MMMM YYYY HH:mm')}
                    </div>
                </div>
                <div className={'message-text ' + textClass}>{text}</div>
            </div>
        );
    };

    return (
        <div className='messages-container'>
            <div className='messages-list'>
                {data?.result?.map((item) => {
                    return message(item);
                })}
            </div>
            <div
                className='messages-footer d-flex align-items-center'
                style={{ width: taskBody?.clientWidth }}
            >
                <textarea
                    className='form-control me-2'
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    placeholder='Написать комментарий...'
                />
                <div>
                    <button 
                        className='btn btn-outline-dark d-flex align-items-center'
                        onClick={sendMessage}>
                        <img
                            className='cursor-pointer icon-medium-dark me-2'
                            src='/images/icons/arrow-up-circle.svg'                            
                            height={20}
                        />
                        Отправить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TaskMessagesPanel;
