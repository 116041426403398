import PageTitle from '../../components/layout/PageTitle';
import EmployeesList from './EmployeesList'

const EmployeesPage = (props) => {
    return (
        <>
            <PageTitle
                title='Сотрудники'
                icon={
                    <img
                        src='/images/icons/user.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
             <EmployeesList />
        </>
    );
};

export default EmployeesPage;