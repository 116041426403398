import PageTitle from '../../components/layout/PageTitle';
import TagsList from './TagsList';

const TagsPage = (props) => {
    return (
        <>
            <PageTitle
                title='Теги'
                icon={
                    <img
                        src='/images/icons/tag.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
             <TagsList />
        </>
    );
};

export default TagsPage;