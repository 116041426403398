import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetEmployeesListQuery } from '../../app/api/employeesApiSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import FindComponent from '../../components/filters/FindComponent';
import Button from '../../components/Button';
import SortableTableHead from '../../components/SortableTableHead';
import LineStatus from '../../components/editors/LineStatus';
import SliceIcon from '../../components/SliceIcon';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../features/auth/authSlice';
import Paginator from '../../components/Paginator';

const EmployeesList = (props) => {
    const {
        fastFilters = true,
        preDepartmentId = '',
        excludeCols = [],
    } = props;
    let params = useParams();
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const accountId = params.accountId;
    const [showArchive, setShowArchive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState('name');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [getList, { data, isLoading }] = useLazyGetEmployeesListQuery();

    const [securityContext, setSecurityContext] = useState(null);
    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                sort,
                showArchive,
                departmentId: preDepartmentId ?? '',
                pageSize,
                pageIndex,
            });
        }
        loadDataAsync();
    }, [accountId, searchString, showArchive, sort, pageSize, pageIndex]);

    const openEntityCard = (entityId) => {
        navigate(`/account/${accountId}/employees/${entityId}`);
    };

    return (
        <>
            {fastFilters ? (
                <div
                    className='slices-container'
                    style={{ justifyContent: 'space-between' }}
                >
                    <div className='alignCenter'>
                        <div
                            className={
                                'slice ' + (!showArchive ? 'active-slice' : '')
                            }
                            onClick={() => setShowArchive(false)}
                        >
                            <SliceIcon value={!showArchive} filename='loader' />
                            Все
                        </div>
                        <div
                            className={
                                'slice ' + (showArchive ? 'active-slice' : '')
                            }
                            onClick={() => setShowArchive(true)}
                        >
                            <SliceIcon value={showArchive} filename='archive' />
                            Показать архивные
                        </div>
                    </div>
                    <div>
                        <Button
                            label='Добавить сотрудника'
                            btnColor='blue'
                            handleClick={() =>
                                navigate(`/account/${accountId}/employees/0`)
                            }
                            disabled={
                                !(security?.isRoot ?? false) &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
            </div>
            <div className='row'>
                <div className='col'>
                    <SkeletonTable isLoading={isLoading}>
                        <table className='table table-sm content-table'>
                            <thead>
                                <tr>
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Имя'
                                        field='name'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Должность'
                                        field='occupation'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                    {excludeCols.find(
                                        (x) => x === 'department'
                                    ) === undefined ? (
                                        <SortableTableHead
                                            scope='col'
                                            classes='ps-3'
                                            label='Отдел'
                                            field='department'
                                            value={sort}
                                            handleClick={(value) =>
                                                setSort(value)
                                            }
                                        />
                                    ) : null}
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Линия'
                                        field='line'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                    <th scope='col'>Переадресация</th>
                                    <th scope='col'>Логин</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => {
                                    return (
                                        <tr
                                            className={
                                                'cursor-pointer' +
                                                (item.isArchive
                                                    ? ' archive-row'
                                                    : '')
                                            }
                                            key={item.id}
                                            onClick={() =>
                                                openEntityCard(item.id)
                                            }
                                        >
                                            <td className='ps-3 left-cell text-medium'>
                                                {item.name}
                                            </td>
                                            <td className='cell text-normal'>
                                                {item.occupation}
                                            </td>
                                            {excludeCols.find(
                                                (x) => x === 'department'
                                            ) === undefined ? (
                                                <td className='cell text-normal'>
                                                    {item.department?.title}
                                                </td>
                                            ) : null}
                                            <td className='cell text-normal'>
                                                <LineStatus
                                                    accountId={accountId}
                                                    number={item.lineNumber}
                                                />
                                            </td>
                                            <td className='cell redirect-cell text-normal'>
                                                {item.redirectOn ? (
                                                    <img
                                                        src='/images/icons/phone-forwarded.svg'
                                                        width={20}
                                                        className='icon-green'
                                                    />
                                                ) : (
                                                    <img
                                                        src='/images/icons/phone-off.svg'
                                                        width={20}
                                                        className='icon-gray'
                                                    />
                                                )}
                                                {item.redirectNumber ? (
                                                    <span>
                                                        {item.redirectNumber}
                                                    </span>
                                                ) : (
                                                    <span>не указано</span>
                                                )}
                                            </td>
                                            <td className='right-cell  text-normal'>
                                                {item?.user?.title}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
            <div className='card-body'>
                <Paginator
                    onPageChange={(e) => setPageIndex(e)}
                    onPageSizeChange={(e) => setPageSize(e)}
                    pageCount={data?.paginator?.totalPages ?? 0}
                    pageSizeArray={[50, 100, 500]}
                    pageSize={pageSize}
                    countCurrent={data?.result?.length ?? 0}
                    countTotal={data?.paginator?.totalCount ?? 0}
                />
            </div>
        </>
    );
};

export default EmployeesList;
