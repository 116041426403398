import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import {
    useChangePasswordMutation,
    useLazyGetSmsCodeQuery,
    useLoginBySmsMutation,
    useLoginMutation,
} from './authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';

const Registration = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [changePassword] = useChangePasswordMutation();

    const [login, setLogin] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [passNew, setPassNew] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isPassError, setIsPassError] = useState(false);
    const [passError, setPassError] = useState('');
    const [isPassCompareError, setIsPassCompareError] = useState(false);
    const [isSendCode, setIsSendCode] = useState(false);
    const [isAuthorize, setIsAuthorize] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const [getSmsCode, result] = useLazyGetSmsCodeQuery();
    const [loginBySms, resultLogin] = useLoginBySmsMutation();

    const handleGetCode = async () => {
        setIsSendCode(true);
        await getSmsCode({
            login,
        }).unwrap();
    };

    const handelConfirmCode = async () => {
        const authParam = {
            login,
            code: phoneCode.replace('-', ''),
        };
        const userData = await loginBySms(authParam).unwrap();
        if (!userData.error) {
            dispatch(setCredentials({ ...userData, login }));
            setIsAuthorize(true);
        }
    };

    const handleChangePassword = async () => {
        setIsPassError(false);
        if (passNew !== confirmPass) {
            setIsPassCompareError(true);
            setPassError('Пароли не совпадают');
        } else if (
            !/(?=.*[0-9])(?=.*[a-zA-Zа-яА-Я])[0-9a-zA-Zа-яА-Я!@#$%^&*№\-+.,:;\'\"\/\[\]\(\)\?\\]{8,}/.test(
                passNew
            )
        ) {
            setIsPassCompareError(false);
            setIsPassError(true);
            setPassError(
                'Пароль должен быть длиной от 8 символов, иметь хотя бы одну цифру и букву!'
            );
        } else {
            await changePassword({
                password: passNew,
            }).unwrap();
            window.location.href = '/';
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6 login-left-block'>
                    <div className='login-left-content'>
                        <img
                            src='/images/shLogoFull.svg'
                            className='img-fluid'
                        />
                        <div>
                            <h2 className='w-600'>
                                Ассистент для виртуальной АТС
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 vh-100 py-2'>
                    <div className='card login-card'>
                        <div className='card-body p-0 d-flex justify-content-between flex-column'>
                            <div></div>
                            <div className='row'>
                                <div className='col-md-8 offset-md-2 d-flex justify-content-center flex-column'>
                                    <section className='login pe-4'>
                                        <h2 className='mb-2 w-700'>
                                            {props.isForgot
                                                ? 'Сброс пароля'
                                                : 'Регистрация'}
                                        </h2>
                                        {isAuthorize ? (
                                            <React.Fragment>
                                                <div className='text-gray w-500 mb-4'>
                                                    Задайте постоянный пароль
                                                </div>
                                                <div className='mb-2 position-relative'>
                                                    <div
                                                        className='position-absolute show-password cursor-pointer'
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <img
                                                                src='/images/icons/eye.svg'
                                                                width='20px'
                                                                height='20px'
                                                            />
                                                        ) : (
                                                            <img
                                                                src='/images/icons/eye-off.svg'
                                                                width='20px'
                                                                height='20px'
                                                            />
                                                        )}
                                                    </div>
                                                    <label
                                                        htmlFor='passNew'
                                                        className='form-label'
                                                    >
                                                        Пароль
                                                    </label>
                                                    <input
                                                        type={
                                                            showPassword
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        className={
                                                            'form-control' +
                                                            (isPassError ||
                                                            isPassCompareError
                                                                ? ' is-invalid'
                                                                : '')
                                                        }
                                                        id='passNew'
                                                        value={passNew}
                                                        onChange={(e) =>
                                                            setPassNew(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <div className='invalid-feedback'>
                                                        {passError}
                                                    </div>
                                                </div>
                                                <div className='mb-4 position-relative'>
                                                    <div
                                                        className='position-absolute show-password cursor-pointer'
                                                        onClick={() =>
                                                            setShowRepeatPassword(
                                                                !showRepeatPassword
                                                            )
                                                        }
                                                    >
                                                        {showRepeatPassword ? (
                                                            <img
                                                                src='/images/icons/eye.svg'
                                                                width='20px'
                                                                height='20px'
                                                            />
                                                        ) : (
                                                            <img
                                                                src='/images/icons/eye-off.svg'
                                                                width='20px'
                                                                height='20px'
                                                            />
                                                        )}
                                                    </div>
                                                    <label
                                                        htmlFor='confirmPass'
                                                        className='form-label'
                                                    >
                                                        Повторить пароль
                                                    </label>
                                                    <input
                                                        type={
                                                            showRepeatPassword
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        className={
                                                            'form-control' +
                                                            (isPassCompareError
                                                                ? ' is-invalid'
                                                                : '')
                                                        }
                                                        id='confirmPass'
                                                        value={confirmPass}
                                                        onChange={(e) =>
                                                            setConfirmPass(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <div className='invalid-feedback'>
                                                        {passError}
                                                    </div>
                                                </div>
                                                <div className='d-grid mb-4'>
                                                    <button
                                                        className='btn btn-main'
                                                        onClick={
                                                            handleChangePassword
                                                        }
                                                        type='button'
                                                    >
                                                        Сохранить пароль и войти
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <>
                                                {!isSendCode ? (
                                                    <>
                                                        <div className='text-gray w-500 mb-4'>
                                                            Укажите номер
                                                            телефона для
                                                            получения пароля
                                                        </div>
                                                        <div className='form-group mb-4'>
                                                            <label
                                                                htmlFor='login'
                                                                className='form-label'
                                                            >
                                                                Номер телефона
                                                            </label>
                                                            <InputMask
                                                                mask='+7(999)999-99-99'
                                                                className='form-control'
                                                                id='login'
                                                                value={login}
                                                                onChange={(e) =>
                                                                    setLogin(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder='+7(___)___-__-__'
                                                                disabled={
                                                                    isSendCode
                                                                }
                                                            />
                                                        </div>
                                                        <div className='d-grid mb-4'>
                                                            <button
                                                                className='btn btn-main'
                                                                onClick={
                                                                    handleGetCode
                                                                }
                                                            >
                                                                Получить код по
                                                                СМС
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className='text-gray w-500 mb-4'>
                                                            Введите код из СМС
                                                        </div>
                                                        <div className='form-group mb-4'>
                                                            <label
                                                                htmlFor='phone-code'
                                                                className='form-label'
                                                            >
                                                                Код из СМС
                                                            </label>
                                                            <InputMask
                                                                mask='999-999'
                                                                className='form-control'
                                                                id='phone-code'
                                                                value={
                                                                    phoneCode
                                                                }
                                                                onChange={(e) =>
                                                                    setPhoneCode(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder='___-___'
                                                                disabled={
                                                                    isAuthorize
                                                                }
                                                            />
                                                        </div>
                                                        <div className='d-grid mb-4'>
                                                            <button
                                                                className='btn btn-main'
                                                                onClick={
                                                                    handelConfirmCode
                                                                }
                                                                disabled={
                                                                    isAuthorize
                                                                }
                                                            >
                                                                Подтвердить
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </>
                                        )}
                                        <div className='text-center text-gray w-500'>
                                            Уже зарегистрированны?{' '}
                                            <Link
                                                to={`/login`}
                                                className='text-gray-main w-700 text-decoration-none'
                                            >
                                                Войти
                                            </Link>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex justify-content-between about-company-block'>
                                        <div>© Связь-Холдинг, 2023</div>
                                        <div>
                                            <a
                                                href='mailto:hello@govorite.ru'
                                                className='text-decoration-none text-gray'
                                            >
                                                <i className='far fa-envelope pe-2' />
                                                hello@govorite.ru
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;
