import { useEffect, useState } from 'react';

const TextArea = (props) => {
    const {
        value,
        placeholder,
        valueChanged,
        classes = '',
        changedEditStatus,
    } = props;
    const [text, setText] = useState();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setText(value);
    }, [value]);

    useEffect(() => {
        setText(changedEditStatus(isEdit));
    }, [isEdit]);

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.code === 'Escape') {
            setText(value);
            setIsEdit(false);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if (text !== value) {
            console.log('commit');
            valueChanged(text);
        } else {
            console.log('no commit');
        }
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <textarea
                    className={'form-control ' + classes}
                    placeholder={placeholder}
                    value={text}
                    onChange={(v) => setText(v.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={commitChanges}
                    rows='12'
                    autoFocus
                />
            ) : (
                <>
                    {value ? (
                        <div
                            className={'text-input-hover ' + classes}
                            onClick={() => setIsEdit(true)}
                        >
                            {value}
                        </div>
                    ) : (
                        <div
                            className={
                                'text-input-hover text-input-defalt ' + classes
                            }
                            onClick={() => setIsEdit(true)}
                        >
                            Пусто
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TextArea;
