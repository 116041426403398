import { Link, useLocation } from 'react-router-dom';

const MenuItemWithSub = (props) => {
    const { to, icon, title } = props;
    const location = useLocation();

    const checkIsActive = () => {
        const current = location.pathname.split(/[?#]/)[0];
        if (!current || !to) {
            return false;
        }

        if (current === to) {
            return true;
        }

        if (current.indexOf(to) > -1) {
            return true;
        }

        return false;
    };

    return (
        <>
            <li
                className={'nav-item' + (checkIsActive() ? ' active' : '')}
                data-kt-menu-trigger='click'
            >
                <span className='nav-link d-flex align-items-center'>
                    {icon}
                    <div className='menu-title d-inline-block'>{title}</div>
                </span>
                <ul className='list-unstyled'>{props.children}</ul>
            </li>
        </>
    );
};

export default MenuItemWithSub;
