import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkPhoneToContactModal from '../modals/LinkPhoneToContactModal';

const CallCard = (props) => {
    const { entity, callback } = props;
    const navigate = useNavigate();

    const companyUrl = `/account/${entity?.accountId}/companies/${entity?.companyId}`;
    const contactUrl = `/account/${entity?.accountId}/contacts/${entity?.contactId}`;

    const [linkContactModal, setLinkContactModal] = useState(false);

    const defineContact = () => {
        setLinkContactModal(true);
    };
    const openCompany = () => {
        console.log(companyUrl);
        navigate(companyUrl);
    };
    const CompanyLink = () => {
        if (entity.companyName) {
            return <span onClick={openCompany}>{entity.companyName}</span>;
        } else {
            return <span onClick={defineContact}>Не определен</span>;
        }
    };
    const openContact = () => {
        console.log(contactUrl);
        navigate(contactUrl);
    };
    const ContactLink = () => {
        if (entity.contactName) {
            return <span onClick={openContact}>{entity.contactName}</span>;
        } else {
            return <span onClick={defineContact}>Уточните контакт</span>;
        }
    };

    const linkPhoneCallback = (data) => {
        setLinkContactModal(false);
        callback(data);
    };

    return (
        <>
            <LinkPhoneToContactModal
                isOpen={linkContactModal}
                closeHandle={() => setLinkContactModal(false)}
                phone={entity.formattedPhone}
                callId={entity.callId}
                okHandle={(data) => linkPhoneCallback(data)}
            />
            <div className='calls-card' key={entity.id}>
                <div className='company'>
                    <div className='img-call'>
                        <img
                            src='/images/icons/phone-incoming.svg'
                            width={16}
                            className='icon-nav opacity-75'
                        />
                    </div>
                    <div>
                        <div className='duration'>00:00</div>
                        <div className='title cursor-pointer'>
                            <CompanyLink />
                        </div>
                    </div>
                </div>
                <div className='contact'>
                    <div className='contact-row'>
                        <img
                            src='/images/icons/user.svg'
                            width={16}
                            className='icon-white opacity-95'
                        />
                        <span className='title cursor-pointer'>
                            <ContactLink />
                        </span>
                    </div>
                    <div className='phone-row'>
                        <span>{entity.formattedPhone}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CallCard;
