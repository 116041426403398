import SkeletonTable from '../../components/skeleton/SkeletonTable';

const ReportTableMissed = (props) => {
    const { data, isLoading } = props;
    return (
        <SkeletonTable isLoading={isLoading}>
            <table className='table table-sm content-table table-bordered'>
                <thead>
                    <tr>
                        <th scope='col'>Период</th>
                        <th scope='col'>Всего</th>
                        <th scope='col'>Не перезвонили</th>
                        <th scope='col'>Перезвонили</th>
                        <th scope='col'>Клиент перезвонил</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.period}</td>
                                <td>{item.incomingNotProcessed}</td>
                                <td>{item.incomingNotRecalled}</td>
                                <td>{item.incomingRecalled}</td>
                                <td>{item.incomingRecalledByClient}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </SkeletonTable>
    );
};

export default ReportTableMissed;
