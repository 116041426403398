import { useRef, useState, useEffect } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { selectSecurity, setCredentials, setSecurity } from './authSlice';
import { useLoginMutation, useSecurityMutation } from './authApiSlice';
import InputMask from 'react-input-mask';

const Login = () => {
    const token = localStorage.getItem('access_token');
    const errRef = useRef();
    const security = useSelector(selectSecurity);
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [login, { isLoading }] = useLoginMutation();
    const [getSecurity] = useSecurityMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            if (token) {
                const securityResult = await getSecurity().unwrap();
                dispatch(setSecurity(securityResult.result));
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        try {
            const userData = await login({
                login: user,
                password: pwd,
            }).unwrap();
            dispatch(setCredentials({ ...userData, user }));
            const securityResult = await getSecurity().unwrap();
            console.log('securityResult', securityResult);
            dispatch(setSecurity(securityResult.result));
            setUser('');
            setPwd('');
            navigate(
                `/account/${securityResult.result.accounts[0]?.id}/overview`
            );
        } catch (err) {
            if (!err?.originalStatus) {
                setErrMsg('No Server Response');
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    };

    const handleUserInput = (e) => setUser(e.target.value);

    const handlePwdInput = (e) => setPwd(e.target.value);

    const content = isLoading ? (
        <h1>Загрузка...</h1>
    ) : (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6 login-left-block'>
                    <div className='login-left-content'>
                        <img
                            src='/images/shLogoFull.svg'
                            className='img-fluid'
                        />
                        <div>
                            <h2 className='w-600'>
                                Ассистент Виртуальной АТС
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 vh-100 py-2'>
                    <div className='card login-card'>
                        <div className='card-body p-0 d-flex justify-content-between flex-column'>
                            <div></div>
                            <div className='row'>
                                <div className='col-md-8 offset-md-2 d-flex justify-content-center flex-column'>
                                    <section className='login pe-4'>
                                        <h2 className='mb-2 w-700'>Вход</h2>
                                        <div className='text-gray w-500 mb-4'>
                                            Добро пожаловать в Ассистент ВАТС
                                        </div>
                                        <div className='mb-3'>
                                            <label
                                                htmlFor='mobilePhone'
                                                className='form-label'
                                            >
                                                Номер телефона
                                            </label>
                                            <InputMask
                                                mask='+7(999)999-99-99'
                                                className='form-control'
                                                id='mobilePhone'
                                                value={user}
                                                onChange={handleUserInput}
                                                placeholder='+7(___)___-__-__'
                                            />
                                        </div>
                                        <div className='mb-4 position-relative'>
                                            <div
                                                className='position-absolute show-password cursor-pointer'
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <img
                                                        src='/images/icons/eye.svg'
                                                        width='20px'
                                                        height='20px'
                                                    />
                                                ) : (
                                                    <img
                                                        src='/images/icons/eye-off.svg'
                                                        width='20px'
                                                        height='20px'
                                                    />
                                                )}
                                            </div>
                                            <label
                                                htmlFor='password'
                                                className='form-label'
                                            >
                                                Пароль
                                            </label>
                                            <input
                                                className='form-control'
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                id='password'
                                                onChange={handlePwdInput}
                                                value={pwd}
                                                required
                                            />
                                            <div className='forgot-password mt-1'>
                                                <Link to={`/forgotPassword`}>
                                                    Забыли пароль?
                                                </Link>
                                            </div>
                                        </div>
                                        <p
                                            ref={errRef}
                                            className={
                                                errMsg ? 'errmsg' : 'offscreen'
                                            }
                                            aria-live='assertive'
                                        >
                                            {errMsg}
                                        </p>
                                        <div className='d-grid gap-2 mb-4'>
                                            <button
                                                onClick={handleSubmit}
                                                className='btn btn-main'
                                            >
                                                Войти
                                            </button>
                                        </div>
                                        <div className='text-center text-gray w-500'>
                                            Нет учётной записи?{' '}
                                            <Link
                                                to={`/registration`}
                                                className='text-gray-main w-700 text-decoration-none'
                                            >
                                                Зарегистрироваться
                                            </Link>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex justify-content-between about-company-block'>
                                        <div>© Связь-Холдинг, 2023</div>
                                        <div>
                                            <a
                                                href='mailto:hello@govorite.ru'
                                                className='text-decoration-none text-gray'
                                            >
                                                <i className='far fa-envelope pe-2' />
                                                hello@govorite.ru
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return token && security ? (
        <Navigate
            to={`/account/${security?.accounts[0]?.id}/overview`}
            replace
        />
    ) : (
        content
    );
};

export default Login;
