import { Link, useLocation } from 'react-router-dom';
import MenuAdmin from './MenuAdmin';
import MenuMain from './MenuMain';
import MenuPersonal from './MenuPersonal';

const MenuDefault = (props) => {
    const { mode } = props;
    const location = useLocation();
    console.log(location.pathname);
    const MenuBlock = () => {
        if (location.pathname.startsWith('/administrator/')) {
            return <MenuAdmin />;
        } else if (location.pathname.startsWith('/personal/')) {
            return <MenuPersonal />;
        } else {
            return <MenuMain />;
        }
    };

    return (
        <>
            <MenuBlock />
        </>
    );
};

export default MenuDefault;
