import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    usePatchAccountMutation,
    useCreateAccountMutation,
    useLazyGetAccountByIdQuery,
} from '../../../../app/api/accountsApiSlice';
import PageTitle from '../../../../components/layout/PageTitle';
import SwitchInput from '../../../../components/editors/SwitchInput';
import TextInput from '../../../../components/editors/TextInput';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../../../features/auth/authSlice';

const AccountCardPage = () => {
    let params = useParams();
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const entityId = params.accountId;
    const [patchAccount, { r }] = usePatchAccountMutation();
    const [createAccount] = useCreateAccountMutation();
    const [getAccount, { data }] = useLazyGetAccountByIdQuery();

    function patch(obj) {
        patchAccount({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    useEffect(() => {
        if (entityId > 0) {
            getAccount(entityId);
        }
    }, [entityId]);


    const saveTitle = async (name) => {
        if (entityId === '0') {
            const entity = {
                id: entityId ?? 0,
                name,
            };
            let r = await createAccount(entity);
            if (r?.data?.result?.id > 0) {
                navigate(
                    `/administrator/accounts/${r?.data?.result?.id}`
                );
            }
        } else {
            patch({ path: 'name', value: name });
        }
    };
    return (
        <>
            <PageTitle
                title={data?.result?.name}
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                placeholder='Новый аккаунт'
                isNew={entityId === '0'}
                valueChanged={(name) => saveTitle(name)}
                disabled={!security?.isRoot} 
            />
            <div style={{ width: 600 }} className='entity-card'>
                <div className='row mt-4'>
                    <div className='col property-header'>Информация</div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/file-text.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Аккаунт ВАТС (ID)</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.vatsAccountId}
                            valueChanged={(v) =>
                                patch({ path: 'vatsAccountId', value: v })
                            }
                            disabled={!security?.isRoot}
                        />
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/archive.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Архивный</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <SwitchInput
                            value={data?.result?.isArchive}
                            valueChanged={(v) =>
                                patch({ path: 'isArchive', value: v })
                            }
                            disabled={!security?.isRoot}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountCardPage;
