import PageTitle from '../../components/layout/PageTitle';
import DepartmentsList from './DepartmentsList'

const DepartmentsPage = (props) => {
    return (
        <>
            <PageTitle
                title='Отделы'
                icon={
                    <img
                        src='/images/icons/grid.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
             />
             <DepartmentsList />
        </>
    );
};

export default DepartmentsPage;