import { useEffect, useState } from 'react';

const PageTitle = (props) => {
    const {
        icon,
        title,
        isNew,
        valueChanged,
        placeholder,
        disabled = false,
        maxLength = 0,
    } = props;
    const [text, setText] = useState();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsEdit(isNew);
        setText(title);
    }, [isNew, title]);

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commitChanges();
        } else if (event.code === 'Escape') {
            setText(title);
            setIsEdit(false);
        }
    };

    const setEnable = () => {
        if (!disabled) {
            setIsEdit(true);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if (text !== title) {
            console.log('commit');
            valueChanged(text);
        } else {
            if (!text && isNew) {
                console.log(`reset to placeholder -${text}-`);
                valueChanged(placeholder);
            }
        }
        setIsEdit(false);
    };

    const changeText = (e) => {
        setText(e.target.value);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '14px',
                position: 'relative',
            }}
        >
            {icon}
            {isEdit ? (
                <>
                    <input
                        autoFocus
                        type='text'
                        className='form-control page-title'
                        placeholder={placeholder}
                        value={text}
                        onChange={changeText}
                        onKeyDown={handleKeyDown}
                        onBlur={commitChanges}
                        maxLength={maxLength === 0 ? 255 : maxLength}
                    />
                    <span className='inpit-letters-count text-muted'>
                        {text?.length ?? 0}/{maxLength}
                    </span>
                </>
            ) : (
                <div className='page-title' onClick={setEnable}>
                    {title}
                </div>
            )}
        </div>
    );
};

export default PageTitle;
