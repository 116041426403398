import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetDepartmentsListQuery } from '../../app/api/departmentsApiSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import FindComponent from '../../components/filters/FindComponent';
import Button from '../../components/Button';
import SortableTableHead from '../../components/SortableTableHead';
import SliceIcon from '../../components/SliceIcon';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../features/auth/authSlice';
import Paginator from '../../components/Paginator';

const DepartmentsList = () => {
    let params = useParams();
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const accountId = params.accountId;
    const [showArchive, setShowArchive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState('name');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [getList, { data, isLoading }] = useLazyGetDepartmentsListQuery();

    const [securityContext, setSecurityContext] = useState(null);
    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                sort,
                showArchive,
                pageSize,
                pageIndex,
            });
        }
        loadDataAsync();
    }, [accountId, searchString, showArchive, sort, pageSize, pageIndex]);

    const openEntityCard = (entityId) => {
        navigate(`/account/${accountId}/departments/${entityId}`);
    };

    return (
        <>
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' + (!showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(false)}
                    >
                        <SliceIcon value={!showArchive} filename='loader' />
                        Все
                    </div>
                    <div
                        className={
                            'slice ' + (showArchive ? 'active-slice' : '')
                        }
                        onClick={() => setShowArchive(true)}
                    >
                        <SliceIcon value={showArchive} filename='archive' />
                        Показать архивные
                    </div>
                </div>
                <div>
                    <Button
                        label='Добавить отдел'
                        btnColor='blue'
                        handleClick={() =>
                            navigate(`/account/${accountId}/departments/0`)
                        }
                        disabled={
                            !(security?.isRoot ?? false) &&
                            securityContext?.role !== 'admin'
                        }
                    />
                </div>
            </div>
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
            </div>
            <div className='row'>
                <div className='col'>
                    <SkeletonTable isLoading={isLoading}>
                        <table className='table table-sm content-table'>
                            <thead>
                                <tr>
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Название'
                                        field='name'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                    <th scope='col' className='ps-3'>
                                        Информация об отделе
                                    </th>
                                    <th scope='col' className='ps-3'>
                                        Дата создания
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => {
                                    return (
                                        <tr
                                            className={
                                                'cursor-pointer' +
                                                (item.isArchive
                                                    ? ' archive-row'
                                                    : '')
                                            }
                                            key={item.id}
                                            onClick={() =>
                                                openEntityCard(item.id)
                                            }
                                        >
                                            <td className='ps-3 left-cell text-medium'>
                                                {item.name}
                                            </td>
                                            <td className='ps-3 cell text-normal'>
                                                {item.description}
                                            </td>
                                            <td className='ps-3 right-cell  text-normal'>
                                                {item.createdAt}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
            <div className='card-body'>
                <Paginator
                    onPageChange={(e) => setPageIndex(e)}
                    onPageSizeChange={(e) => setPageSize(e)}
                    pageCount={data?.paginator?.totalPages ?? 0}
                    pageSizeArray={[50, 100, 500]}
                    pageSize={pageSize}
                    countCurrent={data?.result?.length ?? 0}
                    countTotal={data?.paginator?.totalCount ?? 0}
                />
            </div>
        </>
    );
};

export default DepartmentsList;
