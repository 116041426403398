import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    useLazyGetByIdQuery,
    usePatchMutation,
    useCreateMutation,
} from '../../../app/api/companiesApiSlice';
import PageTitle from '../../../components/layout/PageTitle';
import SliceIcon from '../../../components/SliceIcon';
import CompanyInfoBlock from './CompanyInfoBlock';
import TasksList from '../../tasks/TasksList';
import CallsList from '../../calls/CallsList';
import Button from '../../../components/Button';
import ContactsList from '../../contacts/ContactsList';

const CompanyCardPage = () => {
    let params = useParams();
    const navigate = useNavigate();

    const accountId = params.accountId;
    const entityId = params.companyId;

    const [patchCompany, { r }] = usePatchMutation();
    const [createCompany] = useCreateMutation();
    const [getCompany, { data }] = useLazyGetByIdQuery();

    const [mode, setMode] = useState('contacts');

    function patch(obj) {
        patchCompany({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    const saveTitle = async (name) => {
        if (entityId === '0') {
            const entity = {
                id: entityId ?? 0,
                account: { id: accountId },
                name,
            };
            let r = await createCompany(entity);
            if (r?.data?.result?.id > 0) {
                navigate(
                    `/account/${accountId}/companies/${r?.data?.result?.id}`
                );
            }
        } else {
            patch({ path: 'name', value: name });
        }
    };

    useEffect(() => {
        if (entityId > 0) {
            getCompany(entityId);
        }
    }, [entityId]);

    const currentTab = () => {
        let tab = null;
        switch (mode) {
            case 'info':
                tab = <CompanyInfoBlock companyId={entityId} />;
                break;
            case 'contacts':
                tab = (
                    <ContactsList
                        preCompanyId={entityId}
                        excludeCols={['company']}
                        canCreate={false}
                        fastFilters={false}
                    />
                );
                break;
            case 'tasks':
                tab = (
                    <TasksList
                        fastFilters={false}
                        preCompanyId={entityId}
                        excludeCols={['company']}
                        page='company'
                    />
                );
                break;
            case 'calls':
                tab = (
                    <CallsList
                        fastFilters={false}
                        preCompanyId={entityId}
                        preSlice='all'
                    />
                );
                break;

            default:
                break;
        }
        return tab;
    };

    return (
        <>
            <PageTitle
                title={data?.result?.name}
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                placeholder='Новая компания'
                isNew={entityId === '0'}
                valueChanged={(name) => saveTitle(name)}
                maxLength={50}
            />
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' +
                            (mode === 'contacts' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('contacts')}
                    >
                        <SliceIcon value={mode === 'user'} filename='user' />
                        Контакты
                    </div>
                    <div
                        className={
                            'slice ' + (mode === 'info' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('info')}
                    >
                        <SliceIcon value={mode === 'info'} filename='info' />
                        Информация
                    </div>
                    <div
                        className={
                            'slice ' + (mode === 'tasks' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('tasks')}
                    >
                        <SliceIcon value={mode === 'tasks'} filename='target' />
                        Задачи
                    </div>
                    <div
                        className={
                            'slice ' + (mode === 'calls' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('calls')}
                    >
                        <SliceIcon
                            value={mode === 'calls'}
                            filename='phone-call'
                        />
                        Вызовы
                    </div>
                </div>
                <div>
                    {mode === 'tasks' ? (
                        <Button
                            label='Добавить задачу'
                            btnColor='blue'
                            handleClick={() =>
                                navigate({
                                    pathname: `/account/${accountId}/companies/${entityId}/tasks/0`,
                                    search: `?companyId=${entityId}`,
                                })
                            }
                        />
                    ) : null}
                    {mode === 'contacts' ? (
                        <Button
                            label='Добавить контакт'
                            btnColor='blue'
                            handleClick={() =>
                                navigate({
                                    pathname: `/account/${accountId}/contacts/0`,
                                    search: `?companyId=${entityId}`,
                                })
                            }
                        />
                    ) : null}
                </div>
            </div>
            {currentTab()}
            <Outlet />
        </>
    );
};

export default CompanyCardPage;
