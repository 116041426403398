import { useEffect, useRef, useState } from 'react';
import { useLazyGetNotificationsQuery } from '../../app/api/notificationsApiSlice';
import { useLocation } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../features/auth/authSlice';
import CallCard from './CallCard';

const CallsPanel = (props) => {
    const { accountId } = props;
    const location = useLocation();
    const security = useSelector(selectSecurity);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [messages, setMessages] = useState([]);
    const [operatorId, setOperatorId] = useState(null);

    const [connection, setConnection] = useState(null);
    const lastMessages = useRef(null);
    lastMessages.current = messages;
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/calls`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);
    useEffect(() => {
        if (connection && operatorId) {
            connection
                .start()
                .then((result) => {
                    console.log('Connected!');
                    connection
                        .invoke('Join', `calls_a${accountId}_e${operatorId}`) //JoinGroup is C# method name
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on('ReceiveMessage', (message) => {
                        const actualMessages = [...lastMessages.current];
                        console.log(message);
                        if (message.show) {
                            actualMessages.push(message);
                            setMessages(actualMessages);
                            console.log('add msg');
                        } else {
                            let filtered = actualMessages.filter(
                                (m) => m.callId !== message.callId
                            );
                            console.log(filtered);
                            setMessages(filtered);
                            console.log('del msg');
                        }
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection, operatorId]);

    useEffect(() => {
        async function fetchData() {
            if (security) {
                const empId = security.accounts.find(
                    (x) => parseInt(x.id) == parseInt(accountId)
                ).employeeId;
                setOperatorId(empId);
            }
        }
        fetchData();
        //console.log(security);
    }, [security]);

    const updateMessage = (data, callId) => {
        const updatedMessages = [...messages];
        const msg = updatedMessages.find((x) => x.callId === callId);
        msg.companyId = data.company.id;
        msg.companyName = data.company.title;
        msg.contactId = data.contact.id;
        msg.contactName = data.contact.title;
        setMessages(updatedMessages);
    };

    return (
        <>
            <div className='calls-panel'>
                {messages.map((item) => {
                    return (
                        <CallCard
                            entity={item}
                            key={item.callId}
                            callback={(data) =>
                                updateMessage(data, item.callId)
                            }
                        />
                    );
                })}
            </div>
        </>
    );
};

export default CallsPanel;
