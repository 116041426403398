import FilterIcon from '../../components/FilterIcon';

const DropDownInput = (props) => {
    const { value, clickHandler, inputClass } = props;

    return (
        <div className="cx-dropdown position-relative">
            <div
                className={
                    'cursor-pointer cx-dropdown-input d-flex justify-content-between ' +
                    inputClass
                }
                onClick={() => clickHandler()}
            >
                {value}
                <img src="/images/icons/chevron-down.svg" />
            </div>
            {props.children}
        </div>
    );
};

export default DropDownInput;
