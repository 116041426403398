import { useParams } from 'react-router-dom';
import {
    useCreateContactTagMutation,
    useCreateEntryMutation,
    useDeleteContactTagMutation,
    useDeleteEntryMutation,
    useGetContactByIdQuery,
    usePatchContactMutation,
    useUpdateEntryMutation,
} from '../../../app/api/contactsApiSlice';
import { useCreateCallbackMutation } from '../../../app/api/callbackApiSlice';
import SimpleDropdown from '../../../components/editors/SimpleDropdown';
import TextInput from '../../../components/editors/TextInput';
import DateInput from '../../../components/editors/DateInput';
import TagEditor from '../../../components/editors/TagEditor';
import SwitchInput from '../../../components/editors/SwitchInput';
import AddEntry from '../../../components/editors/AddEntry';

const ContactInfoBlock = (props) => {
    let params = useParams();
    const { accountId } = params;
    const { contactId, preCompany } = props;
    const { data, isLoading } = useGetContactByIdQuery(contactId);
    const [patchContact, { r }] = usePatchContactMutation();
    const [createTag] = useCreateContactTagMutation();
    const [deleteTag] = useDeleteContactTagMutation();
    const [createEntry] = useCreateEntryMutation();
    const [updateEntry] = useUpdateEntryMutation();
    const [deleteEntry] = useDeleteEntryMutation();
    const [callPhone] = useCreateCallbackMutation();

    const patch = (obj) => {
        patchContact({
            id: contactId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    };

    const addTag = (value) => {
        createTag({ contactId, tagId: value.id });
    };

    const removeTag = (value) => {
        deleteTag({ contactId, tagId: value });
    };

    const changeEntrie = (id, type, value, ext) => {
        if (value === '') {
            deleteEntry(id);
        } else {
            updateEntry({ id, type, value, ext });
        }
    };

    const addEntry = (phone, type, ext) => {
        createEntry({ contactId, entry: { type, value: phone, ext } });
    };

    const call = (phone) => {
        callPhone({
            phone: phone,
            accountId: accountId,
            contactId: contactId,
        });
    };

    return (
        <div className='row entity-card'>
            <div className='col-md-6'>
                {data?.result?.entries
                    ?.filter(
                        (x) => x.type === 'mobile' || x.type === 'work_phone'
                    )
                    .map((item) => {
                        let labelIcon = '';
                        let labelText = '';
                        switch (item.type) {
                            case 'mobile':
                                labelIcon = 'smartphone.svg';
                                labelText = 'Мобильный';
                                break;
                            case 'work_phone':
                                labelIcon = 'phone.svg';
                                labelText = 'Рабочий';
                                break;
                            default:
                                break;
                        }
                        return (
                            <div className='row mt-4' key={item.id}>
                                <div className='col-md-4 property property-title'>
                                    <img
                                        src={'/images/icons/' + labelIcon}
                                        width={16}
                                        className='icon-gray'
                                    />
                                    <span>{labelText}</span>
                                </div>
                                <div className='col-md-8 property property-value hover-show-parent'>
                                    <TextInput
                                        placeholder='Пусто'
                                        value={item.value}
                                        valueChanged={(value) =>
                                            changeEntrie(
                                                item.id,
                                                item.type,
                                                value
                                            )
                                        }
                                        classes='max-width-150'
                                    />
                                    <img
                                        src='/images/icons/phone.svg'
                                        width={16}
                                        className='icon-gray hover-show cursor-pointer'
                                        onClick={() => call(item.value)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                <AddEntry
                    valueChanged={(text, type, ext) =>
                        addEntry(text, type, ext)
                    }
                />
                {data?.result?.entries
                    ?.filter((x) => x.type === 'email')
                    .map((item) => (
                        <div className='row mt-4' key={item.id}>
                            <div className='col-md-4 property property-title'>
                                <img
                                    src='/images/icons/mail.svg'
                                    width={16}
                                    className='icon-gray'
                                />
                                <span>Email</span>
                            </div>
                            <div className='col-md-8 property property-value hover-show-parent'>
                                <TextInput
                                    placeholder='Пусто'
                                    value={item.value}
                                    valueChanged={(value) =>
                                        changeEntrie(item.id, item.type, value)
                                    }
                                    classes='span-flex-0'
                                />
                                <a href={'mailto:' + item.value}>
                                    <img
                                        src='/images/icons/mail.svg'
                                        width={16}
                                        className='icon-gray hover-show cursor-pointer'
                                    />
                                </a>
                            </div>
                        </div>
                    ))}
                <AddEntry
                    valueChanged={(text, type, ext) =>
                        addEntry(text, type, ext)
                    }
                    type='email'
                />
            </div>
            <div className='col-md-6'>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/user-position.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Должность</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.occupation}
                            valueChanged={(v) =>
                                patch({ path: 'occupation', value: v })
                            }
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/folder.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Компания</span>
                    </div>
                    <div className='col-md-8'>
                        <div className='company-name'>
                            <img
                                src='/images/icons/file.svg'
                                width='18px'
                                className='me-1 icon-medium-dark'
                            />
                            {contactId === '0'
                                ? preCompany.title
                                : data?.result.company?.title}
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/grid.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Отдел</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.department}
                            valueChanged={(v) =>
                                patch({ path: 'department', value: v })
                            }
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/file-text.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Пол</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <SimpleDropdown
                            value={{
                                id: data?.result?.sex,
                                title:
                                    data?.result?.sex === null
                                        ? 'Пусто'
                                        : data?.result?.sex
                                        ? 'Мужской'
                                        : 'Женский',
                            }}
                            selectCallback={(v) =>
                                patch({ path: 'sex', value: v })
                            }
                            list={[
                                { id: true, name: 'Мужской' },
                                { id: false, name: 'Женский' },
                            ]}
                            withSearch={false}
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/calendar.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Дата рождения</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <DateInput
                            value={data?.result?.birthday}
                            valueChanged={(v) =>
                                patch({ path: 'birthday', value: new Date(v) })
                            }
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/tag.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Теги</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <TagEditor
                            list={data?.result?.tags}
                            selectCallback={(value) => addTag(value)}
                            deleteCallback={(value) => removeTag(value)}
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/globe.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Сайт</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.webSite}
                            valueChanged={(v) =>
                                patch({ path: 'webSite', value: v })
                            }
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/message-circle.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Комментарий</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.comments}
                            valueChanged={(v) =>
                                patch({ path: 'comments', value: v })
                            }
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4 property property-title'>
                        <img
                            src='/images/icons/archive.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Архивный</span>
                    </div>
                    <div className='col-md-8 property property-value'>
                        <SwitchInput
                            value={data?.result?.isArchive}
                            valueChanged={(v) =>
                                patch({ path: 'isArchive', value: v })
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfoBlock;
