import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterArrayObjectHeader from './FilterArrayObjectHeader';
import SearchableList from './SearchableList';

import { useLazyGetTagsListQuery } from '../../app/api/tagsApiSlice';

const TagFilter = (props) => {
    const { value, selectCallback, type } = props;
    let params = useParams();

    const accountId = params.accountId;

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [getList, { data }] = useLazyGetTagsListQuery();

    useEffect(() => {
        setSelectedValue(value ?? []);
    }, [value]);

    useEffect(() => {
        selectCallback(selectedValue);
    }, [selectedValue]);

    const selectValue = (value) => {
        let exist = selectedValue.find((x) => x.id === value.id);
        if (exist === undefined) {
            setSelectedValue([...selectedValue, value]);
        }
    };

    const loadDataAsync = async (search) => {
        await getList({
            accountId,
            search,
            sort: 'name',
            type,
            showArchive: false,
            pageSize: 50,
        });
    };

    const removeItem = (id) => {
        setSelectedValue(selectedValue.filter((x) => x.id != id));
    };

    return (
        <>
            <FilterArrayObjectHeader
                value={selectedValue}
                title='Теги'
                icon='tag'
                clickHandler={() => setIsEdit(true)}
            >
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-300 height-400 filters-dropdown'
                        }
                    >
                        <>
                            <div className='filters-tag-panel'>
                                <div className='filters-tag-list'>
                                    {selectedValue?.map((x) => {
                                        return (
                                            <div
                                                key={x.id}
                                                className='filters-tag-list-item'
                                            >
                                                <span>{x.name}</span>
                                                <img
                                                    src='/images/icons/x.svg'
                                                    width={12}
                                                    className='icon-main cursor-pointer'
                                                    onClick={() =>
                                                        removeItem(x.id)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <SearchableList
                                value={value}
                                list={data?.result}
                                searchCallback={(search) =>
                                    loadDataAsync(search)
                                }
                                selectCallback={(value) => selectValue(value)}
                                hideEmpty={true}
                            />
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </FilterArrayObjectHeader>
        </>
    );
};

export default TagFilter;
