import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './features/auth/Login';
import Logout from './features/auth/Logout';
import RequireAuth from './features/auth/RequireAuth';
import LayoutMaster from './components/layout/LayoutMaster';
import Registration from './features/auth/Registration';
import CompaniesPage from './pages/companies/CompaniesPage';
import CompanyCardPage from './pages/companies/card/CompanyCardPage';
import ContactsPage from './pages/contacts/ContactsPage';
import ContactCardPage from './pages/contacts/card/ContactCardPage';
import CallsPage from './pages/calls/CallsPage';
import EmployeesPage from './pages/employees/EmployeesPage';
import EmployeeCardPage from './pages/employees/card/EmployeeCardPage';
import DepartmentsPage from './pages/departments/DepartmentsPage';
import DepartmentCardPage from './pages/departments/card/DepartmentCardPage';
import TasksPage from './pages/tasks/TasksPage';
import TaskModalPage from './pages/tasks/taskCard/TaskModalPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import TagsPage from './pages/tags/TagsPage';
import HoldingsPage from './pages/holdings/HoldingsPage';
import AccountsPage from './pages/admins/accounts/AccountsPage';
import AccountCardPage from './pages/admins/accounts/card/AccountCardPage';
import AdminDashboardPage from './pages/admins/dashboard/AdminDashboardPage';
import { useDispatch } from 'react-redux';
import { setIsAuthorize, setSecurity } from './features/auth/authSlice';
import { useEffect } from 'react';
import { useSecurityMutation } from './features/auth/authApiSlice';
import PersonalAccountsPage from './pages/personal/accounts/PersonalAccountsPage';
import ReportsPage from './pages/reports/ReportsPage';

function App() {
    const dispatch = useDispatch();
    const [getSecurity] = useSecurityMutation();

    useEffect(() => {
        async function firstLoadApp() {
            let isAuth = false;
            if (localStorage.getItem('access_token') !== null) {
                isAuth = true;
                const securityResult = await getSecurity().unwrap();
                dispatch(setSecurity(securityResult.result));
            }
            dispatch(setIsAuthorize(isAuth));
        }
        firstLoadApp();
    }, []);

    return (
        <Routes>
            <Route path='/'>
                {/* public routes */}
                <Route index element={<Navigate to='login' replace />} />
                <Route path='login' element={<Login />} />
                <Route path='logout' element={<Logout />} />
                <Route
                    path='forgotpassword'
                    element={<Registration isForgot />}
                />
                <Route path='registration' element={<Registration />} />

                {/* protected routes */}
                <Route element={<RequireAuth />}>
                    <Route
                        path='account/:accountId/overview/*'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Обзор',
                                        icon: 'home.svg',
                                        to: `/account/:accountId/overview`,
                                    },
                                ]}
                            >
                                <DashboardPage />
                            </LayoutMaster>
                        }
                    >
                        <Route
                            path='tasks/:taskId'
                            element={<TaskModalPage page='overview' />}
                        />
                    </Route>
                    <Route
                        exact
                        path='account/:accountId/companies'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Компании',
                                        icon: 'folder.svg',
                                        to: `/account/:accountId/companies`,
                                    },
                                ]}
                            >
                                <CompaniesPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/companies/:companyId'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Компании',
                                        icon: 'folder.svg',
                                        to: `/account/:accountId/companies`,
                                    },
                                ]}
                            >
                                <CompanyCardPage />
                            </LayoutMaster>
                        }
                    >
                        <Route
                            path='tasks/:taskId'
                            element={<TaskModalPage page='company' />}
                        />
                    </Route>
                    <Route
                        exact
                        path='account/:accountId/contacts'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Контакты',
                                        icon: 'user.svg',
                                        to: `/account/:accountId/contacts`,
                                    },
                                ]}
                            >
                                <ContactsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/contacts/:contactId/*'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Контакты',
                                        icon: 'user.svg',
                                        to: `/account/:accountId/contacts`,
                                    },
                                ]}
                            >
                                <ContactCardPage />
                            </LayoutMaster>
                        }
                    >
                        <Route
                            path='tasks/:taskId'
                            element={<TaskModalPage page='contact' />}
                        />
                    </Route>
                    <Route
                        path='account/:accountId/calls/'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Вызовы',
                                        icon: 'phone-call.svg',
                                        to: `/account/:accountId/calls`,
                                    },
                                ]}
                            >
                                <CallsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        exact
                        path='account/:accountId/employees'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/employees`,
                                    },
                                    {
                                        title: 'Сотрудники',
                                        icon: 'user.svg',
                                        to: `/account/:accountId/employees`,
                                    },
                                ]}
                            >
                                <EmployeesPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/employees/:employeeId'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/employees`,
                                    },
                                    {
                                        title: 'Сотрудники',
                                        icon: 'user.svg',
                                        to: `/account/:accountId/employees`,
                                    },
                                ]}
                            >
                                <EmployeeCardPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        exact
                        path='account/:accountId/departments'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/departments`,
                                    },
                                    {
                                        title: 'Отделы',
                                        icon: 'grid.svg',
                                        to: `/account/:accountId/departments`,
                                    },
                                ]}
                            >
                                <DepartmentsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/departments/:departmentId'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/departments`,
                                    },
                                    {
                                        title: 'Отделы',
                                        icon: 'grid.svg',
                                        to: `/account/:accountId/departments`,
                                    },
                                ]}
                            >
                                <DepartmentCardPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/tags'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/tags`,
                                    },
                                    {
                                        title: 'Теги',
                                        icon: 'tag.svg',
                                        to: `/account/:accountId/tags`,
                                    },
                                ]}
                            >
                                <TagsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/holdings'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Администрирование',
                                        icon: 'settings.svg',
                                        to: `/account/:accountId/holdings`,
                                    },
                                    {
                                        title: 'Холдинги',
                                        icon: 'holding.svg',
                                        to: `/account/:accountId/holdings`,
                                    },
                                ]}
                            >
                                <HoldingsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/reports'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Отчёты',
                                        icon: 'file-text.svg',
                                        to: `/account/:accountId/reports`,
                                    },
                                    {
                                        title: 'Звонки',
                                        icon: 'file-text.svg',
                                        to: `/account/:accountId/reports`,
                                    },
                                ]}
                            >
                                <ReportsPage />
                            </LayoutMaster>
                        }
                    />
                    <Route
                        path='account/:accountId/tasks/*'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Задачи',
                                        icon: 'target.svg',
                                        to: `/account/:accountId/tasks`,
                                    },
                                ]}
                            >
                                <TasksPage />
                            </LayoutMaster>
                        }
                    >
                        <Route path=':taskId' element={<TaskModalPage />} />
                    </Route>

                    <Route
                        exact
                        path='administrator/overview/'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Панель администратора',
                                        icon: 'home.svg',
                                        to: `/administrator/overview`,
                                    },
                                ]}
                            >
                                <AdminDashboardPage />
                            </LayoutMaster>
                        }
                    />

                    <Route
                        exact
                        path='administrator/accounts/'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Панель администратора',
                                        icon: 'home.svg',
                                        to: `/administrator/overview`,
                                    },
                                    {
                                        title: 'Аккаунты',
                                        icon: 'folder.svg',
                                        to: `/administrator/accounts`,
                                    },
                                ]}
                            >
                                <AccountsPage />
                            </LayoutMaster>
                        }
                    />

                    <Route
                        exact
                        path='administrator/accounts/:accountId'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Панель администратора',
                                        icon: 'home.svg',
                                        to: `/administrator/overview`,
                                    },
                                    {
                                        title: 'Аккаунты',
                                        icon: 'folder.svg',
                                        to: `/administrator/accounts`,
                                    },
                                ]}
                            >
                                <AccountCardPage />
                            </LayoutMaster>
                        }
                    />

                    <Route
                        exact
                        path='personal/accounts/'
                        element={
                            <LayoutMaster
                                breadcrumbs={[
                                    {
                                        title: 'Панель пользователя',
                                        icon: 'home.svg',
                                        to: `/personal/accounts`,
                                    },
                                ]}
                            >
                                <PersonalAccountsPage />
                            </LayoutMaster>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
