import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyGetLinesListQuery } from '../../app/api/employeesApiSlice';
import LineStatus from './LineStatus';
import OutsideClickHandler from '../../components/OutsideClickHandler';

const LineSelector = (props) => {
    const {
        value,
        placeholder,
        valueChanged,
        classes,
        disabled = false,
    } = props;
    let params = useParams();

    const accountId = params.accountId;

    const [getList, { data }] = useLazyGetLinesListQuery();

    const [isEdit, setIsEdit] = useState(false);
    const [searchString, setSearchString] = useState('');

    const setEnable = () => {
        if (!disabled) {
            setIsEdit(true);
        }
    };

    useEffect(() => {
        async function loadDataAsync() {
            var r = await getList({
                accountId,
                free: true,
                include: value,
                search: searchString,
            });
        }
        loadDataAsync();
    }, [accountId, value, searchString]);

    const selectValue = (value) => {
        valueChanged(value);
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={
                        'dropdown width-350 height-300 d-block position-absolute'
                    }
                >
                    <>
                        <div className='dropdown-input'>
                            <input
                                value={searchString}
                                onChange={(e) =>
                                    setSearchString(e.target.value)
                                }
                                placeholder={'Введите значение'}
                            />
                        </div>
                        <div
                            key={-1}
                            className='dropdown-check cursor-pointer'
                            onClick={() => selectValue(null)}
                        >
                            Не выбрано
                        </div>
                        {data?.result?.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-check cursor-pointer'
                                    onClick={() => selectValue(item.number)}
                                >
                                    <span style={{ overflowWrap: 'normal' }}>
                                        {item.number}
                                    </span>
                                    {value === item.number ? (
                                        <img
                                            src='/images/icons/check.svg'
                                            width={20}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </>
                </OutsideClickHandler>
            ) : (
                <>
                    {value ? (
                        <span
                            className='line-selector line-selector-hover font-size-14'
                            onClick={setEnable}
                        >
                            <LineStatus active={true} number={value} />
                        </span>
                    ) : (
                        <span
                            className='line-selector line-selector-hover line-selector-defalt'
                            onClick={setEnable}
                        >
                            Пусто
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default LineSelector;
