import { useState } from 'react';
import DropDownWithCheck from '../dropdowns/DropDownWithCheck';
import DropDownInput from '../dropdowns/DropDownInput';

const tasksArray = [
    {
        value: 'none',
        text: 'Не выбрано',
        icon: '/images/icons/circle.svg',
        iconClass: 'icon-status-none',
        blockClass: 'filters-b-gray',
    },
    {
        value: 'draft',
        text: 'Черновик',
        icon: '/images/icons/edit.svg',
        iconClass: 'icon-status-draft',
        blockClass: 'filters-b-gray',
    },
    {
        value: 'postponed',
        text: 'Отложено',
        icon: '/images/icons/postponed.svg',
        iconClass: 'icon-status-postponed',
        blockClass: 'filters-b-postponed',
    },
    {
        value: 'important',
        text: 'Важно',
        icon: '/images/icons/flag.svg',
        iconClass: 'icon-status-important',
        blockClass: 'filters-b-important',
    },
    {
        value: 'complete',
        text: 'Выполнено',
        icon: '/images/icons/check.svg',
        iconClass: 'icon-status-success',
        blockClass: 'filters-b-gray',
    },
];

const TaskStatusDropdown = (props) => {
    const { value, valueChanged } = props;
    const [isEdit, setIsEdit] = useState(false);

    const selectedStatus = tasksArray.find(
        (x) => x.value === (value ?? 'none')
    );

    return (
        <>
            <DropDownInput
                value={
                    <span className="d-flex align-item-center">
                        <img
                            src={selectedStatus.icon}
                            className={
                                'me-1 align-baseline ' +
                                selectedStatus.iconClass
                            }
                            width={12}
                        />
                        {selectedStatus.text}
                    </span>
                }
                clickHandler={() => setIsEdit(true)}
                inputClass={selectedStatus.blockClass + ' task-status-input'}
            >
                {isEdit ? (
                    <DropDownWithCheck
                        listArray={tasksArray}
                        outsideClick={() => setIsEdit(false)}
                        selectHandle={(value) => valueChanged(value)}
                        selectedValue={value}
                    />
                ) : (
                    ''
                )}
            </DropDownInput>
        </>
    );
};

export default TaskStatusDropdown;
