import { useSelector } from 'react-redux';
import { selectSecurity } from '../../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const PersonalAccountsPage = () => {
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const openAccount = (accountId) => {
        navigate(`/account/${accountId}/overview`);
    };

    return (
        <div className='row'>
            <div className='col'>
                <h1 className='mb-4'>Добро пожаловать</h1>
                <p className='font-size-16' style={{ marginBottom: 32 }}>
                    Выберите аккаунт для управления
                </p>
                <div className='row'>
                    {security?.accounts?.map((item) => (
                        <div className='col-md-2' key={item.id}>
                            <div
                                className='av-card mb-24 d-flex align-items-center justify-content-center'
                                key={item.id}
                                onClick={() => openAccount(item.id)}
                            >
                                <div className='h3'>{item.title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PersonalAccountsPage;
