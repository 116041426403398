const SwitchInput = (props) => {
    const { value, valueChanged, disabled=false } = props;
    return (
        <div className='form-check form-switch'>
            <input
                className='form-check-input'
                type='checkbox'
                id='flexSwitchCheckDefault'
                checked={value ?? ''}
                onChange={(value) => valueChanged(value.target.checked)}
                disabled={disabled}
            />
        </div>
    );
};

export default SwitchInput;
