import PageTitle from '../../components/layout/PageTitle';
import CompaniesList from '../companies/CompaniesList';

const CompaniesPage = (props) => {
    return (
        <>
            <PageTitle
                title='Компании'
                icon={
                    <img
                        src='/images/icons/folder.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                disabled={true}
            />
            <CompaniesList />
        </>
    );
};

export default CompaniesPage;
