import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    usePatchDepartmentMutation,
    useLazyGetDepartmentByIdQuery,
    useCreateDepartmentMutation,
} from '../../../app/api/departmentsApiSlice';
import PageTitle from '../../../components/layout/PageTitle';
import SwitchInput from '../../../components/editors/SwitchInput';
import TextInput from '../../../components/editors/TextInput';
import SliceIcon from '../../../components/SliceIcon';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../../features/auth/authSlice';
import EmployeesList from '../../employees/EmployeesList';

const DepartmentCardPage = () => {
    let params = useParams();
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const [patchDepartment, { r }] = usePatchDepartmentMutation();
    const [createDepartment] = useCreateDepartmentMutation();
    const [getDepartment, { data }] = useLazyGetDepartmentByIdQuery();

    const accountId = params.accountId;
    const entityId = params.departmentId;
    const [mode, setMode] = useState('card');
    const [securityContext, setSecurityContext] = useState(null);
    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    function patch(obj) {
        console.log(obj);
        patchDepartment({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    useEffect(() => {
        if (entityId > 0) {
            getDepartment(entityId);
        }
    }, [entityId]);

    const saveTitle = async (name) => {
        console.log(name);

        if (entityId === '0') {
            const entity = {
                id: entityId ?? 0,
                account: { id: accountId },
                name,
            };
            let r = await createDepartment(entity);
            if (r?.data?.result?.id > 0) {
                navigate(
                    `/account/${accountId}/departments/${r?.data?.result?.id}`
                );
            }
        } else {
            patch({ path: 'name', value: name });
        }
    };

    return (
        <>
            <PageTitle
                title={data?.result?.name}
                icon={
                    <img
                        src='/images/icons/grid.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                placeholder='Новый отдел'
                isNew={entityId === '0'}
                valueChanged={(name) => saveTitle(name)}
                disabled={
                    !security?.isRoot && securityContext?.role !== 'admin'
                }
                maxLength={50}
            />
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div
                        className={
                            'slice ' + (mode === 'card' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('card')}
                    >
                        <SliceIcon value={mode === 'card'} filename='info' />
                        Информация
                    </div>
                    <div
                        className={
                            'slice ' +
                            (mode === 'employees' ? 'active-slice' : '')
                        }
                        onClick={() => setMode('employees')}
                    >
                        <SliceIcon value={mode === 'user'} filename='user' />
                        Сотрудники
                    </div>
                </div>
                <div></div>
            </div>

            {mode === 'card' ? (
                <div style={{ width: 600 }} className='entity-card'>
                    <div className='row mt-4'>
                        <div className='col-md-3 property property-title'>
                            <img
                                src='/images/icons/file-text.svg'
                                width={16}
                                className='icon-gray'
                            />
                            <span>Описание</span>
                        </div>
                        <div className='col-md-9'>
                            <TextInput
                                placeholder='Пусто'
                                value={data?.result?.description}
                                valueChanged={(v) =>
                                    patch({ path: 'description', value: v })
                                }
                                disabled={
                                    !security?.isRoot &&
                                    securityContext?.role !== 'admin'
                                }
                            />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-md-3 property property-title'>
                            <img
                                src='/images/icons/archive.svg'
                                width={16}
                                className='icon-gray'
                            />
                            <span>Архивный</span>
                        </div>
                        <div className='col-md-9'>
                            <SwitchInput
                                value={data?.result?.isArchive}
                                valueChanged={(v) =>
                                    patch({ path: 'isArchive', value: v })
                                }
                                disabled={
                                    !security?.isRoot &&
                                    securityContext?.role !== 'admin'
                                }
                            />
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
            {mode === 'employees' ? (
                <EmployeesList
                    fastFilters={false}
                    excludeCols={['department']}
                    preDepartmentId={entityId}
                />
            ) : (
                ''
            )}
        </>
    );
};

export default DepartmentCardPage;
