import { Link, useLocation, useParams } from 'react-router-dom';
import MenuItem from './menu/MenuItem';
import MenuItemWithSub from './menu/MenuItemWithSub';
import { useState } from 'react';
import SidebarHeader from './sidebarHeader/SidebarHeader';
import Breadcrumbs from './Breadcrumbs';
import NotificationsPanel from '../notifications/NotificationsPanel';
import CallsPanel from '../calls/CallsPanel';
import MenuDefault from '../../components/layout/menu/MenuDefault';

const LayoutMaster = (props) => {
    const params = useParams();
    const location = useLocation();
    const isAccount = location.pathname.startsWith('/account/');

    const accountId = params.accountId;

    let returnLink = null;
    if (props.returnLink) {
        returnLink = props.returnLink.replace(':accountId', accountId);
    }

    const [showMenu, setShowMenu] = useState(true);

    return (
        <div className='container-fluid'>
            <div className='row flex-nowrap'>
                <div className='col-auto px-0'>
                    <div
                        id='sidebar'
                        className={`collapse collapse-horizontal ${
                            showMenu ? 'show' : 'collapse'
                        }`}
                    >
                        <SidebarHeader />
                        {/* Sidebar */}
                        <ul className='navbar-nav accordion'>
                            {/* Nav Item - Dashboard */}
                            <MenuDefault />
                        </ul>
                        {/* End of Sidebar */}
                    </div>
                    {isAccount ? (
                        <CallsPanel accountId={accountId} />
                    ) : ''}                    
                </div>
                <div className='col vh-100 p-2'>
                    <div className='content-container'>
                        <div className='content-header'>
                            <div className='breadcrumbs'>
                                <div className='header-left'>
                                    <img
                                        src='/images/icons/chevrons-left.svg'
                                        width={20}
                                        className={
                                            'me-2 cursor-pointer' +
                                            (showMenu ? '' : ' rotate-180')
                                        }
                                        onClick={() => setShowMenu(!showMenu)}
                                    />
                                    {props.headerTitle}
                                </div>
                                <Breadcrumbs folders={props.breadcrumbs} />
                            </div>
                            {isAccount ? (
                                <NotificationsPanel accountId={accountId} />
                            ) : ''}
                        </div>
                        <div className='container-fluid'>
                            {props.returnLink ? (
                                <Link
                                    className='btn btn-link pl-0'
                                    to={returnLink}
                                >
                                    <i className='fas fa-arrow-left' />{' '}
                                    Вернуться к списку
                                </Link>
                            ) : null}
                            <div className='container-fluid'>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutMaster;
