import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddDays, GetToday, ToUtcString } from '../../helpers/DateHelpers';
import { useLazyGetReportsListQuery } from '../../app/api/reportsApiSlice';
import DateRangeFilter from '../../components/filters/DateRangeFilter';
import GroupDateTypeFilter from '../../components/filters/GroupDateTypeFilter';
import Button from '../../components/Button';
import { saveAs } from 'file-saver';
import Http from '../../http';
import ReportChart from './ReportChart';
import TypeCallDirectionsFilter from './TypeCallDirectionsFilter';
import ReportTableAll from './ReportTableAll';
import ReportTableMissed from './ReportTableMissed';
import ReactApexChart from 'react-apexcharts';

const ReportsList = () => {
    let params = useParams();

    const accountId = params.accountId;

    const [getReports, { data, isLoading }] = useLazyGetReportsListQuery();

    const [start, setStart] = useState(AddDays(GetToday(), -7));
    const [finish, setFinish] = useState(GetToday());
    const [groupType, setGroupType] = useState(0);
    const [excel, setExcel] = useState('');
    const [series, setSeries] = useState([]);
    const [callType, setCallType] = useState(0);
    const [colors, setColors] = useState([]);
    const [radialSeries, setRadialSeries] = useState([]);
    const [radialLabels, setRadialLabels] = useState([]);

    useEffect(() => {
        async function loadDataAsync() {
            await getReports({
                accountId,
                start: ToUtcString(start),
                finish: ToUtcString(finish),
                groupType,
                excel,
            });
        }
        loadDataAsync();
    }, [start, finish, groupType]);

    useEffect(() => {
        async function loadDataAsync() {
            const seriesArr = [];
            if (callType == 0) {
                seriesArr.push({
                    name: 'Входящие отвеченные',
                    group: 'incomming',
                    data: data?.result?.map((item) => item.incomingProcessed),
                });
                seriesArr.push({
                    name: 'Входящие пропущенные',
                    group: 'incomming',
                    data: data?.result?.map(
                        (item) => item.incomingNotProcessed
                    ),
                });
                seriesArr.push({
                    name: 'Исходящие',
                    group: 'outgoing',
                    data: data?.result?.map((item) => item.outgoingTotal),
                });
                setRadialSeries([
                    data?.result?.reduce(
                        (n, { incomingProcessed }) => n + incomingProcessed,
                        0
                    ) ?? 0,
                    data?.result?.reduce(
                        (n, { incomingNotProcessed }) =>
                            n + incomingNotProcessed,
                        0
                    ) ?? 0,
                    data?.result?.reduce(
                        (n, { outgoingTotal }) => n + outgoingTotal,
                        0
                    ) ?? 0,
                ]);
                setRadialLabels([
                    'Входящие отвеченные',
                    'Входящие пропущенные',
                    'Исходящие',
                ]);
                setColors(['#65D868', '#FF4F50', '#6E9DF5']);
            } else {
                seriesArr.push({
                    name: 'Не перезвонили',
                    group: 'missed',
                    data: data?.result?.map((item) => item.incomingNotRecalled),
                });
                seriesArr.push({
                    name: 'Перезвонили',
                    group: 'missed',
                    data: data?.result?.map((item) => item.incomingRecalled),
                });
                seriesArr.push({
                    name: 'Клиент перезвонил',
                    group: 'missed',
                    data: data?.result?.map(
                        (item) => item.incomingRecalledByClient
                    ),
                });
                setRadialSeries([
                    data?.result?.reduce(
                        (n, { incomingNotRecalled }) => n + incomingNotRecalled,
                        0
                    ) ?? 0,
                    data?.result?.reduce(
                        (n, { incomingRecalled }) => n + incomingRecalled,
                        0
                    ) ?? 0,
                    data?.result?.reduce(
                        (n, { incomingRecalledByClient }) =>
                            n + incomingRecalledByClient,
                        0
                    ) ?? 0,
                ]);
                setRadialLabels([
                    'Не перезвонили',
                    'Перезвонили',
                    'Клиент перезвонил',
                ]);
                setColors(['#FF4865', '#5786DF', '#65D868']);
            }
            setSeries(seriesArr);
        }
        loadDataAsync();
    }, [data, callType]);

    const exportData = async () => {
        const response = await Http.getMedia(
            'api/reports/calls?' +
                `accountId=${accountId}` +
                `&start=${ToUtcString(start)}` +
                `&finish=${ToUtcString(finish)}` +
                `&groupType=${groupType ?? 0}` +
                `&export=v1`
        );
        var blob = new Blob([response], {
            type: 'text/xlsx',
        });
        saveAs(blob, 'report.xlsx');
    };

    console.log('reportData', data);

    return (
        <>
            <div
                className='slices-container border-bottom-0'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='table-filter'>
                    <DateRangeFilter
                        value={{ start, finish }}
                        valueChanged={(e) => {
                            if (e?.start) setStart(e.start);
                            else setStart(GetToday());
                            if (e?.finish) setFinish(e.finish);
                            else setFinish(GetToday());
                        }}
                    />
                    <GroupDateTypeFilter
                        valueChanged={(v) => setGroupType(v)}
                        value={groupType}
                    />
                    <TypeCallDirectionsFilter
                        valueChanged={(v) => setCallType(v)}
                        value={callType}
                    />
                </div>
                <div>
                    <Button
                        label='Выгрузить в Excel'
                        btnColor='blue'
                        handleClick={exportData}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-8 col-xl-8 col-6 align-self-center'>
                    <ReportChart
                        categories={
                            data?.result?.map((item) => item.period) ?? []
                        }
                        series={series}
                        colors={colors}
                    />
                </div>
                <div className='col-xl-4 col-6 align-self-center'>
                    <ReactApexChart
                        options={{
                            chart: {
                                type: 'donut',
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        labels: {
                                            show: true,
                                            total: {
                                                show: true,
                                                label: 'Всего',
                                            },
                                        },
                                    },
                                },
                            },
                            legend: {
                                show: false,
                            },
                            colors,
                            labels: radialLabels,
                        }}
                        series={radialSeries}
                        type='donut'
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    {callType === 0 ? (
                        <ReportTableAll
                            data={data?.result}
                            isLoading={isLoading}
                        />
                    ) : (
                        <ReportTableMissed
                            data={data?.result}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ReportsList;
