import { useCreateCallbackMutation } from '../../app/api/callbackApiSlice';

const PhoneComponent = (props) => {
    const { phone, accountId, contactId = null, callId = null } = props;

    const [callPhone] = useCreateCallbackMutation();

    const call = (e) => {
        e.stopPropagation();
        callPhone({
            phone,
            accountId,
            contactId,
            callId,
        });
    };

    return (
        <div className='hover-show-parent d-inline'>
            <span>{phone}</span>
            <img
                src='/images/icons/phone.svg'
                className='icon-green ms-2 hover-show cursor-pointer'
                onClick={(e) => call(e)}
            />
        </div>
    );
};

export default PhoneComponent;
