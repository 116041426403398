import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    usePatchEmployeeMutation,
    useCreateEmployeeMutation,
    useLazyGetEmployeeByIdQuery,
    useLazyGetEmployeeRedirectsQuery,
    usePatchEmployeeRedirectMutation,
} from '../../../app/api/employeesApiSlice';
import PageTitle from '../../../components/layout/PageTitle';
import SwitchInput from '../../../components/editors/SwitchInput';
import TextInput from '../../../components/editors/TextInput';
import LineSelector from '../../../components/editors/LineSelector';
import DepartmentsDropdown from '../../../components/editors/DepartmentsDropdown';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../../features/auth/authSlice';
import RoleDropdown from '../../../components/editors/RoleDropdown';
import RecordModeDropdown from '../../../components/editors/RecordModeDropdown';
import EmployeesDropdown from '../../../components/editors/EmployeesDropdown';

const EmployeeCardPage = () => {
    let params = useParams();
    const navigate = useNavigate();
    const security = useSelector(selectSecurity);

    const accountId = params.accountId;
    const entityId = params.employeeId;
    const [patchEmployee, { r }] = usePatchEmployeeMutation();
    const [patchEmployeeRedirect, { r2 }] = usePatchEmployeeRedirectMutation();
    const [createEmployee] = useCreateEmployeeMutation();
    const [getEmployee, { data }] = useLazyGetEmployeeByIdQuery();
    const [getRedirects, { data: redirects }] =
        useLazyGetEmployeeRedirectsQuery();

    const [securityContext, setSecurityContext] = useState(null);
    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    function patch(obj) {
        patchEmployee({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }
    function patchRedirect(obj) {
        patchEmployeeRedirect({
            id: entityId,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    useEffect(() => {
        if (entityId > 0) {
            getEmployee(entityId);
            getRedirects(entityId);
        }
    }, [entityId]);

    const saveTitle = async (name) => {
        if (entityId === '0') {
            const entity = {
                id: entityId ?? 0,
                account: { id: accountId },
                name,
            };
            let r = await createEmployee(entity);
            if (r?.data?.result?.id > 0) {
                navigate(
                    `/account/${accountId}/employees/${r?.data?.result?.id}`
                );
            }
        } else {
            patch({ path: 'name', value: name });
        }
    };

    console.log('empl', data);

    return (
        <>
            <PageTitle
                title={data?.result?.name}
                icon={
                    <img
                        src='/images/icons/user.svg'
                        width={24}
                        className='icon-main'
                    />
                }
                placeholder='Новый сотрудник'
                isNew={entityId === '0'}
                valueChanged={(name) => saveTitle(name)}
                disabled={
                    !security?.isRoot && securityContext?.role !== 'admin'
                }
                maxLength={150}
            />
            <div style={{ width: 600 }} className='entity-card'>
                <div className='row mt-4'>
                    <div className='col property-header'>Линия</div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/phone.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Внутренний номер</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <LineSelector
                            placeholder='Пусто'
                            value={data?.result?.lineNumber}
                            valueChanged={(v) =>
                                patch({ path: 'lineNumber', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col property-header'>
                        Безусловная переадресация
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/smartphone.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Номер для переадресации</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={redirects?.result?.mainRedirectNumber}
                            valueChanged={(v) =>
                                patchRedirect({
                                    path: 'mainRedirectNumber',
                                    value: v,
                                })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin' &&
                                parseInt(entityId) !==
                                    parseInt(securityContext?.employeeId)
                            }
                        />
                    </div>
                </div>
                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/phone-off.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Включена</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <SwitchInput
                            value={redirects?.result?.mainRedirectActive}
                            valueChanged={(v) =>
                                patchRedirect({
                                    path: 'mainRedirectActive',
                                    value: v,
                                })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin' &&
                                parseInt(entityId) !==
                                    parseInt(securityContext?.employeeId)
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col property-header'>
                        Условная переадресация
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/phone-call.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Если линия не отвечает</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={redirects?.result?.noAnswerRedirectNumber}
                            valueChanged={(v) =>
                                patchRedirect({
                                    path: 'noAnswerRedirectNumber',
                                    value: v,
                                })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin' &&
                                parseInt(entityId) !==
                                    parseInt(securityContext?.employeeId)
                            }
                        />
                    </div>
                </div>
                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/phone-off.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Если линия занята</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={redirects?.result?.busyRedirectNumber}
                            valueChanged={(v) =>
                                patchRedirect({
                                    path: 'busyRedirectNumber',
                                    value: v,
                                })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin' &&
                                parseInt(entityId) !==
                                    parseInt(securityContext?.employeeId)
                            }
                        />
                    </div>
                </div>
                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/phone-missed.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Если линия отключена</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={redirects?.result?.unavailableRedirectNumber}
                            valueChanged={(v) =>
                                patchRedirect({
                                    path: 'unavailableRedirectNumber',
                                    value: v,
                                })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin' &&
                                parseInt(entityId) !==
                                    parseInt(securityContext?.employeeId)
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col property-header'>Информация</div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/grid.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Отдел</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <DepartmentsDropdown
                            value={data?.result?.department}
                            selectCallback={(v) => {
                                patch({ path: 'departmentId', value: v });
                            }}
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/user-position.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Должность</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.occupation}
                            valueChanged={(v) =>
                                patch({ path: 'occupation', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/users.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Наставник</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <EmployeesDropdown
                            value={data?.result?.mentor}
                            selectCallback={(v) => {
                                patch({ path: 'mentorId', value: v });
                            }}
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/log-in.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Логин</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <TextInput
                            placeholder='Пусто'
                            value={data?.result?.user?.title}
                            valueChanged={(v) =>
                                patch({ path: 'login', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/role.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Роль</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <RoleDropdown
                            value={data?.result?.role}
                            valueChanged={(v) =>
                                patch({ path: 'role', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/volume-2.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Доступ к записи разговора</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <RecordModeDropdown
                            value={data?.result?.recordMode}
                            valueChanged={(v) =>
                                patch({ path: 'recordMode', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/volume-x.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Скрывать мои записи</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <SwitchInput
                            value={data?.result?.hideRecords}
                            valueChanged={(v) =>
                                patch({ path: 'hideRecords', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>

                <div className='row my-12px'>
                    <div className='col-md-7 property property-title'>
                        <img
                            src='/images/icons/archive.svg'
                            width={16}
                            className='icon-gray'
                        />
                        <span>Архивный</span>
                    </div>
                    <div className='col-md-5 property property-value'>
                        <SwitchInput
                            value={data?.result?.isArchive}
                            valueChanged={(v) =>
                                patch({ path: 'isArchive', value: v })
                            }
                            disabled={
                                !security?.isRoot &&
                                securityContext?.role !== 'admin'
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeCardPage;
