import React from 'react';

const Button = (props) => {
    const {
        label,
        handleClick,
        btnColor,
        isLoading,
        isFailed,
        icon,
        loadingLabel = 'Загрузка...',
        disabled
    } = props;

    let labelBtn = label;

    if (isLoading) {
        labelBtn = loadingLabel;
    }

    if (isFailed) {
        labelBtn = 'Ошибка!';
    }

    return (
        <button
            type='button'
            className={`button button-${isFailed ? 'danger' : btnColor}`}
            onClick={handleClick}
            disabled={disabled}
        >
            {icon}
            {labelBtn}
        </button>
    );
};

export default Button;
