import { useEffect, useState } from 'react';

const TextInput = (props) => {
    const {
        value,
        placeholder,
        valueChanged,
        classes,
        type = 'text',
        disabled = false,
    } = props;
    const [text, setText] = useState();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setText(value);
    }, [value]);
    const setEnable = () => {
        if (!disabled) {
            setIsEdit(true);
        }
    };

    // что-то нажали
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commitChanges();
        } else if (event.code === 'Escape') {
            setText(value);
            setIsEdit(false);
        }
    };

    // применяем изменение
    const commitChanges = () => {
        if (text !== value) {
            console.log('commit');
            valueChanged(text);
        } else {
            console.log('no commit');
        }
        setIsEdit(false);
    };

    return (
        <>
            {isEdit ? (
                <div className='position-relative'>
                    <input
                        className={
                            'form-control text-input text-input-active ' +
                            classes
                        }
                        placeholder={placeholder}
                        value={text}
                        onChange={(v) => setText(v.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={commitChanges}
                        type={type}
                        autoFocus
                    />
                    <div className='input-hint'>
                        <i>Enter - сохранить изменения</i>
                        <br />
                        <i>Esc - отменить изменения</i>
                    </div>
                </div>
            ) : (
                <>
                    {value ? (
                        <span
                            className={'text-input text-input-hover ' + classes}
                            onClick={setEnable}
                        >
                            {value}
                        </span>
                    ) : (
                        <span
                            className={
                                'text-input text-input-hover text-input-defalt ' +
                                classes
                            }
                            onClick={setEnable}
                        >
                            Пусто
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default TextInput;
