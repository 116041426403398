import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOverviewQuery } from '../../app/api/overviewApiSlice';

const SummaryPanel = (props) => {
    const { accountId } = props;

    const navigate = useNavigate();

    const { data, isLoading } = useGetOverviewQuery(accountId);


    return (
        <div className='dashboard dashboard-list'>
            <div className='dashboard-card'>
                    <div className='dashboard-header'>
                        <img src={`/images/icons/target.svg`} width={30} />
                        <span>Задачи</span>
                    </div>

                    <div className='dashboard-values'>
                        <div onClick={() => navigate(`/account/${accountId}/tasks`)} className='cursor-pointer'>
                            <div className='variable-value'>{data?.result.tasks_Today ?? '-'}</div>
                            <div className='variable-name'>На сегодня</div>
                        </div>
                        <div onClick={() => navigate(`/account/${accountId}/tasks?show=watching`)} className='cursor-pointer'>
                            <div className='variable-value'>{data?.result.tasks_Watching ?? '-'}</div>
                            <div className='variable-name'>На контроле</div>
                        </div>
                        <div onClick={() => navigate(`/account/${accountId}/tasks?show=expired`)} className='cursor-pointer'>
                            <div className='variable-value missed'>{data?.result.tasks_Missed ?? '-'}</div>
                            <div className='variable-name missed'>Просроченные</div>
                        </div>

                    </div>
            </div>

            <div className='dashboard-card'>
                    <div className='dashboard-header'>
                        <img src={`/images/icons/phone-call.svg`} width={30} />
                        <span>Вызовы</span>
                    </div>

                    <div className='dashboard-values'>
                        <div onClick={() => navigate(`/account/${accountId}/calls?show=in`)} className='cursor-pointer'>
                            <div className='variable-value'>{data?.result.calls_Today ?? '-'}</div>
                            <div className='variable-name'>За сегодня</div>
                        </div>
                        <div onClick={() => navigate(`/account/${accountId}/calls?show=missed`)} className='cursor-pointer'>
                            <div className='variable-value missed'>{data?.result.calls_Missed ?? '-'}</div>
                            <div className='variable-name missed'>Неотвеченные</div>
                        </div>

                    </div>
            </div>

        </div>
    );
};

export default SummaryPanel;
