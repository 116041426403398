import { useEffect, useState } from "react";

const SearchableList = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    
    const {value, list, searchCallback, selectCallback, hideEmpty} = props;
    
    const [searchString, setSearchString] = useState('');
    useEffect(()=>{
        if(searchString==='' || searchString.length>1){
            if(searchCallback){
                searchCallback(searchString);
            }
        }
    },[searchString])
    
    const selectValue = (value) => {
        selectCallback(value);
        setIsEdit(false);
    }
    
    return (
        <>
            <div className="dropdown-input">
                <input 
                    value={searchString} 
                    onChange={(e)=>setSearchString(e.target.value)} 
                    placeholder={'Поиск'} 
                    autoFocus={true}
                />
            </div>
            <div className="dropdown-scroll">
            {hideEmpty === undefined ? (
                <div key={-1} className="dropdown-check cursor-pointer" onClick={()=>selectValue(undefined)}>Не выбрано</div>
            ) : ''}
                {list?.map((item, i)=>{
                    return (
                        <div key={i} className="dropdown-check cursor-pointer" onClick={()=>selectValue(item)}>
                            <span>{item.name}</span>
                            {value?.id === item.id ? (
                                <img src='/images/icons/check.svg' width={20} />
                                ) : ''}
                        </div>
                    )
                    })}

            </div>
        </>
    );
};
            
export default SearchableList;