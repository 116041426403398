import { createSlice } from '@reduxjs/toolkit';

const linesSlice = createSlice({
    name: 'lines',
    initialState: { lines: [] },
    reducers: {
        setLines: (state, action) => {
            state.lines = action.payload;
        },
        updateStatus: (state, action) => {
            const line = state.lines.find(
                (x) => x.number === action.payload.number
            );
            line.status = action.payload.status;
        },
    },
});

export const { setLines, updateStatus } = linesSlice.actions;

export default linesSlice.reducer;

export const selectLines = (state) => state.lines?.lines;
