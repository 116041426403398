import FilterIcon from '../../components/FilterIcon';

const FilterScalarHeader = (props) => {
    const { value, icon, title, clickHandler, withoutActive } = props;

    return (
        <div className='filters'>
            <div
                className={
                    'filters-label cursor-pointer' +
                    (value === '' || value === undefined || withoutActive
                        ? ''
                        : ' filters-active')
                }
                onClick={() => clickHandler()}
            >
                <FilterIcon
                    filename={icon}
                    width={12}
                    value={value}
                    withoutActive={withoutActive}
                />
                <span>{title}</span>
                {value}
                <FilterIcon
                    filename='chevron-down'
                    width={12}
                    value={value}
                    withoutActive={withoutActive}
                />
            </div>
            {props.children}
        </div>
    );
};

export default FilterScalarHeader;
