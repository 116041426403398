import { useEffect, useState } from 'react';

import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterScalarHeader from './FilterScalarHeader';

const GroupDateTypeFilter = (props) => {
    const { value, valueChanged } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [description, setDescription] = useState(': день');

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const selectValue = (value) => {
        valueChanged(value);
        setIsEdit(false);
        setSelectedValue(value);
        if (value === 0) {
            setDescription(': день');
        } else if (value === 1) {
            setDescription(': неделя');
        } else if (value === 2) {
            setDescription(': месяц');
        } else {
            setDescription('');
        }
    };

    return (
        <>
            <FilterScalarHeader
                value={description}
                title='Тип группировки'
                icon='3-layers'
                clickHandler={() => setIsEdit(true)}
                withoutActive
            >
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={
                            'dropdown width-200 height-100 filters-dropdown'
                        }
                    >
                        <>
                            <div
                                className='dropdown-check cursor-pointer'
                                onClick={() => selectValue(0)}
                            >
                                <div className='dropdown-flex-start'>
                                    <span>День</span>
                                </div>
                                {selectedValue === 0 ? (
                                    <img
                                        src='/images/icons/check.svg'
                                        width={20}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className='dropdown-check cursor-pointer'
                                onClick={() => selectValue(1)}
                            >
                                <div className='dropdown-flex-start'>
                                    Неделя
                                </div>
                                {selectedValue === 1 ? (
                                    <img
                                        src='/images/icons/check.svg'
                                        width={20}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className='dropdown-check cursor-pointer'
                                onClick={() => selectValue(2)}
                            >
                                <div className='dropdown-flex-start'>
                                    Месяц
                                    {selectedValue === 2 ? (
                                        <img
                                            src='/images/icons/check.svg'
                                            width={20}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </FilterScalarHeader>
        </>
    );
};

export default GroupDateTypeFilter;
