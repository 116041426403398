import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useLazyGetHoldingsListQuery,
    useCreateHoldingMutation,
    usePatchHoldingMutation,
    useDeleteHoldingMutation,
} from '../../app/api/holdingsApiSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import FindComponent from '../../components/filters/FindComponent';
import SortableTableHead from '../../components/SortableTableHead';
import AddRow from '../../components/editors/AddRow';
import SwitchInput from '../../components/editors/SwitchInput';
import TextInput from '../../components/editors/TextInput';
import SliceIcon from '../../components/SliceIcon';
import { FormatDateToHuman2 } from '../../helpers/DateHelpers';
import { DeleteActionMenu } from '../../components/DeleteActionMenu';
import { useSelector } from 'react-redux';
import { selectSecurity } from '../../features/auth/authSlice';

const HoldingsList = () => {
    let params = useParams();

    const [deleteHolding] = useDeleteHoldingMutation();

    const accountId = params.accountId;

    const security = useSelector(selectSecurity);

    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState('name');
    const [securityContext, setSecurityContext] = useState(null);

    const [getList, { data, isLoading }] = useLazyGetHoldingsListQuery();
    const [createHolding] = useCreateHoldingMutation();
    const [patchHolding] = usePatchHoldingMutation();

    useEffect(() => {
        if (security) {
            setSecurityContext(
                security.accounts.find((x) => x.id == accountId)
            );
        }
    }, [security]);

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                search: encodeURIComponent(searchString),
                sort,
                showArchive: true,
            });
        }
        loadDataAsync();
    }, [accountId, searchString, sort]);

    const create = async (name) => {
        let holding = {
            account: { id: accountId },
            name,
        };
        await createHolding(holding);
    };

    function patch(id, obj) {
        console.log(obj);
        patchHolding({
            id: id,
            items: [{ path: '/' + obj.path, op: 'replace', value: obj.value }],
        });
    }

    return (
        <>
            <div
                className='slices-container'
                style={{ justifyContent: 'space-between' }}
            >
                <div className='alignCenter'>
                    <div className={'slice active-slice'}>
                        <SliceIcon value={true} filename='loader' />
                        Все
                    </div>
                </div>
            </div>
            <div className='table-filter'>
                <FindComponent
                    callback={(name) => setSearchString(name)}
                    placeholder='Поиск'
                />
            </div>
            <div className='row'>
                <div className='col'>
                    <SkeletonTable isLoading={isLoading}>
                        <table className='table table-sm content-table'>
                            <thead>
                                <tr>
                                    <SortableTableHead
                                        scope='col'
                                        classes='ps-3'
                                        label='Имя'
                                        field='name'
                                        value={sort}
                                        handleClick={(value) => setSort(value)}
                                    />
                                    <th scope='col'>Дата создания</th>
                                    <th scope='col'>Архивный</th>
                                    {securityContext?.role === 'admin' ||
                                    security?.isRoot ? (
                                        <th></th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => {
                                    return (
                                        <tr
                                            className='cursor-pointer'
                                            key={item.id}
                                        >
                                            <td className='ps-3 left-cell'>
                                                <TextInput
                                                    value={item.name}
                                                    valueChanged={(v) =>
                                                        patch(item.id, {
                                                            path: 'name',
                                                            value: v,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td className='cell text-normal'>
                                                {FormatDateToHuman2(
                                                    item.createdAt,
                                                    false
                                                )}
                                            </td>
                                            <td className='cell text-normal'>
                                                <SwitchInput
                                                    value={item.isArchive}
                                                    valueChanged={(v) =>
                                                        patch(item.id, {
                                                            path: 'isArchive',
                                                            value: v,
                                                        })
                                                    }
                                                />
                                            </td>
                                            {securityContext?.role ===
                                                'admin' || security?.isRoot ? (
                                                <td>
                                                    <DeleteActionMenu
                                                        id={item.id}
                                                        action={deleteHolding}
                                                        deleteTitle='Удалить холдинг'
                                                        deleteText={
                                                            <>
                                                                Вы действительно
                                                                хотите удалить
                                                                холдинг{' '}
                                                                <b>
                                                                    {item.name}
                                                                </b>
                                                                ?
                                                            </>
                                                        }
                                                    />
                                                </td>
                                            ) : null}
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td colSpan={4}>
                                        <AddRow
                                            valueChanged={(name) =>
                                                create(name)
                                            }
                                            placeholder='Добавить холдинг'
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default HoldingsList;
