import React from 'react';

const SkeletonTable = (props) => {
    const { rowsCount = 10, isLoading, classTable, children } = props;

    const tr = () => {
        let tr = [];
        for (let index = 0; index < rowsCount; index++) {
            tr.push(
                <tr key={index}>
                    <td className='py-2'>
                        <div className='skeleton skeleton-td-inside'></div>
                    </td>
                    <td className='py-2'>
                        <div className='skeleton skeleton-td-inside'></div>
                    </td>
                    <td className='py-2'>
                        <div className='skeleton skeleton-td-inside'></div>
                    </td>
                    <td className='py-2'>
                        <div className='skeleton skeleton-td-inside'></div>
                    </td>
                    <td className='py-2'>
                        <div className='skeleton skeleton-td-inside'></div>
                    </td>
                </tr>
            );
        }
        return tr;
    };

    return (
        <>
            {isLoading ? (
                <table className={'table ' + classTable}>
                    <thead>
                        <tr className='fw-bold text-gray-800 border-bottom border-gray-200'>
                            <td className='py-2'>
                                <div className='skeleton skeleton-td-inside'></div>
                            </td>
                            <td className='py-2'>
                                <div className='skeleton skeleton-td-inside'></div>
                            </td>
                            <td className='py-2'>
                                <div className='skeleton skeleton-td-inside'></div>
                            </td>
                            <td className='py-2'>
                                <div className='skeleton skeleton-td-inside'></div>
                            </td>
                            <td className='py-2'>
                                <div className='skeleton skeleton-td-inside'></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>{tr()}</tbody>
                </table>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export default SkeletonTable;
