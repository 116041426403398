import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OutsideClickHandler from '../../components/OutsideClickHandler';
import FilterIcon from '../../components/FilterIcon';
import FilterScalarHeader from './FilterScalarHeader';
import DropDownWithCheck from '../dropdowns/DropDownWithCheck';
import { GetToday, DateToString } from '../../helpers/DateHelpers';
import moment from 'moment';
import StyledCalendar from './StyledCalendar';

const DateRangeFilter = (props) => {
    const { value,  valueChanged, classes } = props;
    let params = useParams();

    const [isEdit, setIsEdit] = useState(false);
    const [start, setStart] = useState(value?.start);
    const [finish, setFinish] = useState(value?.finish);
    const [stage, setStage] = useState(0);
    const [description, setDescription] = useState('');

    const selectValue = (value) => {
        valueChanged({ start, finish: value});
        setFinish(value);
        setIsEdit(false);
        setDescription(': ' + moment(start).format('DD.MM.yyyy') + ' - '  + moment(value).format('DD.MM.yyyy'));
        setStage(0);
    };

    const clear = () => {
        valueChanged('');
        setDescription('');
        setIsEdit(false);
        setStage(0);
        setStart(null);
        setFinish(null);
    };

    return (
        <>
            <FilterScalarHeader
                value={description}
                title='Период'
                icon='calendar'
                clickHandler={() => setIsEdit(true)}
            >
                {isEdit ? (
                    <OutsideClickHandler
                        callback={() => setIsEdit(false)}
                        classes={"dropdown filters-dropdown"}
                    >
                        <>
                            <div>{start ? DateToString(start) : ''} - {finish ? DateToString(finish) : ''}</div>
                            <div>{stage===0 ? 'Укажите начало' : 'Укажите окончание'}</div>
                            {stage === 0 ? (
                                <StyledCalendar
                                    value={start ? start : GetToday()}
                                    onChange={(d) => {
                                        setStart(d);
                                        setStage(1);
                                    }}
                                    locale='ru-RU'
                                />
                            ) : (
                                <StyledCalendar
                                    value={finish ? finish : GetToday()}
                                    onChange={(d) => {
                                        selectValue(d);
                                    }}
                                    locale='ru-RU'
                                />
                            )}
                            <div className='d-grid'>
                                <button
                                    className='button button-white'
                                    onClick={clear}
                                >
                                    Очистить
                                </button>
                            </div>
                        </>
                    </OutsideClickHandler>
                ) : (
                    ''
                )}
            </FilterScalarHeader>
        </>
    );
};

export default DateRangeFilter;
