import { useState } from 'react';

const FindComponent = (props) => {
    const { callback, placeholder, componentStyle } = props;

    const [findText, setFindText] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            callback(findText);
        }
    };

    const clearText = () => {
        setFindText('');
        callback('');
    };

    return (
        <div className='find-component'>
            <img src='/images/icons/search.svg' width={12} />
            <input
                type='text'
                value={findText}
                onChange={(e) => {
                    setFindText(e.target.value);
                    if(e.target.value.length>2 || e.target.value.length === 0){
                        callback(e.target.value);
                    }

                }}
                onKeyDown={handleKeyDown}
                className=''
                placeholder={placeholder}
            />
        </div>
    );
};

export default FindComponent;
